/* 广告图轮播区域 */
.ads_carousel_container {
    width:100vw; height: 56.25vw; overflow: hidden;
}
.ads_img {
    width:100vw; height: 56.25vw;
    background-size: cover; background-repeat: no-repeat;
}
.ads_loading {
    background-color: rgba(255, 160, 122, 0.15);
    width:100vw; height: 56.25vw;
    padding-left: 40vw; padding-top: 23vw;
}
.ads_spin {
    color: lightsalmon;
    width: 20vw; height: 20vw;
    padding-top: auto; padding-bottom: auto;
}