.main_category_area {
    display: flex; align-items: center;
    width: 100vw; height: 21.5vw;
    margin: 0; padding: 0;
}
.main_category_item {
    background-color: rgba(0, 157, 255, 0);
    border: none; outline: none;
    width: 25%;height: 21.5vw;
}
.main_category_icon {
    background-size: cover; background-repeat: no-repeat;
    width: 10vw; height: 10vw;
    padding: 0; margin-top: 3vw; margin-left: auto; margin-right: auto;
}
.main_category_text {
    color: black; font-size: 3.5vw; text-align: center; font-weight: bold;
    width:100%; height: 5.5vw; padding-top: 2vw;
}
/* .main_category_item:focus-within {
  background-color: lightblue;
}
.main_category_item:active {
  background-color: lightblue;
} */
.categories_loading {
    /* background-color: rgba(255, 160, 122, 0.15); */
    width:100vw; height: 45vw;
    padding-left: 40vw; padding-top: 16vw;
}
.categories_spin {
    width: 20vw; height: 20vw;
    padding-top: auto; padding-bottom: auto;
}