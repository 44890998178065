.item_page {
    background: white;
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden;
}
.item_ads_img_area {
    background-color: lightcyan;
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 56.25vw;
}
.item_ads_img {
    background: lightcyan;
    width: auto; height: auto;
    max-width: 100vw; max-height: 56.25vw;
    background-size: auto, auto; background-repeat: no-repeat;
}
.item_ads_logo {
    background-color: white;
    width: 26vw; height: 26vw;
    position: absolute; left: 37vw; top: 17vw; z-index: 200;
    border-radius: 50px; border-style: solid; border-color: white; border-width: 2px; opacity: 1;
}
.item_actives_area {
    position: absolute; right: 2vw; top: 14vw; z-index: 99;
    width: 10vw; height: 56.25vw; opacity: 0.6;
    padding:0; padding-top: 13vw;
}
.item_actives_btn {
    background-size: cover; background-repeat: no-repeat;
    width: 7vw; height: 7vw;
    margin: 1vw; margin-bottom: 3vw;
}
.item_start_btn {
    position: absolute; right: 3.25vw; top: 44.5vw; z-index: 99;
    background-size: cover; background-repeat: no-repeat;
    width: 6.5vw; height: 6.5vw; opacity: 0.5;
}
.item_start_btn:active {
    opacity: 1;
}
.item_back_btn {
    position: fixed; top: 3vw; left: 3vw; z-index: 200;
    width: 6vw; height: 6vw;
    background-size: cover; background-repeat: no-repeat;
}
.item_back_btn:active {
    width: 7vw; height: 7vw; opacity: 1;
}
.item_tip {
    display: flex; align-items: center;
    background: lightgray; opacity: 0.5;
    position: fixed; top: 43vw; left: 4.5vw; z-index: 200;
    width: auto; height: 8.5vw; padding: 2vw; padding-top: 0; padding-bottom: 0;
    border-radius: 3px;
}
.item_rate {
    width: auto; height: auto;
    position: fixed; top: 43vw; left: 35vw; z-index: 200;
    opacity: 0.8;
}
.item_name {
    color: black; font-size: 5vw; font-weight: bold;
    padding: 4.5vw; opacity: 0.75;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.1); border-bottom-width: 1px;
}
.item_price_area {
    display: flex; align-items: center;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.1); border-bottom-width: 1px;
}
.item_price {
    color: lightcoral; font-size: 5vw; 
    padding: 4.5vw;
}
.item_add_area {
    display: flex; align-items: center;
    margin: 0; margin-left: auto; margin-right: 6.5vw;
}
.item_add_btn {
    background: lightseagreen;
    display: flex; align-items: center; justify-content: center;
    color: black; font-size: 3vw; text-align: center; font-weight: bold;
    width: 6.5vw; height: 6.5vw; opacity: 0.8;
    border-style: solid; border-color: darkgray; border-width: 1px; border-radius: 2.5px;
}
.item_add_btn:focus, .item_add_btn:active {
    background: lightsalmon; opacity: 1;
    color: black; border-color: white; border-width: 2px;
}
.item_add_num {
    display: flex; align-items: center; justify-content: center;
    color: black; font-size: 3.75vw; text-align: center; font-weight: bold;
    width: 8.5vw; height: 6.5vw;
}
.item_description {
    background-color: rgba(30, 180, 170, 0.05); opacity: 0.75;
    height: auto; margin: 4.5vw; padding: 2vw;
    border-style: solid; border-radius: 5px; border-color: rgba(0, 0, 0, 0.05); border-width: 1px;
    box-shadow: 2.5px 2.5px 10px rgb(0, 0, 0, 0.25);
}
.item_description_icon {
    width: 4.5vw; height: 4.5vw;
    position: relative; bottom: calc(5vw - 100%); right: calc(5vw - 100%); z-index: 300;
    background-size: cover; background-repeat: no-repeat; opacity: 0.75;
    transform:rotate(20deg); -ms-transform:rotate(20deg); /* IE 9 */ -webkit-transform:rotate(20deg); /* Safari and Chrome */
}
.item_empty {
    width: 94vw; height: 50vw;
    padding-top: 20vw;
}
.item_loading {
    width: 100vw; height: 75vw; /*calc(100vh - 56.25vw)*/;
    display: flex; align-items: center; justify-content: center;
}