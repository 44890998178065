.color_brown {
    color: rgba(120, 85, 85, 1);
}
.bgc_brown {
    background-color: rgba(120, 85, 85, 1);
}
.color_brown_light {
    color: rgba(120, 85, 85, 0.75);
}
.bgc_green_light {
    background-color: rgba(120, 85, 85, 0.75);
    background-color: rgba(0, 139, 139, 0.75);
    /* background-color: rgba(255, 160, 122, 0.5); */
}
.color_brown_super_light {
    color: rgba(120, 85, 85, 0.5);
}
.bgc_brown_super_light {
    background-color: rgba(120, 85, 85, 0.1);
}
.color_cyan {
    color: rgba(150, 185, 185, 1);
}
.bgc_cyan {
    background-color: rgba(150, 185, 185, 1);
}