.user_edit_info_row {
    display: flex; align-items: center;
    margin-bottom: 15px;
}
.user_edit_info_title {
    font-weight: bold;
    min-width: 80px; 
}
.user_edit_info_input_short {
    width: 100px;
}
.user_edit_info_input {
    width: 200px;
}
.user_edit_info_input_long {
    width: 75%;
}
.copy_info_row {
    display: flex; align-items: center;
}
.copy_info_text {
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
    width: auto; min-width: 50px; margin-right: 10px;
}
.copy_info_button {
    background: darkcyan;
    display: flex; align-items: center; justify-content: center;
    color: white; font-weight: bold;
    width: 60px; min-width: 60px; max-width: 60px;
    height: 30px; border-radius: 5px;
}
.copy_info_button:active {
    opacity: 0.5;
}

.user_info_jw_title {
    width: 900px; height: auto;
    color: darkcyan; font-weight: bold; font-size: 17.5px;
    display: flex; align-items: center; 
    padding-bottom: 5px; margin-left: 25px; margin-bottom: 5px;
    border-bottom-style: solid; border-bottom-color: darkcyan; border-bottom-width: 1px;
}
.user_info_jw_area {
    width: 900px; height: auto; min-height: 100px;
    margin-left: 25px; margin-bottom: 50px;
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer */
    -khtml-user-select: text; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: text; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: text; /* Disable Android and iOS callouts*/
}
.user_info_jw_row {
    display: flex; align-items: center; 
    color: rgba(0, 0, 0, 0.75); font-size: 15px; font-weight: bold;
    margin-bottom: 15px;
}
.user_info_jw_column {
    display: flex; align-items: center; 
    width: 250px; margin-right: 100px; margin-left: 20px; margin-top: 20px; 
}
.user_info_jw_address_area {
    background: rgba(0, 139, 139, 0.1);
    border-radius: 5px; margin-left: 0px;
    padding: 0px; padding-left: 20px; padding-top: 15px; padding-bottom: 10px;
    width: 100%; height: auto; min-height: 100px;
}
.user_info_jw_copy_button {
    display: flex; align-items: center; justify-content: center;
    background:darkcyan; color: white; font-weight: bold;
    width: 90px; height: 35px; border-radius: 5px;
    margin-left: 20px;
}
.user_info_jw_copy_button:hover {
    cursor: pointer;
}
.user_info_jw_copy_button:active {
    opacity: 0.75;
}