.store_page {
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    position: fixed; top: 0vw; left: 0vw;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: scroll;
}
.store_page_container {
    width: 100vw; height: calc(100vh - 14vw); margin-top: 14vw;
}
.store_page_bottom_bar {
    background: lightblue;
    width: 100vw; height: 14vw;
    position: fixed; bottom: 0; left: 0; z-index:100;
}
.store_actives_area {
    position: absolute; right: 2vw; top: 14vw; z-index: 99;
    width: 10vw; height: 56.25vw; opacity: 0.6;
    padding:0; padding-top: 13vw;
}
.store_actives_btn {
    background-size: cover; background-repeat: no-repeat;
    width: 7vw; height: 7vw;
    margin: 1vw; margin-bottom: 3vw;
}
.store_star_btn {
    position: absolute; right: 3vw; top: 60vw; z-index: 99;
    background-size: cover; background-repeat: no-repeat;
    width: 6.5vw; height: 6.5vw; opacity: 1;
    border-radius: 50px; border-style: solid;  border-width: 2px; border-color: white;
}
.store_start_btn:active {
    background-color: white;
    background-image: url("../../picture/Starred.png"); 
}
.store_logo_area {
    width: 100vw; height: 0px;
}
.store_logo {
    background-color: white;
    position: relative; top: -10.5vw; z-index: 90;
    width: 21vw; height: 21vw;
    margin: 0; margin-left: 40vw; margin-right: auto; padding: 1vw;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.2); border-radius: 10px;
}
/* .store_lineup_area {
    position: relative; top: -14vw; left: calc(100vw - 7.11vw); z-index: 90;
    width: 11vw; height: 17vw;
}
.store_lineup_btn {
    display: flex; align-items: center; justify-content: center;
    background-color: lightseagreen; opacity: 0.75;
    width: 11vw; height: 11vw; margin-bottom: 0.11vw;
    border-radius: 50px; border-color: lightslategray; border-style: solid; border-width: 1px;
    box-shadow: 5px 5px 8px rgb(0, 0, 0, 0.2);
}
.store_lineup_btn:focus, .store_lineup_btn:active {
    background: lightgreen; border-color: rgba(0, 157, 255, 0.05); opacity: 1;
}
.store_lineup_icon {
    background-size: cover; background-repeat: no-repeat;
    width: 3.11vw; height: 3.11vw;
}
.store_lineup_text {
    color: black; font-size: 1.14vw; text-align: center;
    width: 11vw; height: 1.14vw;
} */
/* 商家名称 & 热度 */
.store_key_info {
    width: 100vw; height: auto;
    margin: 0; padding: 0;
}
.store_name {
    color: black; font-weight: bold; font-size: 5vw; text-align: center;
    margin: 1vw; margin-top: 18vw; margin-bottom: 3vw;
}
.store_hot_area {
    display: flex; align-items: center; justify-content: center;
    width: auto; height: 11vw;
}
.store_mall_info {
    color: grey; font-size: 3vw; text-align: right;
    width: 32.5vw; margin-top: 15vw; z-index: 299;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
    position: absolute; top: calc(56.25vw + 3vw); right: 3vw;
}
.store_mall_info:active {
    color: lightsalmon;
}
.store_main_tabs {
    background: white;
    font-weight: bold;
    width: 100vw; height: auto;
    margin: 0; padding-right: 0;
}
/* .store_tab_pane {
    color: rgba(120, 85, 85, 1);
    font-size: 4.5vw;
}
.store_tab_pane_active {
    font-weight: bold;
} */
.store_main_tabs_loading {
    color: lightsalmon;
    width: 100vw; height:43vw;
}
.store_tab_button {
    display: flex; align-items: center; justify-content: center;
    width: 15vw; margin: 0; margin-left: 2vw; margin-right: 1vw; padding: 0;
}
.store_tab_text {
    color: rgba(120, 85, 85, 0.65); font-size: 4.5vw; font-weight: normal;
    padding: 0; margin: 0; margin-top: 1vw;
}
.store_tab_text_active {
    color: rgba(120, 85, 85, 0.85);font-weight: bold; font-size: 4.5vw; padding-top: 1vw; padding-left: 0vw;
}
.store_tab_text_hide {
    opacity: 0;
}

.ant-tabs-tab {
    color: rgba(120, 85, 85, 1) !important;
    justify-items: center;
}
.ant-tabs-tab-active {
    color: rgba(120, 85, 85, 1) !important;
}
.ant-tabs-ink-bar {
    background-color: transparent !important;
  }
  
.ant-tabs-top .ant-tabs-ink-bar-animated:before {
    content: " "; color: rgba(120, 85, 85, 0.85);
    background-color: rgba(120, 85, 85, 0.85);
    width: 100%; height: 1px;
    position: absolute; margin: auto;
}

.store_tab_area_empty{
    background: lightpink;
    width: 100vw; height: 0;
}
/* .store_item_tab_area {
    display: flex; align-items: top;
    width: 100vw; height: auto; padding: 0; margin: 0; margin-top: 5vw;
    overflow: visible;
} */
.store_item_type_sidebar {
    background: lightcoral;
}
.store_item_list_area {
    width: 100vw; height: auto; min-height: calc(100vh - 14vw - 17.5vw);
    height: calc(100vh - 14vw - 17.5vw);
    margin: 0; padding: 0;
    overflow-x: hidden; overflow-y: scroll;
}
.store_ticket_list_area {
    width: 100%; height: auto; min-height: calc(100vh - 14vw - 17.5vw);
    height: calc(100vh - 14vw - 17.5vw);
    margin: 0; padding: 0;  padding-top: 2.5vw;
    overflow-x: hidden; overflow-y: scroll;
}
.store_details_area {
    width: 100vw; height: auto; min-height: calc(100vh - 14vw - 17.5vw);
    height: calc(100vh - 14vw - 17.5vw);
    margin: 0; padding: 0;
    overflow: hidden;
}
.sidebar_details_icon {
    width: 4.5vw; height: 4.5vw;
    margin: 1vw; margin-right: 1.5vw; margin-top: 0;
    background-size: cover; background-repeat: no-repeat; opacity: 0.75;
}
.store_description_icon {
    width: 5vw; height: 5vw;
    position: absolute; top: 30vw; right: 7.5vw;
    background-size: cover; background-repeat: no-repeat; opacity: 0.75;
    transform:rotate(20deg);
    -ms-transform:rotate(20deg); /* IE 9 */
    -webkit-transform:rotate(20deg); /* Safari and Chrome */
}
.store_description {
    background-color: rgba(30, 180, 170, 0.05);
    color: black; font-size: 3.5vw; 
    width: auto; height: auto; opacity: 0.75;
    margin: 5vw; margin-top: 0; margin-bottom: 4vw; padding: 2.5vw;
    border-style: solid; border-radius: 5px; border-color: rgba(0, 0, 0, 0.05); border-width: 1px;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.25);
}
.store_details_contact {
    display: flex; align-items: center;
    margin: 5vw; margin-top: 0; margin-bottom: 3vw;
}
.store_details_address {
    display: flex; align-items: center;
    margin: 5vw; margin-top: 0; margin-bottom: 3vw;
}
.store_details_address_text {
    width: 95%; height: auto;
     white-space: nowrap;  text-overflow: ellipsis; overflow: hidden;
}
.store_details_time_area {
    display: flex; align-items: center;
    margin: 5vw; margin-top: 0; margin-bottom: 3vw;
}
.store_details_time_title {
    display: flex; align-items: center;
}
.store_details_time_show {
    color: lightsalmon;
    font-weight: bold;
}
.store_details_time_list {
    margin: 5px; opacity: 0.75;
}
.store_details_time_list_bold {
    color: lightsalmon;
    font-weight: bold; opacity: 1;
}
.store_details_map {
    color: gray; text-align: center; font-weight: bold; font-size: 4.5vw;
    background: lightsalmon;
    width: 90vw; height: 60vw; margin: 5vw; margin-top: 0;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
    overflow: hidden;
}
.store_ads_ticket {
    width: 100vw; height: 37vw;
    display: flex; align-items: center; justify-content: center;
    margin: 0; padding: 0;
}
.ads_ticket_bgp {
    background-size: cover; background-repeat: no-repeat;
    display: flex; align-items: center; opacity: 1;
    width: 90vw; height: 27vw;
    padding: 0; margin: 0;
    /* box-shadow: 5px 5px 5px rgba(120, 85, 85, 0.1); */
    border-style: solid; border-color: rgba(120, 85, 85, 0.075); border-width: 1px; border-radius: 4px;
}
.store_bottom_space {
    width: 100vw; height: 14vw;
}
.store_bottom_space_side {
    width: 27.5vw; height: 14vw;
}
.store_bottom_cart_area {
    background: rgb(50, 60, 80); z-index: 300;
    display: flex; align-items: center;  justify-content: center;
    position: fixed; bottom: 0; left: 0;
    width: 100vw; height: 14vw;
}
.store_cart_icon_area {
    display: flex; align-items: center; justify-content: center;
    width: 10vw; height: 10vw; margin: 0; margin-left: 3vw; margin-right: auto;
    border-radius: 50px;
}
.store_cart_icon {
    background-size: cover; background-repeat: no-repeat;
    width: 10vw; height: 10vw;
}
.store_item_num {
    display: flex; align-items: center; justify-content: center;
    background-color: lightsalmon;
    color: black; font-weight: bold; font-size: 3vw;
    position: absolute; bottom: 8.5vw; left: 8.5vw;
    width: 5.5vw; height: 4.5vw;
    border-radius: 40px;
}
.store_cart_area_title {
    display: flex; align-items: center; justify-content: center;
    color: lightsalmon; font-weight: bold; font-size: 5.5vw;
    width: auto; min-width: 60vw;  height: 14vw; margin: 0; margin-right: auto;
}
.store_cart_area_right_space {
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 6.5vw;
    width: 8.5vw; height: 14vw;
}
.store_cart_area_btn {
    background: lightsalmon;
    display: flex; align-items: center; justify-content: center;
    color: black; font-weight: bold; font-size: 4.5vw; text-align: left;
    width: 26vw; height: 14vw;
    margin-right: 0;
}
.store_cart_area_btn:active {
    background: lightskyblue;
}
.store_checkout_button {
    display: flex; align-items: center; justify-content: center;
    background: lightsalmon;
    color: black;  font-size: 5vw; font-weight: bold;
    width: 86vw; height: 14vw;
    position: fixed; bottom: 0; left: 14vw; z-index: 300;
}
.store_checkout_button:active {
    background: lightskyblue; color: white; font-weight: bold;
}
.store_cart_clear_button {
    display: flex; align-items: center; justify-content: center;
    background: lightcoral;
    color: black;  font-size: 5vw;
    width: 14vw; height: 14vw;
    position: fixed; bottom: 0; left: 0; z-index: 300;
}
.store_cart_clear_button:active {
    background: lightsalmon; color: white; font-weight: bold;
}
.store_page_hot_stars {
    background-size: cover; background-repeat: no-repeat;
    width: 6vw; height: 6vw;
    margin: 1vw; border-radius: 5px;
}
.store_budget_details_area {
    width: 100vw; height: 100%;
    overflow: hidden;
}
.store_budget_details_title {
    background: rgb(50, 60, 80);
    display: flex; align-items: center;  justify-content: center;
    width: 100vw; height: 14vw;
}
.store_budget_details_contents {
    height: auto; max-height: calc(75vh - 28vw);
    margin: 0;
    overflow-y: scroll;
}
.store_budget_item_num {
    display: flex; align-items: center; justify-content: center;
    background-color: lightsalmon;
    color: black; font-weight: bold; font-size: 3.5vw;
    position: absolute; top: 1.5vw; left: 10vw;
    width: 5vw; height: 4vw; padding-top: 0.5vw;
    border-radius: 40px;
}
.store_budget_details_item {
    color: black;
    display: flex; align-items: center;
    width: 100vw; height: 20vw; padding: 3vw; padding-right: 1vw;
    border-bottom-style: solid; border-bottom-color: rgba(211, 211, 211, 0.5); border-bottom-width: 1px;
}
.store_budget_details_item_logo {
    background-color: white;
    width: 14vw; height: 14vw;
    min-width: 14vw; min-height: 14vw;
    max-width: 14vw; max-height: 14vw;
    margin-right: 4vw;
    border-radius: 50px; box-shadow: 3px 3px 6px rgb(0, 0, 0, 0.5);
    background-size: auto, auto; background-repeat: no-repeat;
}
.store_budget_details_item_name {
    color: rgba(0, 0, 0, 0.75); font-size: 4vw; font-weight: bold;
    width: 63vw; margin-right: 2vw; padding-top: 1.75vw;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
/* .store_budget_details_item_num {
    width:auto; min-width:15vw;
    display: flex; align-items: right;
    color: black; font-size: 4.5vw; font-weight: bold;
    margin-left: auto; margin-right: 0;
} */
.store_budget_details_item_price {
    width:auto; min-width:16vw;
    display: flex; align-items: right;
    color: lightsalmon; font-size: 4vw;
    margin-left: 4vw; margin-right: 1vw;
}
.store_item_list_loading {
    display: flex; justify-content: center; align-items: center; 
    width: 100%; height: 40vw; padding-top: 20vw;
}
.store_item_type_dropdown{
    background: rgba(255, 255, 255, 0.85);
    display: flex; justify-content: center; align-items: center; 
    width: 90vw; height: 10vw;
    margin: 5vw; border-radius: 4px;
    position: absolute; top: 15vw; left: 0; z-index: 400;
    border-style: solid; border-color: grey; border-width: 1px;
}
