.page_basic {
    /* background: white; */
    background: rgba(0, 139, 139, 0.01);
    background: #188fff01;
    width: 100vw; height: 100vh;
}
.home_page {
    color: black;
    width: 100vw; height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: scroll;
}
.affix_navbar_container {
    position: relative;
    top: -5vw; height: 5vw;
}
.affix_navbar {
    display: flex; align-items: center;
    position: relative; top: 0px; left: 0px;
    margin: 0; margin-left: auto; margin-right: auto;
    z-index: 100; overflow: hidden;
}
.affix_navbar_white {
    background: white; width: 74vw;
    border-radius: 4px; opacity: 0.9;
    padding: 0; 
    height: 10vw;
}
/* .affix_navbar_blue {
    background: lightblue; width: 100vw;
    border-radius: 0px; opacity: 1;
} */
@keyframes navbar_affixed_in {  
    0% {opacity: 0.9; width: 74vw; height: 10vw;
        background: white; border-radius: 4px;}
    25% {opacity: 0.925; width: 80.5vw; height: 11vw;
        background: rgb(247, 255, 255); border-radius: 3px;}
    50% {opacity: 0.95; width: 87vw; height: 12vw;
        background: rgb(240, 255, 255); border-radius: 2px;}
    75% {opacity: 0.975; width: 93.5vw; height: 13vw;
        background: rgb(233, 255, 255); border-radius: 1px;}
    100% {opacity: 1; width: 100vw; height: 14vw;
        background: lightsalmon; border-radius: 0px;}
}  
@-webkit-keyframes navbar_affixed_in {/*针对webkit内核*/  
    0% {opacity: 0.9; width: 74vw; height: 10vw;
        background: white; border-radius: 4px;}
    25% {opacity: 0.925; width: 80.5vw; height: 11vw;
        background: rgb(247, 255, 255); border-radius: 3px;}
    50% {opacity: 0.95; width: 87vw; height: 12vw;
        background: rgb(240, 255, 255); border-radius: 2px;}
    75% {opacity: 0.975; width: 93.5vw; height: 13vw;
        background: rgb(232, 255, 255); border-radius: 1px;}
    100% {opacity: 1; width: 100vw; height: 14vw;
        background: lightsalmon; border-radius: 0px;}
}
.navbar_affixed_in {
    animation: navbar_affixed_in;/*动画名称*/  
    animation-duration: 0.2s;/*动画持续时间*/  
    -webkit-animation: navbar_affixed_in 0.2s;/*针对webkit内核*/
    background: lightsalmon; width: 100vw; height: 14vw; 
    border-radius: 0px; border: none; opacity: 1;
    padding-left: 11vw; padding-right: 15vw;
}
@keyframes navbar_affixed_out {  
    0% {opacity: 1; width: 100vw;  height: 14vw;
        background: lightsalmon; border-radius: 0px;}
    25% {opacity: 0.975; width: 93.5vw;  height: 13vw;
        background: rgb(232, 255, 255); border-radius: 1px;}
    50% {opacity: 0.95; width: 87vw;  height: 12vw;
        background: rgb(240, 255, 255); border-radius: 2px;}
    75% {opacity: 0.925; width: 80.5vw;  height: 11vw;
        background: rgb(247, 255, 255); border-radius: 3px;}
    100% {opacity: 0.9; width: 74vw;  height: 10vw;
        background: white; border-radius: 4px;}
}  
@-webkit-keyframes navbar_affixed_out {/*针对webkit内核*/  
   0% {opacity: 1; width: 100vw; 
        background: lightsalmon; border-radius: 0px;}
    25% {opacity: 0.975; width: 93.5vw; 
        background: rgb(232, 255, 255); border-radius: 1px;}
    50% {opacity: 0.95; width: 87vw; 
        background: rgb(240, 255, 255); border-radius: 2px;}
    75% {opacity: 0.925; width: 80.5vw; 
        background: rgb(247, 255, 255); border-radius: 3px;}
    100% {opacity: 0.9; width: 74vw; 
        background: white; border-radius: 4px;}
}
.navbar_affixed_out {
    animation: navbar_affixed_out;/*动画名称*/  
    animation-duration: 0.25s;/*动画持续时间*/  
    -webkit-animation: navbar_affixed_out 0.25s;/*针对webkit内核*/
    background: white; width: 74vw; height: 10vw; 
    border-radius: 5px; opacity: 0.9;
    padding: 0;
}
.ads_carousel_container {
    width:100vw; height: 56.25vw; overflow: hidden;
}
.home_page_bottom_space {
    width: 100vw; height: 8vw;
}