.tmall_ubi_page{
    /* background: lightsalmon; */
    /* display: flex; align-items: center;justify-content: center; */
    width: calc(100vw - 200px); height: 100%;
    padding: 100px; margin-left:100px; margin-right: 100px;
    min-width:500px;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.tmall_logout_button {
    /* background: lightblue; */
    position: fixed; top: 15px; right: 15px; z-index: 200;
    width: 100px; height: auto;
    text-decoration: underline; color: gray; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
}
.tmall_logout_button:hover {
    cursor: pointer;
}
.tmall_logout_button:active {
    opacity: 0.5;
}
.tmall_order_login_title {
    color: gray; font-weight: bold; font-size: 20px;
    width: 100%; display: flex; align-items: center;justify-content: center;
    margin-left: auto; margin-right: auto; margin-bottom: 15px; margin-top: 20px;
}
.tmall_order_login_form {
    /* background: rgba(135, 207, 235, 0.25); */
    background: rgba(255, 255, 255, 0.5);
    background: rgba(173, 216, 230, 0.1);
    width: 350px; height: auto;
    padding: 50px; margin-left: auto; margin-right: auto;
    /* margin: auto; margin-top: 30vh; */
    border-radius: 5px;
    /* border-style: solid; border-width: 1px; border-color: rgba(0, 0, 0, 0.1); */
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.2);
}
.tmall_ubi_title {
    /* background: lightblue; */
    width: 100%; height: 50px;
    padding-left: 10px;
    display: flex; align-items: center;
    /* border-bottom-style: solid; border-bottom-width: 0.5px; border-bottom-color: gray; */
}
.tmall_ubi_orders_area_loading {
    /* background: lightseagreen; */
    color: black;
    width: 100%; height: 50vh;
    padding-top: 100px;
    display: flex; justify-content: center;
    align-items: center; 
}
.tmall_ubi_orders_area {
    /* background: lightblue; */
   
    width: 100%; height: 100%; max-width: 100%; max-height: 100%;
    padding-top: 10px;
    overflow: auto;
}
.tmall_ubi_add_button {
    background: darkcyan;
    margin: 15px; margin-left: 20px; margin-bottom: 5px;
    padding: 10px; padding-top: 5px; padding-bottom:5px;
    color: white; font-size: 15px; font-weight: bold;
    display:flex; align-items: center; justify-content: center;
    width: auto; height: auto;
    border-radius: 2.5px;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.tmall_ubi_add_button:active {
    opacity: 0.5;
}
.tmall_ubi_add_button:hover {
    cursor: pointer;
}
.tmall_ubi_item_list_area {
    background: rgba(32, 178, 171, 0.025);
    width: 95%; height: auto; max-height: 30vh;
    margin-left: 2.5%; margin-right: 2.5%;
    padding: 2%; border-radius: 5px;
    border-style: solid; border-width: 0.5px; border-color: rgba(0, 0, 0, 0.25);
    overflow: auto;
}
.tmall_order_print_button {
    display:flex; align-items: center; justify-content: center;
    color: #1890ff; font-weight: bold;
}
.tmall_order_print_button:active {
    opacity: 0.5;
}
.tmall_order_print_button:hover {
    cursor: pointer;
}
.tmall_order_print_button_disable {
    display:flex; align-items: center; justify-content: center;
    color: grey; font-weight: normal;
}
.tmall_order_print_remind{
    width: 7.5px; height: 7.5px;
    margin-left: 5px; margin-bottom: 15px;
    background: lightcoral;
    border-radius: 100px;
}
.tmall_order_status_dropdown {
    background: white;
    width: 110px; height: 32.5px;
    margin-left: 10px;
    display:flex; align-items: center; justify-content: center;
    border-radius: 5px; border-style: solid; border-width: 0.5px; border-color: rgba(0, 0, 0, 0.2);
}
.tmall_order_normal_row {
    background: white;
    font-weight: normal;
}
.tmall_order_complete_row {
    background: rgba(211, 211, 211, 0.5);
    font-weight: lighter;
}
