.order_details_page {
    background-color: rgba(128, 128, 128, 0.1);
    color: black;
    /* font-weight: bold; */
    /* font-family: "微软雅黑"; */
    font-family:Arial,Helvetica,sans-serif;
    position: fixed; top: 0; left: 0;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden;
}
.order_details_title {
    position: fixed; left: 0; top: 0; z-index: 500;
    width: 100%; height: 12vw;
    background-color: lightskyblue;
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.75); font-weight: bold; font-size: 4vw;
}
.order_details_back_btn {
    width: 6vw; height: 6vw;
    background-size: cover; background-repeat: no-repeat;
    position: fixed; left: 3vw; top: 3vw;
}
.order_details_googlemap_area {
    width: 96vw; height: 54vw;
    background-color: lightsalmon;
    margin-top: 14.5vw; margin-left: 2vw; margin-bottom: 5vw;
    border-radius: 1vw;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.25);
}
.order_detials_pickup_options {
    width: 100%; height: 12vw;
    display: flex; align-items: center; justify-content: center;
    margin-top: 2vw;
}
.order_details_info_area {
    background: white;
    width: 96vw; height: auto;
    margin: 1.5vw; margin-left: 2vw; margin-right: 2vw; border-radius: 1vw;
    /* border-top-style: solid; border-top-width: 5px; border-top-color: rgba(0, 0, 0, 0.01); */
}
.order_details_info_row {
    width: 90vw; height: 12vw;
    color: black; font-size: 4vw;
    display: flex; align-items: center;
    margin-left: 2vw;
    border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(0, 0, 0, 0.1);
}
.order_details_info_row_text {
    
    width: 65vw; height: 12vw; padding-top: 3vw; font-size: 4vw;
    overflow: hidden; white-space: nowrap;  text-overflow: ellipsis;  
}
.order_details_distance_text {
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw; font-weight: normal;
}
.order_details_icon {
    width: 6.5vw; height: 6.5vw;
    margin: 0; margin-right: 5vw;
    background-size: cover; background-repeat: no-repeat;
    border-radius: 50px;
}
.order_details_distance_icon {
    width: 6.5vw; height: 6.5vw;
    margin: 0; margin-right: 1vw; margin-left: 6vw;
    background-size: cover; background-repeat: no-repeat;
}
.order_details_no_bottom_line {
    border-bottom-width:0;
}
.order_details_main_font {
    color: black; font-weight:normal; font-size: 4vw; 
}
.order_details_itme_name_font {
    font-weight: normal; font-size: 3.5vw;
}
.order_details_orange_color {
    color: lightsalmon;
}
.order_details_lightgray_color {
    color: lightgray;
    display: flex; align-items: center; justify-content: center;
}
.order_details_total_price {
    display: flex; align-items: center;
    margin-left: auto; margin-right: 0vw;
    color: lightsalmon; font-size: 4vw;
}
.order_details_confirm_area {
    display: flex; align-items: center; justify-content: center;
    width:100vw; height: 12.5vw;
    background: lightskyblue;
    margin-top: 2vw;
    color: rgba(0, 0, 0, 0.75); font-size: 4vw; font-weight: bold;
}
.order_details_confirm_area:active {
    background: lightsalmon; color: white;
}
.order_details_price {
    color: lightsalmon; font-size: 4.5vw; text-align: right;
    width: 20vw; min-width: 20vw;
    margin-left: auto; margin-right: 0vw;
}
.order_details_num {
    color: rgba(0, 0, 0, 0.75); width: 15vw; min-width: 15vw;
    justify-content: left;
}
.order_details_right_side_option {
    text-align: right; /*padding-top: 3.25vw;*/
    width: 30vw; min-width: 30vw; height: 10vw;
    color: rgba(0, 0, 0, 0.5); z-index: 300;
    margin-left: auto; margin-right: 0;
}
/* .order_dertails_time_pickler {
    
} */
.order_details_payment_method {
    width: 100%; height: auto;
    border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1); border-top-width: 1px;
    margin-top: 3vw; padding-top: 3vw;
}
.new_card_button_area {
    display: flex; align-items: center;
    width: 100%; height: 10vw;
    margin-top: 3.5vw;
}
.new_card_submit_button {
    background:lightskyblue; color: rgba(0, 0, 0, 0.75); font-size: 3.5vw;
    width: 24vw; height: 8vw;
    border: none; border-radius: 4px;
    margin-left: auto; margin-right: 0;
}
.new_card_submit_button:active {
    background: lightsalmon; color: white;
}
.new_card_submit_button:focus {
    outline: none;
}
.new_card_cancle_button {
    display: flex; align-items: center; justify-content: center;
    background: rgba(211, 211, 211, 0.5); color: grey;  font-size: 3.5vw;
    width: 16vw; height: 8vw;
    border: none; border-radius: 4px;
    margin-left: 0; margin-right: auto;
}
.new_card_cancle_button:active {
    background: grey; color: white;
}
.new_card_cancle_button:focus {
    outline: none;
}
.payment_tips {
    margin-bottom: 2vw;
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw;
}
.payment_info_input {
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw;
    width: 100%; height: 40px;
    padding: 10px 12px; margin-bottom: 2.5vw; 
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}
.payment_info_input--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}
.payment_info_input--invalid {
    border-color: lightcoral;
}
.payment_info_input--webkit-autofill {
    background-color: #fefde5 !important;
}
.card_info_row {
    display: flex; align-items: center;
    width: 78vw; height: 8vw;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.05); border-bottom-width: 1px;
}
.card_brand_icon {
    display: flex; align-items: center;
    width: 7.5vw; height: 7.5vw; 
    min-width: 7.5vw; min-height: 7.5vw; 
    background-size: cover; background-repeat: no-repeat;
    margin-left: 2vw; margin-right: 0vw;
}
.card_info_element {
    display: flex; align-items: center;
    margin-left: 1vw; margin-right: 5vw;
    width:auto; min-width: 10vw; height: 8vw;
    color: rgba(0, 0, 0, 0.75);font-size: 3.5vw;
}
.no_card_yet_row {
    display: flex; align-items: center;
    width: 100%; height: 8vw; margin-top: 2vw;
}
.add_new_card_button {
    display: flex; align-items: center; justify-content: center;
    background: lightskyblue; color: rgba(0, 0, 0, 0.75); font-size: 3.5vw;
    width: 22.5vw; height: 8vw; margin-top: 1vw; border-radius: 4px;
}
.add_new_card_button:active {
    background: lightsalmon;
    color: white;
}
.card_remove_button {
    background: lightgray;
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 3vw; font-weight: bold;
    margin-left: auto; width: 4vw; height: 4vw;
    border-radius: 40px;
}
.card_remove_button:active {
    background: lightcoral; color: black;
}
.card_remove_confirm_area {
    display: flex; align-items: center; justify-content: center;
    padding: 0; margin: 0;
}
.card_remove_confirm_button {
    display: flex; align-items: center; justify-content: center;
    background: lightcoral; font-size: 3vw; color: white;
    width: 12vw; height: 6vw; border-radius: 4px; margin: 2vw;
}
.card_remove_confirm_button:active {
    background: lightskyblue;
}
.card_remove_cancel_button {
    display: flex; align-items: center; justify-content: center;
    background: lightgrey; font-size: 3vw; color: black;
    width: 12vw; height: 6vw; border-radius: 4px; margin: 2vw;
}
.align_right {
    margin-left: auto; margin-right: 0; margin-top:0; z-index: 300;
}
.whole_page_loading_cover {
    position: fixed; top:0; left: 0; z-index: 10001;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw; height: 100vh;
    display: flex; align-items: center; justify-content: center;
}
.whole_page_loading_cover_hide {
    width: 0; height: 0; background: rgba(0, 0, 0, 0);
}
.select_card_brief_info {
    /* background: lightseagreen; */
    display: flex; align-items: center;
    width: auto; min-width: 10vw; height: 10vw;
    /* padding-top: 3vw;text-align: right; color: rgba(0, 0, 0, 0.5); */
}
.select_card_brief_last4 {
    /* background: lightseagreen; */
    width: auto; text-align: right;
    margin-left: 2vw; margin-right: 0;
    color: rgba(0, 0, 0, 0.5); font-size:3.25vw; 
}
.payment_processing {
    display: flex; align-items: center; justify-content: center;
    padding: 2vw; color: darkcyan; font-size: 4.5vw; font-weight: bold;
}
.order_details_info_change {
    color: rgba(0, 0, 0, 0.5); font-size: 4vw;
}
.font-adjust {
    font-size: 4vw;
}
.order_details_grey_text {
    color: rgba(0, 0, 0, 0.5); font-size: 4vw;
}
.order_details_tips {
    display: flex; align-items: center;
    width: 5vw; height: 5vw; z-index: 350;
}
.order_details_row_middle {
    display: flex; align-items: center; justify-content: center;
}
.order_details_coupon_area {
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.5); font-size: 4vw;
    width: 100%; height: auto;
    padding: 3vw; padding-top: 2vw; padding-left:5vw;
}
.order_details_coupon_item {
    width: auto; height: 8vw;
    display: flex; align-items: center; 
}
.order_details_ticket_title {
    display: flex; align-items: center; justify-content: center;
    color: black; font-weight: bold; font-size: 3.5vw;
    width: 35vw; height: 8vw;margin: 0; padding: 0; padding-left: 1vw; padding-right: 1vw;
    /* overflow: hidden; text-overflow: ellipsis;  超出控件宽度的文字用省略号代替 */
    border-style: solid; border-color: rgba(0, 0, 0, 0.1); border-width: 1px;
    border-radius: 5px;
}
.order_details_ticket_title_disable {
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw;
    width: 35vw; height: 8vw;margin: 0; padding: 0; padding-left: 1vw; padding-right: 1vw;
    border-style: solid; border-color: rgba(0, 0, 0, 0.1); border-width: 1px;
    border-radius: 5px;
}
.order_details_ticket_subtitle {
    display: flex; align-items: center; 
    width: 35vw; height: 8vw; margin: 0; padding: 0; padding-left: 2vw; padding-right: 1vw;
    color: rgba(0, 0, 0, 0.5); font-size: 3vw;
    /* overflow: hidden; text-overflow: ellipsis;  超出控件宽度的文字用省略号代替 */
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.1); border-bottom-width: 1px;
}
.ticket_used_sign {
    background: rgba(240, 128, 128, 0.5);
    color: rgba(0, 0, 0, 0.5); font-size: 3vw;
    display: flex; align-items: center; justify-content: center;
    width: 12vw; height: 5vw;
    border-radius: 4px;
}