.store_pickup_page {
    background-image: linear-gradient(white, rgb(250, 250, 250),  rgb(235, 235, 235));
    /* background: lightsalmon; */
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    position: fixed; top: 0vw; left: 0vw;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: auto;
}
.store_pickup_menu_reminder {
    background: lightsalmon;
    width: 2.25vw; height: 2.25vw; border-radius: 10vw;
    position: fixed; right: 2vw; top: 2.5vw;
}
.store_pickup_cart_reminder {
    background: lightsalmon;
    width: 2.5vw; height: 2.5vw; border-radius: 10vw;
    position: fixed; right: 0.5vw; bottom: 13vw;
}
.store_pickup_menu_cart_item_num {
    background: lightsalmon;
    display: flex; align-items: center; justify-content: center;
    width: 6.5vw; height: 6.5vw; margin-left: auto; margin-right: 4.25vw;
    color: black; font-size: 4vw; font-weight: bold;
    border-radius: 10vw;
}
.store_pickup_menu_button {
    background-color: rgba(0, 139, 139, 0.2);
    display: flex; align-items: center; justify-content: center;
    width: 10vw; height: 10vw;
    color: black; font-weight: bold; font-size: 6vw;
    position: fixed; top: 1vw; right: 1vw; z-index: 300;
    border-radius: 1.5vw;
}
.store_pickup_menu_button:active {
    background-color: white; color: darkcyan;
}
.store_pickup_menu_button_close {
    background-color: white;
    display: flex; align-items: center; justify-content: center;
    width: 15vw; height: 15vw;
    font-weight: bold; font-size: 6.5vw;
    position: fixed; top: 0vw; right: 0vw; z-index: 300;
    border-bottom-style: solid; border-bottom-width: 0.2vw; border-bottom-color: rgba(0, 0, 0, 0.1);
}
.store_pickup_menu_button_close:active {
    color: black;
}
.store_pickup_main_menu_row {
    width: 100vw; height: 15vw;
    display: flex; align-items: center;
    padding-left: 7.5vw;
    border-bottom-style: solid; border-bottom-width: 0.2vw; border-bottom-color: rgba(0, 0, 0, 0.1);
}
.store_pickup_main_menu_row:active {
    opacity: 0.65;
}
.store_pickup_menu_icon {
    color: darkcyan; font-size: 5.5vw; font-weight: bold; 
}
.store_pickup_main_menu_text {
    width: auto; min-width: 50vw;height: 8vw;
    color: darkcyan; font-size: 4.5vw; font-weight: bold; 
    display: flex; align-items: center;
    padding-left: 5vw;
}
.store_pickup_cart_button {
    background-color: rgba(0, 139, 139, 0.2);
    display: flex; align-items: center; justify-content: center;
    width: 12.5vw; height: 12.5vw;
    color: black; font-weight: bold; font-size: 7.5vw;
    position: fixed; bottom: 2.5vw; right: 1.5vw; z-index: 300;
    border-radius: 1.5vw;
}
.store_pickup_cart_button:active {
    background-color: white; color: darkcyan;
}
.store_pickup_name {
    color: black; font-weight: bold; font-size: 6vw; text-align: left;
    /* white-space: nowrap; text-overflow: ellipsis; overflow: hidden; */
    margin: 3.5vw;  margin-left: 5vw; margin-bottom: 1vw;
}
.store_pickup_location_row {
    display: flex; align-items: center; 
     white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
     color: black; font-size: 4vw;
    margin: 3.5vw; margin-top: 0vw; margin-left: 4.75vw; 
}
.store_pickup_location_text {
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
    color: rgba(0, 0, 0, 0.75); font-size: 4vw;
    margin-left: 1.5vw;
    padding-top: 0.5vw;
}
.store_pickup_main_tab_area {
    background: white; 
     display: flex; align-items: center;  justify-content: center;
    width: calc(100vw - 9vw); height: 14vw;
    margin: 4.5vw;  border-radius: 2vw; margin-top: 0;
    box-shadow: 2.5px 2.5px 2.5px rgb(0, 0, 0, 0.1);
}
.store_pickup_main_tab_button {
    background: white;
    display: flex; align-items: center;  justify-content: center;
    color: darkcyan; font-weight: bold; font-size: 4.75vw;
    width:43.5vw; height: 11vw;
    margin: 2vw; border-radius: 1.5vw;
}
.store_pickup_main_tab_button:active {
    opacity: 0.5;
}
.store_pickup_main_tab_button_select {
    background: darkcyan; color: white; 
}
.store_pickup_main_tab_button_select:active {
    opacity: 0.5;
}
.store_pickup_intro_area {
    background: white;
    border-radius: 2vw;
    margin: 4.5vw;  margin-top: 0;
    width: calc(100vw - 9vw); height: auto; 
    padding: 4vw; padding-top: 3vw;
    box-shadow: 2.5px 2.5px 2.5px rgb(0, 0, 0, 0.1);
}
.store_pickup_info_title {
    display: flex; align-items: center; 
    color: darkcyan; font-weight: bold; font-size: 4.5vw;
    margin-top: 0.75vw; margin-bottom: 2vw;
}
.store_pickup_intro_text {
    width: 75vw; height: auto;
    color: rgba(0, 0, 0, 0.75); font-size: 3.75vw;
    margin-left: 6vw; margin-top: 1.5vw;
}
.store_pickup_hour_row{
    display: flex; align-items: center;  
    margin-top: 0.75vw; margin-bottom: 0.75vw;
}
.store_pickup_hour {
    color: rgba(0, 0, 0, 0.75);
    margin-left: auto; margin-right: 7.5vw;
}
.store_pickup_more_details_tow {
    margin-top: 2.5vw; margin-bottom: 3.5vw;
}
.store_picup_info_text {
    color: rgba(0, 0, 0, 0.65); font-size: 4vw;
}
.store_pickup_map_container {
    background-color: lightcoral;
    width: 100vw; height: 100vw;
}
.store_pickup_info_map {
    width: 83vw; height: 60vw; 
    margin: 0; margin-top: 4vw; margin-bottom: 2vw;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
    border-radius: 2.5vw;
}
.store_pickup_time_location_row {
    display: flex; align-items: center; 
    color: rgba(0, 0, 0, 0.75); font-weight: bold; font-size: 4vw;
    margin-top: 1vw; margin-bottom: 1vw; margin-left: 1.25vw;
}
.store_pickup_time_location_text {
    margin-left: 1.75vw; margin-right: 2.5vw;
    padding-top: 1vw;
}
.store_pickup_status {
    display: flex; align-items: center;  justify-content: center;
    color: darkcyan; font-weight: bold; font-size: 3.5vw;
    margin-top: 1vw;
    padding-left: 1vw; padding-right: 1vw; padding-top: 0.5vw;
    border-radius: 1.5vw; border-style: solid; border-width: 0.6vw; border-color: darkcyan;
}
.store_pickup_item_types_line {
    background: darkcyan; color: white; font-weight: bold; font-size: 5vw;
    display: flex; align-items: center;  justify-content: center;
    width: 100vw; height: 14vw;
    box-shadow: 2.5px 2.5px 2.5px rgb(0, 0, 0, 0.1);
    overflow-y: hidden; overflow-x: scroll;
}
.store_pickup_item_types_menu {
    color: white; font-weight: bold; font-size: 5vw;
    width: auto; height: 100%; padding-top: 0.5vw;
    display: flex; align-items: center;  justify-content: center;
}
.store_pickup_item_type_title {
    display: flex; align-items: center; 
    height: 7.5vw; padding-left: 3vw;
    color: rgba(0, 0, 0, 0.75); font-weight: bold; font-size: 4.5vw;
}
.store_pickup_item_type_title_selected {
    color: darkcyan; 
}
.store_pickup_item_row{
    display: flex; align-items: center; 
    height: auto; padding-left: 2.5vw; 
    color: rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 4vw;
    border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1); border-top-width: 0.2vw;
}
.store_pickup_item_icon{
    display: flex; align-items: center; 
    background-size: cover; background-repeat: no-repeat;
    width: 12vw; height: 12vw;
    min-width: 12vw; min-height: 12vw; margin: 0.5vw; margin-left: 0;
    border-radius: 1vw;
    border-style: solid; border-color: rgba(0, 0, 0, 0.1); border-width: 0.2vw;
}
.store_pickup_item_name{
    display: flex; align-items: center; 
    min-height: 6vw; height: auto; padding-left: 1vw; 
    color: rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 3.75vw;
    margin: 1vw; padding-top: 2vw;
}
.store_pickup_item_price {
    display: flex;
    min-height: 6vw; height: 6vw; padding-left: 1vw; 
    margin: 1vw; margin-top: 0vw; margin-bottom: 2vw;
    color: rgba(0, 0, 0, 0.75); font-weight: bold; font-size: 4vw;
}
.store_pickup_item_details_dlg{
    background: white;
    width: 100vw; height: 100vh;
}
.store_pickup_item_details_title {
    background: white;
    display: flex; align-items: center;  justify-content: center;
    width: 100vw; height: 12vw; padding-top: 1.25vw;
    border-bottom-style: solid; border-bottom-width: 0.2vw; border-bottom-color: rgba(0, 0, 0, 0.1);
    position: fixed; top: 0; left: 0; z-index: 300;
}
.store_pickup_item_details_back_button {
    display: flex; align-items: center; justify-content: center;
    width: 12vw; height: 12vw;
    color: darkcyan; font-size: 6.5vw; font-weight: bold;
    position: fixed; left: 0; top: 0; z-index: 400;
}
.store_pickup_item_details_delete_button {
    display: flex; align-items: center; justify-content: center;
    width: 12vw; height: 12vw;
    color: darkcyan; font-size: 6.5vw; font-weight: bold;
    position: fixed; right: 0; top: 0; z-index: 400;
}
.store_pickup_item_details_picture {
    background-color: rgba(0, 139, 139, 0.01);
    /* background-color: white; */
    background-size: cover; background-repeat: no-repeat;
    width: 100vw; height: 56.25vw;
    margin-top: 12vw; 
}
.store_pickup_item_details_store_name {
    color: darkcyan; font-size: 5vw; font-weight: bold;
}
.store_pickup_item_details_name{
    width: 100vw;
    color: black; font-size: 5vw; font-weight: bold;
    padding: 5vw; padding-bottom: 0vw;
}
.store_pickup_item_details_info {
    width: 100vw;
    padding: 5vw; padding-top: 2.5vw;
    color: rgba(0, 0, 0, 0.5); font-size: 4vw; 
}
/******** Modifier ********/
.store_pickup_item_details_modifier_area {
    width: 100vw; height: auto;
}
/* Modifier 标题 */
.store_pickup_item_details_modifier_title {
    background: rgba(200, 200, 200, 0.5);
    display: flex; align-items: center;
    width: 100vw; height: 11vw; padding-left: 5vw;
    color: black; font-size: 4vw;
}
.store_pickup_item_details_modifier_option_row {
    display: flex; align-items: center; 
    width: 100vw; height: 10vw;  padding-left: 5vw;
    border-bottom-style: solid; border-bottom-width: 0.2vw; border-bottom-color: rgba(0, 0, 0, 0.1);
}
.store_pickup_item_details_modifier_option_row:active {
    opacity: 0.5;
}
/* Modifier 选项*/
.store_pickup_item_details_modifier_option_text {
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw;
    margin-left: 5vw;
}
.store_pickup_item_details_require {
    margin-left: auto; margin-right: 5vw;
    color: rgba(0, 0, 0, 0.5);   font-size: 4vw;
}
.store_pickup_item_details_modifier_option_price {
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw; 
    margin-right: 5vw; margin-left: auto;
}
.store_pickup_item_details_modifier_option_price_select {
    color: darkcyan; font-size: 3.5vw; font-weight: bold;
    margin-right: 5vw; margin-left: auto;
}
.store_pickup_item_details_modifier_option_radio {
    width: 4.5vw; height: 4.5vw;
    border-radius: 50vw;
    border-style: solid; border-color: lightgray; border-width: 0.5vw;
}
.store_pickup_item_details_modifier_option_radio_select {
    width: 4.5vw; height: 4.5vw;
    border-radius: 50vw;
    border-style: solid; border-color: darkcyan; border-width: 1.5vw;
}
.store_pickup_item_details_modifier_option_checkbox {
     width: 4.5vw; height: 4.5vw;
    border-radius: 0.5vw;
    border-style: solid; border-color: lightgray; border-width: 0.5vw;
}
.store_pickup_item_details_modifier_option_checkbox_select {
    display: flex; align-items: center; justify-content: center;
    background: darkcyan;
    width: 4.5vw; height: 4.5vw;
    border-radius: 0.5vw;
    color: white; font-weight: bold; font-size: 3vw;
}
.store_pickup_item_details_modifier_comments {
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: auto; min-height: 6.5vw; padding: 0vw;
    border-bottom-style: solid; border-bottom-width: 0.2vw; border-bottom-color: rgba(0, 0, 0, 0.1);
}
.store_pickup_item_details_num_select_row {
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 30vw;
}
.store_pickup_item_details_num_text {
    display: flex; align-items: center; justify-content: center;
    width: 15vw; height: 8vw;
    color: darkcyan; font-size: 5vw; font-weight: bold;
}
.store_pickup_item_details_add_button {
    background: darkcyan;
    display: flex; align-items: center; justify-content: center;
    width: 8vw; height: 8vw;
    color: white; font-size: 4.5vw; font-weight: bold;
    border-radius: 1vw;
}

.store_pickup_item_details_add_button:active {
    opacity: 0.5;
}
.store_pickup_item_details_bottom_margin {
    background: white;
    width: 100vw; height: 30vw;
}
.store_pickup_item_details_bottom{
    background: rgba(211, 211, 211, 0.1);
    width: 100vw; height: 15vw;
    display: flex; align-items: center;  justify-content: center;
    border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1); border-top-width: 0.2vw;
    position: fixed;  bottom: 0; left: 0; z-index:300;
}
.store_pickup_item_details_total_price {
    background: white;
    width: 30vw; height: 15vw;
    display: flex; align-items: center;  justify-content: center;
    color: black; font-weight: bold; font-size: 5vw;
    border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1); border-top-width: 0.2vw;
}
.store_pickup_item_details_add_to_cart_button {
    background: darkcyan;
    width: 70vw; height: 15vw;
    display: flex; align-items: center;  justify-content: center;
    color: white; font-size: 5vw; font-weight: bold;
}
.store_pickup_item_details_add_to_cart_button_disable {
    background: grey; color: black;
}
.store_pickup_item_details_add_to_cart_button:active {
    opacity: 0.5;
}
.store_pickup_cart_item_title {
    display: flex; align-items: center; 
    width: 95vw; height: 10vw; margin-left: 2.5vw;
}
.store_pickup_cart_item_num {
    display: flex; align-items: center;  justify-content: center;
    color:rgba(0, 0, 0, 0.5); font-size: 3.5vw; font-weight: bold;
    width: 6vw; height: 6vw;
    border-radius: 1vw; margin-right: 2.5vw; padding-top: 0.5vw;
    border-style: solid; border-color: rgba(0, 0, 0, 0.5); border-width: 0.5vw;
}
.store_pickup_cart_item_name {
    color: rgba(0, 0, 0, 0.75); font-size: 4.5vw; font-weight: bold;
    width: auto; max-width: 57.5vw;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.store_pickup_cart_item_price {
    color: darkcyan; font-size: 4.5vw; font-weight: bold;
    margin-left: auto; margin-right: 0;
}
.store_pickup_cart_item_remove_button {
    display: flex; align-items: center; justify-content: center;
    color:rgba(0, 0, 0, 0.5); font-size: 4.5vw; font-weight: bold;
    width: 6vw; height: 6vw; margin-left: 2.5vw; margin-right: 2.5vw;
}
.store_pickup_cart_item_remove_button:active {
    opacity: 0.5;
}
.store_pickup_cart_item_options_area {
    width: 95vw; height: auto; margin-left: 2.5vw;
}
.store_pickup_cart_item_option_row {
    display: flex; align-items: center; padding-left: 9vw;
    color: rgba(0, 0, 0, 0.75); font-size: 3.75vw; height: auto; min-height: 6vw;
}
.store_pickup_cart_item_area{
    width: 95vw; height: auto; margin-left: 2.5vw; margin-top: 2vw;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.2); border-bottom-width: 0.15vw;
}
.store_pickup_cart_add_more_item{
    display: flex; align-items: center; 
    width: 95vw; height: 12vw; margin: 2.5vw; padding-left: 2.5vw; padding-bottom: 2.5vw;
    color: darkcyan; font-weight: bold;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.2); border-bottom-width: 0.15vw;
}
.store_pickup_cart_add_more_item:active {
    opacity: 0.5;
}
.store_pickup_cart_price_row {
    display: flex; align-items: center;
    width: 80vw; height: 12vw; margin-left: 10vw;
    color: black; font-size: 4.25vw;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.2); border-bottom-width: 0.15vw;
}
.store_pickup_cart_total_price_row {
    width: 95vw; margin-left: 2.5vw; padding-left: 7.5vw; padding-right: 7.5vw; padding-top: 1vw;
}
.store_pickup_cart_payment_button {
    background: darkcyan;
    color: white; font-size: 4.75vw; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 12vw;
    position: fixed; left: 0; bottom: 0; z-index: 300;
}
.store_pickup_cart_payment_button:active{
    opacity: 0.5;
}
.store_pickup_cart_payment_total_price_bottom {
    width: auto; height: 12vw;
    margin-right: 5vw;
    color: white; font-size: 4.75vw; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
    position: fixed; right: 0; bottom: 0; z-index: 300;
}
.store_pickup_cart_payment_method {
    display: flex; align-items: center; 
    width: 95vw; height: 16vw;
    margin-left: 2.5vw; padding-left: 3.5vw; padding-top: 1vw;
    color: black; font-size: 4vw; font-weight: bold;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.2); border-bottom-width: 0.15vw;
}
.store_pickup_cart_payment_method:active {
    opacity: 0.5;
}
.store_pickup_cart_contact {
    display: flex; align-items: center; 
    width: 95vw; height: 16vw;
    margin-left: 2.5vw; padding-left: 3.5vw; padding-top: 1vw;
    color: black; font-size: 4vw; font-weight: bold;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.2); border-bottom-width: 0.15vw;
}
.store_pickup_cart_contact:active {
    opacity: 0.8;
}
.store_pickup_cart_item_comments {
    width: 100%; height: 7.5vw;
    display: flex; align-items: center; 
    padding-left: 11.5vw;  margin-bottom: 1vw;
    color: rgba(0, 0, 0, 0.5); font-weight: 4.25vw; 
}
.store_pickup_cart_item_bottom_margin {
    margin-bottom: 2.5vw;
}
.store_pickup_cart_login_button {
    background: darkcyan;
    display: flex; align-items: center; justify-content: center;
    width: 20vw; height: 8vw;
    color: white; font-weight: bold; font-size: 3.5vw;
    margin-left: auto; margin-right: auto;
    border-radius: 1vw;
}
.store_pickup_cart_login_button:active {
    opacity: 0.5;
}
.store_pickup_cart_contact_info_row {
    display: flex; align-items: center; justify-content: center;
}
.store_pickup_cart_contact_title {
    font-size: 3.75vw; font-weight: bold;
    width:auto; min-width: 16vw;
}
.store_pickup_cart_contact_input {
    width: 50vw;
}
.store_pickup_login_reminder {
    font-size: 4vw; font-weight: bold; color: darkcyan;
    display: flex; align-items: center; justify-content: center;
    margin-top: 2.5vw;
}
.store_pickup_bottom_space {
    background: white;
    width: 100vw; height:20vw;
}
.store_pickup_contact_info_contents {
    width: 45vw; min-width: 45vw; height: auto;
    margin-left: 2vw; margin-right: 0;
    color: grey; font-size:4vw; font-weight: bold;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.store_pickup_cart_dlg_change_button {
    font-weight: bold; color: grey; font-size: 3.75vw;
    margin-left: auto; margin-right: 7.5vw;
}
.store_pickup_contact_info_tips {
    width: 100%; height: auto; margin-top: 2.5vw;
    color: lightcoral; font-weight: bold; font-size: 3.75vw;
}
.store_pickup_item_info_block {
    /* background: lightsalmon; */
    width: 100%;
    display: flex; align-items: center;
}
.store_pickup_item_sec_row {
    /* background: lightblue; */
    width: 78.5vw; height: auto;
    margin-left:auto; margin-right: 0vw;
    display: flex; align-items: center;
}
.store_pickup_item_sales {
    min-height: 6vw; height: 6vw;
    margin-left: 2vw; margin-top: 0vw; margin-bottom: 2vw;
    color: darkcyan; font-weight: bold; font-size: 4vw;
}
.store_pickup_no_search_result {
    background: white;
    width: 100%; height: auto; padding-top: 7.5vh;
    font-size: 3.75vw; font-weight: bold; color: grey;
    display: flex; align-items: center; justify-content: center;
}
.store_pickup_search_button {
    /* background: lightsalmon; */
    color: white; font-weight: bold; font-size: 4.5vw;
    width: 7.5vw; height:7.5vw; min-width: 7.5vw; min-height: 7.5vw;
    margin-left: 2.5vw; margin-top:0.1vw; padding-bottom: 0.25vw;
    display: flex; align-items: center; justify-content: center;
    border-radius: 6px; border-width: 2px; border-style: solid; border-color: white;
}
.store_pickup_search_button:active {
    opacity: 0.75;
}

.shipping_address_row {
    /* background: lightblue; */
    width: 100%;margin-bottom: 5vw;
    display: flex; align-items: center; 
}
.shipping_address_row_title {
    width: 18vw; min-width: 18vw;
    /* margin-right: 1vw; */
}
.shipping_address_row_input {
    width: 65vw;  display: flex; align-items: center; 
}