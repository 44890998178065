.manage_page {
    /* background: #000f29; */
    background: rgb(70, 70, 70);
    background: rgba(0, 0, 0, 0.115);
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1; overflow: hidden;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: scroll;
    overflow:hidden;
}
.manage_empty_loading {
    /* background: lightseagreen; */
    color: rgba(0, 0, 0, 0.25); font-weight: bold; font-size: 16px;
    width: 100%; height: 100%;
    padding: 0; padding-top: 35vh;
}
.manage_empty_loading_m {
    color: white; font-weight: bold; font-size: 16px;
    padding-top: 50vw;
    width: 100vw; height: calc(100vh - 12vw);
    position: fixed; top: 12vw; left: 0;
}
.manage_top_bar {
    background: #000f29;
    display: flex; align-items: center;
    position: fixed; top: 0; left: 0; z-index: 100;
    width: 100vw; height: 54px;
}
.platform_logo {
    background: #000f29;
    color: lightcyan; font-weight: bold; 
    display: flex; align-items: center; justify-content: center;
    width: auto; height: 50px; min-width: 100px;
    margin-left: 15px; margin-right: auto;
}
.manage_top_bar_menu {
    width: auto; height: 50px; min-height:50px;
}
.manage_login_form {
    display: flex; align-items: center;
    width: auto; height: 50px;
    margin-right: 0; margin-left: auto;
    padding-left: 20px;
}
.manage_side_bar {
    width: 100px; height: 100vh;
    position: fixed; left: 0; top: 50px;
    overflow: hidden;
}
.manage_contents {
    background:rgb(225, 225, 225);
    width: calc(100vw - 100px); height: calc(100vh - 50px);
    position: fixed; top: 50px; left: 100px;
    margin: 0; padding: 0; padding-top: 50px; padding-bottom: 25px; overflow-y: auto;
}
.manage_contents_less_top {
    padding-top: 25px;
}
.manage_contents_title {
    position: fixed; top: 50px; left: 100px; z-index: 50;
    background-color: darkgrey;
    display:flex; align-items: center;
    width:calc(100vw - 100px); height:50px;
    padding: 20px;
}
.manage_contents_title_margin {
    margin-left: 10px;
}
.manage_contents_title_margin_right {
    margin-right: 10px;
}
.manage_table_contents {
    background-color: rgb(225, 225, 225);
    padding: 10px; padding-bottom: 0;
}
.manage_title_button_right_side {
    display: flex; align-items: center;
    margin-left: auto; margin-right: 0;
}
.must_input_star {
    color: brown; text-align: center;
    width: 7.5px; height: auto;
}
.manage_info_label {
    text-align:left;display:flex;align-items: center;
    width: 85px; max-width: 55px;
}
.manage_info_label_top {
    text-align:left;display:flex;align-items: top;
    width: 85px; max-width: 55px;
    margin-top: 5px; margin-bottom: auto;
}
.manage_info_space {
    max-width: 100px;
}
.manage_model_box {
    background: #f9f9f9;
    margin: 0; margin-bottom:10px;
    padding: 0; padding-top: 3px; padding-bottom: 3px;
    border-style: solid; border-color: lightgray; border-width: 1px; border-radius: 3px;
}
.manage_modal_row {
    display:flex; align-items: center;
    margin: 10px; padding: 0;
}
.manage_modal_col{
	display:flex; align-items: center;
    margin: 0; margin-bottom: 5px; padding: 0;
    width: auto;
}
.manage_modal_item {
    display: flex; align-items: center; 
    margin: 10px; margin-top: 0px; margin-bottom: 10px;
}
.manage_modal_input {
    width: 150px; margin:0; margin-right: 30px;
}
.manage_modal_input_area {
    width: 620px; margin:0; margin-right: 30px;
}
.manage_modal_time_input {
    width: 120px; outline: none; border-radius: 3px;
}
.manage_mid_input {
    width: 385px;
}
.manage_long_input {
    width: 620px;
}
.manage_modal_dropdown {
    text-align: center; width: 150px;
    padding: 0; padding-left: 7px;
    margin:0; margin-right: 30px;
}
.manage_modal_textarea {
    width: 620px;
}
.manage_tree_structure {
  margin: 0; margin-top: 20px; margin-left: 20px;
}
.manage_tree_node_h {
    display: flex; align-items: center;
}
.manage_tree_icon {
    width: 15px; height: 15px;
    margin: 0; margin-top: 4px;
}
.category_page_title_button {
  display: flex; margin-left:0;
}
.manage_title_filter_area {
display:flex; align-items: center;
}
.manage_stretch_right {
    display:flex; align-items: center;
    margin: 0; margin-right: 15px;
}
.manage_stretch_left {
    display:flex; align-items: center;
    margin: 0; margin-right: 15px;
}
.manage_stretch_right_tiny {
    margin: 0; margin-right: 5px;
}
.welcome_back_title {
    color: white; font-weight: bold;
    min-width: 200px;
}
.welcome_back_title_m {
    color: white; font-weight: bold; font-size: 3.5vw; margin-left: 2.5vw;
}
.logout_btn {
    margin-left: 15px; margin-right: 15px;
}
.logout_btn_m {
    display:flex; align-items: center; justify-content: center;
    background: darkcyan;
    width: 15vw; height: 6.5vw;
    margin-left: auto; margin-right: 2.5vw;
    color: white; font-weight: bold; font-size: 3vw;
    border: none;
}
.search_manage_contents{
    background:rgb(225, 225, 225);
    width: calc(100vw - 100px); height: calc(100vh - 50px);
    position: fixed; top: 50px; left: 100px;
    margin: 0; padding: 0; padding-top: 20px; overflow-y: auto;
}