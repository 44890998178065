.storage_manage_logo {
    background: #000f29;
    color: lightcyan; font-weight: bold; 
    display: flex; align-items: center; justify-content: center;
    width: 150px; height: 50px;
}
.storage_backend_title {
    display: flex; align-items: center; justify-content: center;
    color: lightcyan; font-weight: bold;
    width: auto; height: 50px; padding-left: 25px;
    border-left-style: solid; border-left-color: rgba(224, 255, 255, 0.5); border-left-width: 0.5px;
}
.storage_manage_side_bar {
    width: 150px; height: 100vh;
    position: fixed; left: 0; top: 50px;
    overflow: hidden;
}
.storage_contents_title {
    position: fixed; top: 52px; left: 150px; z-index: 100;
    background-color: grey;
    display:flex; align-items: center;
    width:calc(100vw - 150px); height:50px;
    padding: 20px;
}
.storage_contents_title_text {
    color: white; font-weight: bold;
    margin-left: 5px;
}
.storage_contents_area {
    background:rgb(225, 225, 225);
    width: calc(100vw - 150px); height: calc(100vh - 50px);
    position: fixed; top: 52px; left: 150px;
    margin: 0; padding: 0; padding-top: 50px; padding-bottom: 25px; overflow-y: auto;
}
.storage_edit_info_required {
    color: lightcoral; font-weight: bold;
    min-width: 10px;
}
.storage_edit_info_title {
    font-weight: bold; min-width: 67.5px; max-width:67.5px;
    display:flex; align-items: center;
}
.storage_edit_info_title_long {
    font-weight: bold; min-width: 85px; 
    margin-left: 30px;
    display:flex; align-items: center;
}
.storage_edit_info_title_short {
    font-weight: bold; min-width: 50px; 
    margin-left: 7.5px;
}
.storage_edit_info_title_first_col {
    margin-left: 5px;
}
.storage_edit_info_input_long {
    width: 752.5px;
}
.storage_edit_info_input {
    width: 187.5px;min-width: 187.5px;max-width: 187.5px; margin-right: 20px;
}
.storage_edit_info_input_print {
    width: 180px;min-width: 180px;max-width: 180px; margin-right: 20px;
}
.storage_edit_info_input_mid {
    width: 145px; min-width: 145px; margin-right: 24.5px;
}
.storage_edit_info_input_mid2 {
    width: 462.5px;
}
.storage_edit_info_input_mid3 {
    width: 300px; margin-right: 20px;
}
.storage_edit_info_input_short {
    width: 115px;
}
.storage_edit_info_option {
    width: auto;
}
.storage_info_edit_block {
    background: rgba(32, 178, 171, 0.02);
    width: 100%; height: auto;
    padding: 15px; padding-top:20px; padding-bottom: 10px;
    margin-bottom: 15px;
    border-style: solid; border-width: 1px; border-color: rgba(128, 128, 128, 0.25); border-radius: 5px;
}
.storage_login_form {
    background: rgba(135, 207, 235, 0.25);
    background: rgba(255, 255, 255, 0.5);
    width: 350px; height: auto;
    padding: 50px;
    margin: auto; margin-top: 30vh;
    border-radius: 5px;
    /* border-style: solid; border-width: 1px; border-color: rgba(0, 0, 0, 0.1); */
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.2);
}
.storage_manager_register_button{
    color: #1890ff; font-weight: bold;
    text-decoration: underline;
    margin-left: 0; margin-right: auto;
}
.storage_manager_register_button:hover{
    cursor: pointer;
}
.storage_manager_register_button:active {
    opacity: 0.5;
}
.storage_login_row{
    display: flex; align-items: center;
}
.storage_edit_info_block {
    background: rgba(0, 139, 139, 0.01);
    width: 96.5%; height: auto; 
    /* max-height: 50vh; */
    margin-left: auto; margin-right:auto;
    padding: 17.5px; padding-top: 15px;
    font-size: 14.5px;
    border-radius: 5px; border-style: solid; border-width: 1px; border-color: rgba(0, 0, 0, 0.075);
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow: hidden; overflow-y: auto;
}
.storage_edit_info_sub_block {
    background: rgba(0, 139, 139, 0.025);
    width: 100%; height: auto;
    margin-left: auto; margin-right:auto;
    padding: 10px;
    border-radius: 0px; border-style: solid; border-width: 1px; border-color: rgba(0, 0, 0, 0.075);
}
.storage_edit_info_sub_block_dark {
    background: rgba(0, 139, 139, 0.075);
}
.storage_edit_info_black_title {
    padding-bottom: 10px;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.2);border-bottom-width: 1px; 
}
.storage_edit_info_add_button {
    background: darkcyan;
    color: white; font-size: 14px;
    width: 100px; max-width: 100px;
    display:flex; align-items: center; justify-content: center;
    padding: 10px; padding-top: 5px; padding-bottom:5px;
    margin-left: auto; margin-right: 0px;
    width: auto; height: auto;
    border-radius: 2.5px;
}
.storage_edit_info_add_button_disable {
    background: grey;
    width: 100px; max-width: 100px;
    color: white; font-size: 14px;
    display:flex; align-items: center; justify-content: center;
    padding: 10px; padding-top: 5px; padding-bottom:5px;
    margin-left: auto; margin-right: 0px;
    width: auto; height: auto;
    border-radius: 2.5px;
}
.storage_edit_info_add_button:hover{
    cursor: pointer;
}
.storage_edit_info_add_button:active {
    opacity: 0.5;
}
.storage_edit_box_info_title {
    min-width: 50px;
}
.storage_edit_box_info_input {
    min-width: 150px; max-width: 150px;
    margin-right: 20px;
}
.storage_edit_box_info_input_short {
    min-width: 70px; max-width: 70px;
    margin-right: 15px;
}
.storage_edit_box_info_input_short2 {
    min-width: 75px; max-width: 75px;
    margin-right: 10px;
}
.storage_edit_box_info_input_mid {
    min-width: 125px; max-width: 125px;
    margin-right: 20px;
}
.storage_edit_box_info_input_mid2 {
    min-width: 200px; max-width: 200px;
    margin-right: 20px;
}
.storage_edit_box_info_input_long {
    min-width: 345px; max-width: 345px;
    margin-right: 20px;
}
.storage_edit_info_delete_button {
    display:flex; align-items: center; justify-content: center;
    width: 25px; height: 30px;
}
.storage_edit_info_delete_button:hover{
    cursor: pointer;
}
.storage_edit_info_delete_button:active {
    opacity: 0.5;
}
.storage_edit_info_delete_box_button {
    background: lightcoral;
    color: black; font-size: 14px;
    display:flex; align-items: center; justify-content: center;
    padding: 10px; padding-top: 5px; padding-bottom:5px;
    margin-left: 10px;
    width: auto; height: auto;
    border-radius: 2.5px;
}
.storage_edit_info_delete_box_button:hover{
    cursor: pointer;
}
.storage_edit_info_delete_box_button:active {
    opacity: 0.5;
}
.storage_sku_input_search {
    display:flex; align-items: center; justify-content: center;
    width: 15px; height: 20px;
    margin: 0; padding: 0;
}
.storage_sku_input_search:active {
    opacity: 0.5;
}
.storage_sku_input_search:hover {
    cursor: pointer;
}
.storage_scan_tips {
    width: 100%; height: 75%;
    color: rgba(0, 0, 0, 0.6); font-weight: normal; font-size: 20px;
    display:flex; align-items: center; justify-content: center;
    margin-left: auto; margin-right: auto;
}
.storage_sku_check_row {
    background: rgba(0, 139, 139, 0.07); padding:2px; border-radius: 2px;
}
.storage_sku_list_area {
    height: auto; max-height: 50vh;
    overflow: hidden; overflow-y: auto;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.storage_sku_list_row{
    margin: 10px; margin-top: 5px; margin-bottom: 15px;
    display:flex; align-items: center;
    color: darkcyan; 
    border-bottom-style: solid; border-bottom-width: 0.5px; border-bottom-color: lightgray;
}
.storage_sku_list_row:hover {
    cursor: pointer;
}
.storage_sku_list_row:active {
    opacity: 0.5;
}
.storage_print_button {
    width: 35px; height: 35px;
    min-width: 35px; min-height: 35px;
    max-width: 35px; max-height: 35px;
    display:flex; align-items: center; justify-content: center;
}
.sku_row_bottom_line {
    padding-bottom: 10px;
    border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(0, 0, 0, 0.1);
}