.user_center_page {
    background: rgba(120, 85, 85, 0.75);
    background: #c9a063;
    background: #f5ecd5;
    background: #f7f8f8;
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden;
}
.user_center_page_indie {
    /* background: rgba(0, 139, 139, 0.05); */
    /* background: rgba(0, 0, 0, 0.05); */
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden;
}
.user_page_spin {
    background: white; 
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 100vh;
}
/* 用户中心 */
/* Title */
.user_center_title {
    display: flex; align-items: center;
    width: 100vw; height: 30vw; margin-top: 14vw;
}
.user_center_title_indie {
    display: flex; align-items: center; justify-content: center;
    width: 90vw; height: auto; padding-top: 12.5vw; padding-bottom: 2.5vw;margin-left: 5vw;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-width: 0.25vw;
}
.user_center_avatar {
    width: 18vw; height: 18vw; margin: 8vw; margin-right: 3vw;
    background-size: cover; background-repeat: no-repeat;
    border-radius: 50px; border-style: solid; border-width: 1px; border-color: lightgrey;
    box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3);
}
.user_center_avatar_indie {
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    width: 15vw; height: 15vw; margin-right: 5vw;
    border-radius: 50px; border-style: solid; border-width: 0.75vw; border-color: white;
    box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3);
}
.user_center_name {
    display: flex; align-items: center;
    font-weight: bold; text-align: left; font-size: 5vw; 
    margin-bottom: 1vw; margin-top: 1vw;
}
.user_center_name_indie {
    display: flex; align-items: center; margin-bottom: 2vw;
    color:black; font-weight: bold; font-size: 4.5vw; text-align: left;
}
.user_center_user_info_btn {
    color: lightsalmon; font-size: 3.5vw; font-weight: bold;
    padding: 1vw;
}
.user_center_user_info_btn:focus, .user_center_user_info_btn:active {
    color: black; font-weight: bold;
    border-bottom-width: 2px; opacity: 1;
}
.user_center_setting_area {
    display: flex; align-items: center;
    width: auto; height:100%;
    margin-left: auto; margin-right: 3.5vw;
}
.user_center_setting_btn {
    width: 7.5vw; height: 7.5vw;
    margin: 0; margin-left: 2vw; margin-top: 8.5vw; opacity: 0.8;
    background-size: cover; background-repeat: no-repeat;
}
.user_center_setting_btn:focus, .user_center_setting_btn:active {
    background-color: lightsalmon;
    border-radius: 5px;
}
.user_collects_area {
    display: flex; align-items: center; justify-content: center;
    height: 20vw;
    /* border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-width: 0.25vw; */
}
.user_collects_item {
    width: 24vw; height: 21.5vw;
    padding: 0; padding-top: auto; padding-bottom: auto; opacity: 0.75;
}
.user_collects_item_select {
    background-color: lightsalmon; opacity: 1;
    width: 24vw; height: 21.5vw;
    border-radius: 2.5px; box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.25);
}
.user_collects_num {
    color:black; font-size: 4.5vw;
    display: flex; align-items: center; justify-content: center;
    width: 24vw; height: 11vw;
}
.user_collects_icon {
    width: 5vw; height: 5vw;
    margin: 0; margin-right: 2vw;
    background-size: cover; background-repeat: no-repeat;
}
.user_collects_text {
    display: flex; align-items: center; justify-content: center;
    color: black; font-size: 4.5vw; font-weight: bold; text-align: center;
    width: 24vw; height:11vw;
}
.user_center_divider {
    background-color: darkgray; opacity: 0.3;
}
.user_center_divider_v {
    width: 1px; height: 16vw;
    margin-top:1.5vw; margin-bottom: auto;
}
.user_center_list_area {
    width: 100vw; height: auto;
    margin: 0; padding: 0;
    overflow-x: hidden; overflow-y: scroll;
}
.user_center_list_area_top_space {
    background: #f7f8f8;
    height: calc(100vh - 50vw); 
    position: fixed; top: 50vw; left: 0vw;
    overflow-x: hidden; overflow-y: scroll;
}
.user_center_empty {
    width: 100vw; height: calc(100vh - 13vw - 32vw - 26vw);
    padding-top: 32vw;
}
.user_center_cut_price_empty {
    padding-top: 20vw;
}
.go_to_cut_price {
    background: #c9a063;
    width: 30vw; height: 10vw;
    padding-left: 1.5vw; margin-left: 35vw; margin-top: 10vw;
    font-size: 4.5vw; color: white; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
    border-radius: 1vw;
    /* border-style: solid; border-width: 0.2vw; border-color: lightgrey; */
}
.go_to_cut_price:active {
    opacity: 0.5;
}
/* 个人信息 */
.user_info_title_area {
    background: rgba(120, 85, 85, 0.75);
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center;
    position: fixed; top: 0; left: 0; z-index: 100;
    width: 100vw; height: 14vw;
}
.user_avatar_space {
    width: 100vw; height: 14vw;
}
.user_info_title {
    color: rgb(80, 80, 80);
    color: white; font-weight: bold; font-size: 4vw; text-align: center;
    margin: auto;
}
.user_center_back_btn {
    width: 6vw; height: 6vw;
    position: fixed; top: 3vw; left: 3vw;
    background-size: cover; background-repeat: no-repeat;
}
.user_center_back_btn:focus, .user_center_back_btn:active {
    opacity: 1;  width: 7vw; height: 7vw;
}
.user_info_avatar_area {
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 32vw; margin-top: 14vw;
}
.user_info_avatar_icon {
    width: 18vw; height: 18vw;
    background-size: cover; background-repeat: no-repeat;
    border-radius: 50px; border-style: solid; border-width: 1px; border-color: lightgrey;
    box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3);
}
.avatar_uploader {
    background-size: auto, auto; background-repeat: no-repeat;
    width: 18vw; height: 18vw; 
} 
.avator_uploader_shadow {
    border-radius: 50px; border-style: solid; border-width: 1px; border-color: lightgrey;
    box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3);
}
.user_info_avatar_change_btn {
    background-color: white;
    position: fixed; top: 35.5vw; left: 46.75vw; z-index: 100;
    background-size: cover; background-repeat: no-repeat;
    width: 6.5vw; height: 6.5vw; 
    border-radius: 10px; border-width: 2px; border-color: white; border-style: solid;
}
.user_info_item {
    display: flex; align-items: center;
    width: 100vw; height: 12vw; margin: 0; padding: 0;
    border-top-width: 1px; border-top-color: rgba(200, 200, 200, 0.3); border-top-style: solid;
    border-bottom-width: 1px; border-bottom-color: rgba(200, 200, 200, 0.3); border-bottom-style: solid;
}
.user_info_change_line {
    display: flex; align-items: center;
    width: 100vw; height: 12vw; margin: 0; padding: 0;
}
.user_info_item_title {
    width: 17.5vw; display: flex; align-items: center;
    margin-left: 4.5vw; margin-top: 1vw;
    color: deepskyblue;
    color: rgba(120, 85, 85, 0.65);
    font-size: 4vw; text-align: left;
}
.user_info_change_title {
    width: auto; display: flex; align-items: center; margin-right: 3vw;
    color: deepskyblue;
    color: rgba(120, 85, 85, 0.65); font-size: 4vw; text-align: left; font-weight: bold;
}
.user_info_change_pwd {
    min-width: 20vw; 
}
.user_password_input_margin {
    margin-bottom: 0vw;
}
.user_info_item_content {
    width: 60vw; opacity: 0.8;
    color: black; font-size: 4vw; 
}
.user_info_address {
    overflow: hidden; white-space: nowrap;  text-overflow: ellipsis;  
}
.user_info_item_btn {
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 3.75vw; opacity: 0.75;
    background: lightsalmon;
    background: rgba(120, 85, 85, 0.75);
    background: rgba(120, 85, 85, 0.65);
    min-width: 16vw; max-width: 16vw; height: 8.5vw;
    margin: 0; margin-right: 2vw; margin-left: 2vw;
    border-radius: 1vw; border-width: 2px; border-color: white; border-style: solid;
}
.user_info_item_btn:focus, .user_info_item_btn:active {
    opacity: 1; 
}
.user_info_bottom_area {
    position: fixed; top: 0; right: 0; opacity: 0.5; z-index: 101;
    display: flex; align-items: center; justify-content: center;
    width: 20vw; height: 14vw;
    color: white; font-size: 4vw;
}
.user_info_bottom_area:focus, .user_info_bottom_area:active {
    opacity: 1; color: black;
}
.user_center_order_details_title {
    display: flex; align-items: center;
    font-size: 3.75vw;
}
.user_center_order_details_title_left {
    width: auto; min-width: 35vw; font-size: 4vw;
    margin-left: 0; margin-right: 2vw;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.user_center_order_details_title_right {
    /* background: lightseagreen; */
    margin-left: auto; margin-right: 0;
    font-size: 4vw; text-align: right;
    display: flex; align-items: center; justify-content: right;
    width: auto; min-width:15vw; height: 8vw;
    padding-right: 0vw; padding-left: auto; padding-top: 1vw; padding-bottom: 1vw;
}
.user_center_order_details_title_center {
    margin-left: auto; margin-right: 2vw;
    font-size: 4vw; text-align: left;
    width: auto; min-width: 20vw; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.user_center_order_details_area {
    color: rgba(0, 0, 0, 0.75); font-size: 3.75vw;
    width: 100vw; height: auto;
    margin:0; padding:0; margin-bottom: 0; margin-left: 6vw;
}
.user_center_order_details_text{
    width: 80vw;
    display: flex; align-items: center;
    margin-bottom: 1.5vw;
}
.user_center_order_details_cancel_button {
    background: lightgray;
    width: auto; min-width: 20vw; max-width: 30vw; height: 8vw;
    font-weight: bold; font-size: 3.5vw;
    margin-left: 0; margin-top: 2.5vw;
    display: flex; align-items: center; justify-content: center;
    border-radius: 1vw;
}
.user_center_order_details_cancel_button:active {
    opacity: 0.5;
}
.user_center_order_details_comments {
    background: rgba(240, 128, 128, 0.25);
    display: flex; align-items: center; justify-content: center;
    padding: 1vw;
}
.user_center_order_details_address {
    width: 70vw;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.user_center_loading_area {
    width: 100vw; height:100%;
    display: flex; align-items: center; justify-content: center;
    margin-top: 20vw;
}
.user_center_info_title_row {
    display: flex; align-items: center;
    width: auto; height: 10vw;
}
.user_center_info_title_row_indie {
    display: flex; align-items: center;
    width: auto; height: auto;
}

/* 团购用户页 */
.user_title {
    background: rgba(120, 85, 85, 0.75);
    /* background: #c9a063; */
    width: 100vw; height: 50vw;
    padding-left: auto; padding-right: auto; padding-top: 15vw;
    position: fixed; top: 0; left: 0; z-index: 100;
    border-bottom-style: solid; border-bottom-color: white; border-bottom-width: 0.25vw;
}
.user_avatar {
    background: rgba(0, 0, 0, 0.5);
    display: flex; align-items: center; justify-content: center;
    font-size: 7.5vw; color: white; font-weight: bold;
    width: 11vw; height: 11vw;
    margin-left: auto; margin-right: auto;
    border-radius: 10vw;
}
.user_name {
    width: 100vw; height: auto;
    color: white; font-size: 5vw; font-weight: bold; text-align: center;
    margin-top: 1vw;
}
.user_order {
    width: 100vw; height: auto;
    color: white; font-size: 4.5vw; text-align: left;
    margin-top: 3vw; padding-left: 4.5vw; padding-top: 2.5vw;
    border-top-style: solid; border-top-color: white; border-top-width: 0.25vw;
}
.user_setting{
     display: flex; align-items: center; justify-content: center;
     position: fixed; top: 3vw; right: 14vw; z-index: 200;
     font-size: 6vw; color: white; opacity: 0.75;
     width: 8vw; height: 8vw;
}
.user_setting:active {
    opacity: 1;
}
.user_card{
    display: flex; align-items: center; justify-content: center;
    position: fixed; top: 3vw; right: 3vw; z-index: 200;
    font-size: 6vw; color: white; opacity: 0.75;
    width: 8vw; height: 8vw;
}
.user_card:active {
    opacity: 1;
}
.user_order_list {
    background: #f5ecd5;
    background: #f7f8f8;
    width: 100vw; height: auto; min-height: calc(100vh - 50vw);
    max-height: calc(100vh - 20vw);
    margin: 0vw; padding-top: 2.5vw;
    overflow-x: hidden; overflow-y: scroll;
}
.user_center_share_button {
    width: 7.5vw; height: 7.5vw;
    min-width: 7.5vw; min-height: 7.5vw;
    color: white; font-size:4vw; font-weight: bold;
    margin-left: auto;
    margin-right: 16vw;
    border-radius: 5vw;
    border-style: solid; border-color: white; border-width: 0.1vw;
    display: flex; align-items: center; justify-content: center;
}
.user_center_share_button:active {
    opacity: 0.5;
}
.transit_user_options_button {
    background: rgba(0, 139, 139, 0.1);
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.5); font-size: 4.5vw; font-weight: bold; text-align: center;
    width: 50vw; height:12vw;
}
.transit_user_options_button_select {
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 4.5vw; font-weight: bold; text-align: center;
    width: 50vw; height:12vw;
}
.user_center_express_order_input_title {
    width: 100vw; height: 11vw;
    display: flex; align-items: center;
    padding-left: 5vw;
    color: black; font-weight: bold; font-size: 4vw;
}
.user_center_express_order_input_area {
    width: 100vw; height: auto; max-height: 100vh;
    padding: 4.5vw; padding-top: 0vw;
    overflow-x: hidden; overflow-y: scroll;
}
.user_center_express_order_submit_button {
    display: flex; align-items: center; justify-content: center;
    width: 22.5vw; height: 8vw;
    background: rgba(120, 85, 85, 0.65); color: white; font-weight: 3.5vw; font-weight: bold;
    border-radius: 1vw; margin-left: auto; margin-right: 0; margin-top: 5vw;
}
.user_center_order_reminder {
    background: lightsalmon;
    width: 2vw; height: 2vw;
    border-radius: 20vw;
    position: relative; left: -1.5vw;top: -2.5vw; 
}
.user_info_change_button_row {
    display: flex; align-items: center;
}
.user_info_change_confirm_button {
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 3.5vw; font-weight: bold;
    width: 18vw; height: 7.5vw; margin-right: 0vw; margin-top:5vw;
    border-radius: 1vw;
}
.user_info_change_confirm_button:active {
    opacity: 0.5;
}
.user_info_change_cancel_button {
    background: lightgray;
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 3.5vw; font-weight: bold;
    width: 18vw; height: 7.5vw; margin-left: auto; margin-right: 5vw; margin-top:5vw;
    border-radius: 1vw;
}
.user_info_change_cancel_button:active {
    opacity: 0.5;
}
.user_center_express_order_info_area {
    margin-bottom: 5vw; padding-bottom: 2vw;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-width: 0.25vw;
}
.user_center_express_order_info_row{
    display: flex; align-items: center;
    margin-bottom: 2vw;
}
.user_center_express_info_title {
    width: 20%;
    font-size: 4vw; text-align: right; font-weight: bold;
    margin-right: 2vw;
}
.user_center_express_info_contents{
    width: 80%
}
.user_center_transit_tracking_no{
    width: 100%; height: 10vw; height: auto;
    margin: 0; margin-top: 0.5vw; margin-bottom: 2.5vw;
    display: flex; align-items: center;
    font-size: 4vw; padding-left: 2vw;
}
.user_center_user_service_functions{
     display: flex; align-items: center; justify-content: center;
    width: 100%; height: auto;
}
.user_center_express_order_info_button {
    background: rgba(0, 139, 139, 0.1);
    display: flex; align-items: center; justify-content: center;
    width: 30vw; height: 10vw; margin:1.5vw; padding:1.5vw; padding-top: 2vw;
    color: rgba(0, 0, 0, 0.5); font-size: 4vw; font-weight: bold;
    border-radius: 1vw;
}
.user_center_express_order_info_button:active {
    opacity: 0.5;
}
.user_center_express_order_info_button_select {
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    width: 30vw; height: 10vw; margin:1vw; padding:1.5vw;  padding-top: 2vw;
    color: white; font-size: 4vw; font-weight: bold;
    border-radius: 1vw;
}
.user_center_service_function_icon {
    font-size: 4.5vw;  margin-right: 1vw; margin-bottom: 1vw;
}
.user_center_address_info_tips {
    background: rgba(255, 160, 122, 0.5);
    display: flex; align-items: center; justify-content: center;
    width: 95vw; height: 10vw; margin-left: 2.5vw; margin-top: 2.5vw;
    font-size: 3.25vw;
}
.user_center_address_info_row {
    background: rgba(0, 0, 0, 0.025);
    width: 95vw; height: 12vw; margin-left: 2.5vw; margin-top: 4vw;
    display: flex; align-items: center;
    border-radius: 1vw; border-style: solid; border-width: 0.5vw; border-color: rgba(120, 85, 85, 0.65);
}
.user_center_address_info_title {
    background: rgba(0, 0, 0, 0.05);
    width: 15vw; min-width: 15vw; height: 11vw; font-size: 3.75vw; font-weight: bold;
    padding: 1vw; padding-top: 1.5vw;
    display: flex; align-items: center; justify-content: center;
}
.user_center_pickup_reminds {
    width: 100%; height: 11vw; font-size: 3.75vw; font-weight: bold;
    padding: 1vw; padding-top: 1.5vw; color: lightcoral;
    display: flex; align-items: center; justify-content: center;
}
.user_center_address_info_contents {
    width: 65vw; min-width: 65vw; height: 11vw; font-size: 3.75vw;
    padding: 2.5vw; 
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.user_center_address_info_copy_button {
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    width: 11vw; height: 7.5vw; color: white; font-size: 3.5vw;
    margin-left: 1.5vw;
    border-radius: 1vw; 
}
.user_center_address_info_copy_all_button {
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    width: 24vw; height: 8vw; color: white; font-size: 3.5vw;
    margin-left: auto; margin-right: auto; margin-top: 5vw; border-radius: 1vw; 
}
.user_center_address_info_copy_button:active {
    opacity: 0.5;
}
.user_center_address_info_copy_all_button:active {
    opacity: 0.5;
}
.user_center_exress_order_submit_tip_area{
    /* background: lightsalmon; */
    width: 90vw; height: auto; margin-left: 5vw;
}
.user_center_exress_order_submit_tip_title {
    font-weight: bold; font-size: 4vw; margin-bottom: 3vw;
}
.user_center_exress_order_submit_tip_row {
    display: flex; align-items: center;
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw; margin-top: 1.5vw;
}
.user_center_address_manage_area {
    width: 95vw; height:auto; margin: 2.5vw; margin-top: 5vw;
}
.user_center_add_new_address_button {
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    width: 35vw; height: 10vw; margin-top: 5vw; margin-left:32.5vw;
    color: white; font-weight: bold; font-size: 4vw;
    border-radius: 1vw;
}
.user_center_add_new_address_button:active {
    opacity: 0.5;
}
.user_center_new_address_row {
    width: 100%; height: 10vw;
    display: flex; align-items: center; justify-content: center;
}
.user_center_new_address_title {
    width: 20%; height: 10vw;
    display: flex; align-items: center; justify-content: center;
}
.user_center_new_address_contents {
    width: 80%; height: 10vw;
    display: flex; align-items: center;
    /* justify-content: center; */
}

.user_center_contact_us_button {
    color: rgba(120, 85, 85, 0.65); font-size: 3.5vw; font-weight: bold; text-decoration: underline;
    margin-left: 1vw;
}
.user_center_contact_us_button:active {
    opacity: 0.5;
}
.user_center_contact_us_qrcode_title {
    width: 100%; height: 10vw;
    display: flex; align-items: center; justify-content: center;
    font-weight: bold; font-size: 4vw;
    margin-bottom: 2.5vw;
}
.user_center_contact_us_qrcode {
    background-color: lightcoral;
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1603945333/0-%E9%B2%B8%E5%9B%BEWhaleMap/CustomerServiceQRCode_ppgktf.jpg"); 
    width: 65vw; height: 65vw;
    margin-left: auto; margin-right: auto; margin-bottom: 2.5vw;
}
.user_center_contact_us_qrcode_text {
    width: 100%; height: 10vw;
    display: flex; align-items: center; justify-content: center;
    font-size: 3.5vw; margin-bottom: 10vw;
}
.user_center_order_submit_tip {
    background: rgba(255, 160, 122, 0.5);
    width: 100%; height: auto;
    margin-bottom:5vw; padding: 2vw;
    font-size: 3.75vw;
}
.user_center_modify_button{
    background: rgba(120, 85, 85, 0.65);
    width: 15vw; height: 7.5vw;
    display: flex; align-items: center; justify-content: center;
    border-radius: 0.5vw; margin-left: auto; margin-right: 0vw;
    color: white; font-weight: bold; font-size: 3.5vw;
}
.user_center_modify_button:active {
    opacity: 0.5;
}
.user_center_order_detials_store_qrcode {
    width: 60vw; height: 60vw;
    margin-left: 12.5vw; margin-right: auto;
}
.user_center_order_express_tracking{
    width: auto; min-width: 15vw; height: auto;
    margin-left: auto; margin-right: 0;
    color: rgba(120, 85, 85, 0.65); font-size: 3.5vw; font-weight: bold; text-decoration: underline;
}
.user_center_order_express_tracking:active {
    opacity: 0.5;
}
.user_center_dropdown_menu_button{
    background: rgba(120, 85, 85, 0.65);
    display: flex; align-items: center; justify-content: center;
    width: 27.5vw; min-width: 27.5vw; height: 10vw;
    padding-left: 2.5vw; padding-right: 0.5vw;
    margin-left: auto; margin-right: 2.5vw; margin-bottom: 2.5vw;
    color: white; font-weight: bold; font-size: 4.5vw;
    border-radius: 1vw;
}