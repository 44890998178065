.order_detail_title {
    width: 75px; min-width: 75px;
    font-weight: bold;
}
.order_detail_input {
    width: 180px; min-width: 180px; margin-right: 30px;
}
.order_search_input {
    width: 150px;
}
.order_detail_dropdown {
    /* background: lightsalmon; */
    text-align: center; width: 180px; min-width: 180px;
    padding: 0; padding-left: 7px;
    margin:0; margin-right: 30px;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.order_details_dropdown_button {
    width: 180px;  min-width: 180px; padding-left: 5px; padding-right: 5px;
    display: flex; align-items: center; justify-content: center;
}
.order_details_dropdown_text {
    width: 150px; 
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.manage_title_left_side {
    display: flex; align-items: center;
    margin:0;
}
.order_filter_title {
    color: white; font-weight: bold;
    width: auto; margin-left: 20px; margin-right: 15px;
}
.order_filter_dropdown {
    text-align: center; width: 120px; min-width: 120px; max-width: 120px;
    padding: 0; padding-left: 7px; padding-right: 7px; margin:0; margin-right: 5px;
}
.order_date_pickup {
    width: 180px; min-width: 180px;
    padding: 0; margin:0; margin-right: 30px;
}
.table_loading{
    display: flex; align-items: center; justify-content: center;
    width: calc(100vw - 200px); height: calc(100vh - 200px);
    margin-left: 20px;
}
.custom_file_link {
    color: darkcyan;
    width: 100px; min-width: 100px; max-width: 100px; height: 20px;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.custom_file_link:active {
    opacity: 0.5;
}    
.react-resizable {
    position: relative;
    background-clip: padding-box;
}
.react-resizable-handle {
    position: absolute;
    width: 10px; height: 100%;
    bottom: 0; right: -5px;
    cursor: col-resize; z-index: 1;
}
.order_refresh_table_layout {
    /* background-color: lightseagreen; */
    display: flex; align-items: center; justify-content: center;
    margin-left: 2vw;
}

.order_pid_copy_button {
    /* background: lightskyblue; */
    width: 25px; height: 25px;
    margin-left: 10px;
    display: flex; align-items: center; justify-content: center;
    /* border-radius: 5px; border-style: solid; border-color: grey; border-width: 1px; */
}
.order_pid_copy_button:active {
    opacity: 0.5;
    border-radius: 5px;
    border-style: solid; border-color: grey; border-width: 1px;
}
.store_order_input {
    width: 200px; margin-left: 15px; margin-right: 5px;
}
.manage_refresh_button {
    display: flex; align-items: center; justify-content: center;
    width: 30px; height: 30px;
}
.item_info_row_m {
    display: flex; align-items: center;
    height: 12vw;
}
.order_detail_title_m {
    display: flex; align-items: center;
    width: 21.5vw; height: 9.2vw;
    font-size: 3.5vw;
}
.order_detail_dropdown_m {
    text-align: center; width: 55vw; height: 9.2vw;
    padding: 0; margin:0; font-size: 3.5vw;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.order_date_pickup_m {
    width: 55vw; height: 9.2vw; padding: 0; margin:0; 
    font-size: 3.5vw; font-weight: bold; text-align: center;
}
.order_detail_edit_area {
    background: rgba(0, 0, 0, 0.05);
    width: 100%; height: 50vh; max-height: 50vh;
    overflow-x: hidden; overflow-y: scroll;
    padding: 5vw;
}
.order_detail_input_m {
    width: 55vw; height: 9.2vw;
    font-size: 3.5vw; 
}