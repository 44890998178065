.start_page {
    background-color: black; color: black;
    display: flex; align-items: center; justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow: hidden;
}
.start_page_empty {
    background-color: deepskyblue;
}
.start_page_logo {
    background-size: cover; background-repeat: no-repeat;
    width: 40vw; height: 40vw; margin-left: 6vw;
    /* border-radius: 100px; border-style: solid; border-color: white; border-width: 5px; */
}
.start_page_title {
    color: black; font-size: 12vw; font-weight: bold; text-align: center;
    position: relative; top: -20vw; opacity: 0.7;
}
.start_page_subtitle {
    color: black; font-size: 4.5vw; text-align: center;
    position: relative; bottom: -20vw; opacity: 0.5;
}
.countdown_area {
    display: flex; align-items: center; justify-content: center;
    background: lightslategray;
    width: 15vw; height: 7.5vw;
    border-radius: 8px; opacity:0.8;
    margin:0; padding: 0;
    position: fixed; right: 2vw; top: 2vw;
}
.countdown_area_hide {
    opacity: 0;
}
.countdown_title {
    color: white; font-size: 3.75vw;
    margin: 0; margin-top: 2vw; margin-right: 1vw;
}
.jump_btn_mask {
    background: lightcoral;
    width: 15vw; height: 7.5vw;
    position: fixed; right: 2vw; top: 2vw; z-index: 100;
    border-radius: 8px; opacity: 0.05;
}
.start_page_image {
    background-color: lightsalmon;
    display: flex; align-items: center; justify-content: center;
    width: auto; height: 100vh;
}