.store_list  {
    width: 100vw; height: auto; padding:0; margin-bottom: 5vw;
}
.store_list_title {
    color: black; font-weight: bold; font-size: 4.5vw; text-align: left;
    display: flex; align-items: center; opacity: 0.9;
    width: 100vw; height: 11vw;
    margin: 0; padding: 0; padding-left: 4vw; padding-top: 2.5vw;
}
.store_list_filter {
    color: darkslategray; font-size: 3.5vw; font-weight: lighter; text-align: right;
    display: flex; align-items: center;
    margin: 0; margin-left: auto; margin-right: 2vw; 
}
.store_list_filter_stretch {
    margin-left: auto;
}
.store_list_category_filter_menu {
    background: white;
    color: darkslategray; font-size: 3.5vw; font-weight: lighter; text-align: left;
    border-radius: 0;
}
.store_list_dropdown_icon {
    margin-left: 1vw;
}
.store_list_item {
    display: flex; align-items: center;
    width: 100vw; height: 26vw;
    margin: 0; padding: 0;
}
.store_list_item_labels {
    display: flex; align-items: center; justify-content: right;
    width: 94vw; height: 7.5vw; opacity: 0.65;
    margin-left: 3vw; margin-bottom: 1.5vw; padding: 0; padding-bottom: 1vw;
    border-bottom-color: rgba(0, 0, 0, 0.1); border-bottom-style: solid; border-bottom-width: 1px;
}
.store_list_item_labels_empty {
    width: 94vw; height: 2vw;
    margin-left: 3vw; margin-bottom: 1.5vw;
    border-bottom-color: rgba(0, 0, 0, 0.1); border-bottom-style: solid; border-bottom-width: 1px;
}
.store_list_item_image {
    background: lightsalmon;
    width: 40vw; height: 23vw;
    margin: 0; margin-left: 3vw; margin-right: 3vw; padding: 0;
    border-radius: 5px; box-shadow: 4px 4px 4px rgb(0, 0, 0, 0.2)
}
.store_list_item_info {
    width: calc(100vw - 9vw - 40vw); height: 23vw;
    margin: 0; margin-right: 3vw; padding: 0;
}
.store_list_item_info_row {
    display: flex; align-items: center;
    width: 100%; height: 5vw; margin-bottom: 1vw;
}
.store_list_item_title {
    color: rgba(0, 0, 0, 0.65); font-weight: bold; font-size: 3.5vw;
    width: 100%; height: 5.75vw; overflow: hidden;
    padding-top: 1vw;
    white-space: nowrap; text-overflow: ellipsis;  /* 超出控件宽度的文字用省略号代替 */
}
.store_list_category_info {
    color: black; font-size: 3vw;
    width: auto; height: auto; opacity: 0.5;
    margin-left: 0; margin-right: auto; margin-top: 1vw; overflow: hidden;
    /* white-space: nowrap; text-overflow: ellipsis; */
}
.store_list_mall_info {
    width: auto; max-width: 60%; opacity: 0.5; font-size: 3vw;
    margin-left: 0; margin-right: auto; overflow: hidden;
    white-space: nowrap; text-overflow: ellipsis;  /* 超出控件宽度的文字用省略号代替 */
}
.store_list_item_distance_location_area {
    display: flex; align-items: center;
    /* color: black; font-size: 2.5vw; */
    width: 100%; height: 3.75vw; margin-top: 1vw; margin-bottom: 1vw;
}
.store_list_item_distance_icon {
    background-size: cover; background-repeat: no-repeat;
    width: 4vw; height: 4vw; margin-left: auto; margin-right: 0;
}
.store_list_item_distance_text {
    display: flex; align-items: center;
    color: black; font-size: 3vw; text-align: right;
    width: auto; height: 5.75vw;
}
.store_hot_area {
    width: auto; height: 5.75vw; margin: 0;
}
.store_list_item_price {
    display: flex; align-items: center;
    color: black; font-size: 3vw;
    margin:0; margin-left: auto; padding:0; padding-top: 1vw;
    width: auto; height: 5.75vw; opacity: 0.5;
}
.store_list_empty {
    color: grey; padding: 6.5vw;
}
.store_list_loading {
    width: 100vw; height: 50vw;
    display: flex; align-items: center; justify-content: center;
}
.store_hot_stars {
    background-size: cover; background-repeat: no-repeat;
    width: 4vw; height: 4vw; min-width: 4vw; min-height: 4vw; 
    margin-top: 0; margin-right: 1vw; border: none;
    box-shadow: 1px 1px 1px rgb(0, 0, 0, 0.25);
    border-radius: 2px;border-width: 2px; border-color: darkgray;
}
.storelist_loading {
    width:100vw; height: 47vw;
    padding-left: 40vw; padding-top: 24vw;
}
.storelist_spin {
    width: 20vw; height: 20vw;
    padding-top: auto; padding-bottom: auto;
}
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: white;
  margin-bottom: 24px; border: 0px; overflow: hidden;
}
.store_list_load_more_btn {
    display: flex; align-items: center; justify-content: center;
    color: grey; font-size: 3vw;
    background: rgba(200, 200, 200, 0.2);
    width: 94vw; height: 10vw; margin: 3vw;
    border-bottom-color: rgba(0, 0, 0, 0.05); border-bottom-style: solid; border-bottom-width: 2px;
    border-right-color: rgba(0, 0, 0, 0.05); border-right-style: solid; border-right-width: 1px;
    border-radius: 2px;
}
.store_list_load_more_btn:active {
    color: white; background: lightsalmon;
}

