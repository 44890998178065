.zhihe_user_page {
    background: white;
    width:100vw; height: 100vh;
    max-width: 100vw; max-height: 100vh;
    overflow: hidden; overflow-y: auto;
}
.title_row {
    background: rgba(32, 178, 171, 0.5);
    width:100vw; height: 12vh;
    max-width: 100vw; max-height: 12vh;
    display: flex; align-items: center;
    justify-content: center;
     padding-right: 8vh;
}
.zhihe_logo {
    background-image: url("../../picture/ZhiHeLogo.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: 8vh; height: 8vh; min-width: 8vh; min-height: 8vh;
    border-radius: 100px; margin-right: 1vh;
}
.zhihe_slogan {
    background-image: url("../../picture/ZhiHeTitle.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: auto; min-width:176.7px; height: 8vh; min-height: 8vh;
    display: flex; align-items: center;justify-content: center;
}
.bottom_menu_row {
    width: 100%;height:8vh;
    display: flex; align-items: center;justify-content: center;
    /* margin-top: 80vh; margin-bottom: 0vh; */
}
.bottom_menu_btn {
    background:rgba(32, 178, 171, 0.5);
    width: 33.33%; height: 100%;
    font-weight: normal; color: black; font-size: 16px;
    display: flex; align-items: center;justify-content: center;
    border-style: solid; border-color: white; border-width: 1px;
}
.bottom_menu_btn_select {
    background: rgba(0, 139, 139, 0.75);  font-weight: bold; color: white; font-size: 18px;
}
.zhihe_center_area {
    background: rgba(32, 178, 171, 0.05);
    width: 100vw; height: 80vh;
    max-height: 80vh;
    overflow: hidden; overflow-y: auto;
}
.zhihe_center_title{
    /* background: lightsalmon; */
    width: 100vw; height: 7.5vh;
    font-weight: bold; font-size: 18px;
    display: flex; align-items: center;justify-content: center;
}
.zhihe_center_contents {
    width: 100vw; height: 72vh;
    padding-left: 10vw;  font-weight: bold;
    overflow: hidden; overflow-y: auto;
}