.category_page {
    background-color: rgba(0, 157, 255, 0); 
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height:auto;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden;
}