.new_button_general_style {
    background: lightseagreen;
    color: white; font-weight: bold;
}
.express_enter_edit_title {
    /* font-weight: bold; */
    min-width: 75px; 
    margin-left: 1vw;
}
.express_enter_edit_title_short {
    /* font-weight: bold; */
    min-width: 45px; 
    margin-left: 1vw;
}
.express_enter_edit_title_m {
    /* background: lightsalmon; */
    /* font-weight: bold; */
    min-width: 9vw; 
    margin-left: 2.5vw;
}
.express_enter_checkbox {
    font-weight: bold; min-width: 50px; 
    margin-left: 1vw;
}
.express_enter_input_name {
    display: flex; align-items: center;
}
.export_button_style {
    background: darkcyan;
    color: white; font-weight: bold;
}
.export_button_style:active{
    background: lightseagreen;
}
.express_enter_item_info_area {
    width: 100%; height: auto; min-height: 50px;
    margin-bottom: 20px;
}
.express_info_block {
    background: rgba(32, 178, 171, 0.15);
    padding-top: 15px; padding-bottom: 5px;
    margin-bottom: 15px;
    border-style: dashed; border-color: lightgray; border-width: 1px;
    border-radius: 5px;
}
.express_info_block_light {
    background: rgba(32, 178, 171, 0.075);
    padding-top: 15px; padding-bottom: 5px;
    margin-bottom: 15px;
    border-style: dashed; border-color: lightgray; border-width: 1px;
    border-radius: 5px;
}
.express_enter_express_info_add_button {
    cursor: pointer;
    display: flex; align-items: center; justify-content: center;
    background: lightseagreen; color: white; font-weight: bold;
    width: 100px; height: 35px;
    margin-left: auto; margin-right: 20px;
    border-radius: 5px;
}
.express_enter_express_info_add_button_left {
    margin-left: 20px; margin-top: 10px;
}
.express_enter_item_info_add_button {
    display: flex; align-items: center; justify-content: center;
    background: lightseagreen; color: white; font-weight: bold;
    width: 100px; height: 35px;
    margin-left: auto; margin-right: 0; margin-top: 5px;
    border-radius: 5px;
}
.express_enter_item_info_add_button:active {
    opacity: 0.5;
}
.express_enter_express_info_clean_button {
    cursor: pointer;
    display: flex; align-items: center; justify-content: center;
    background: lightcoral; color: white; font-weight: bold;
    width: 100px; height: 35px;
    margin-left: auto; margin-right: 20px; margin-top: 10px;
    border-radius: 5px;
}
.express_enter_express_info_clean_button:active {
    opacity: 0.5;
}
.express_enter_delete_button_first {
    display: flex; align-items: center; justify-content: center;
    width: 30px; height: 30px;
    color: lightslategray; font-weight: bold; font-size: 20px;
    position: relative; left: 547.5px; top: -132.5px;
}
.express_enter_delete_button {
    display: flex; align-items: center; justify-content: center;
    width: 30px; height: 30px;
    color: lightslategray; font-weight: bold; font-size: 20px;
    position: relative; left: 570px; top: -132.5px;
}
.express_enter_delete_button:active{
    opacity: 0.5;
}
.express_item_delete_button {
    display: flex; align-items: center; justify-content: center;
    width: 30px; height: 30px;
    color: rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 20px;
}
.express_item_delete_button:active{
    opacity: 0.5;
}
.express_enter_item_info_input_margin_left {
    margin-left: 15px;
}
.express_enter_item_info_plus_sign{
    margin: 10px; font-weight: bold;
}
.express_enter_item_info_input_short {
    width: 100px;
}
.express_enter_item_info_input_middle {
    width: 200px;
}
.express_enter_item_info_input_long {
    width: 495px; min-width: 495px;
}
.express_enter_item_info_input_full {
    width: 787.5px;
}
.express_enter_item_info_bottom_line {
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 1px;
}
.express_enter_item_total_fee {
    color: darkcyan;
}
.express_item_info_block {
    background: rgba(32, 178, 171, 0.15);
    width: 96%; height: auto;
    margin: auto; margin-bottom: 7.5px; margin-top: 7.5px; padding-top: 10px;
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 1px;
}
.express_item_info_block_light {
    background: rgba(32, 178, 171, 0.075);
    width: 96%; height: auto;
    margin: auto; margin-bottom: 7.5px; margin-top: 7.5px; padding-top: 10px;
    /* border-style: solid; border-color: lightgray; border-width: 1px; */
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 1px;
}
.express_item_info_row {
    display: flex; align-items: center;
    margin-right: 15px; margin-bottom: 10px;
}
.no_mouse_select {
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.express_enter_table_area_m {
    width:100vw; height:calc(100vh - 48vw);
    position: fixed; top: 48vw; left: 0;
    overflow-y: auto;
}
.package_combine_view_item_details {
    color: darkcyan; font-weight: bold; text-decoration: underline;
}
.package_combine_view_item_details:hover {
    cursor: pointer;
}
.package_combine_view_item_details:active {
    opacity: 0.5;
}
.view_item_details_area {
    width: 100%; height: auto; max-height: 80%;
    overflow: auto;
}
.view_item_details_row {
    width: 100%; height: 30px; margin-bottom: 10px;
    color: black; font-size: 15px; 
    display: flex; align-items: center;
}
.package_combine_info_block {
    background: rgba(32, 178, 171, 0.05);
    padding-top: 15px; padding-left: 0px; padding-right: 0px; padding-bottom: 1px; margin-bottom: 15px;
    border-bottom-style:solid; border-bottom-color:rgba(0, 0, 0, 0.05); border-bottom-width: 0.5px;
}
.package_combine_info_required_title {
    color: lightcoral; font-weight: bold;
}
.express_enter_info_list_title {
    /* background: rgba(0, 139, 139, 0.1); */
    width: cal(100% - 40px); height: auto; margin-left: 20px; margin-right: 20px;
    display: flex; align-items: center;
}
.express_enter_info_list_title_tracking_no {
    background: rgba(32, 178, 171, 0.05);
    width: 275px; height: 50px;
    display: flex; align-items: center; justify-content: center;
    border-right-style: solid; border-right-color: rgba(0, 0, 0, 0.1); border-right-width: 0.5px;
}
.express_enter_item_info_block {
    width: auto; height: auto;
    margin-left: 10px; padding: 0px; padding-top: 9px;
}
.express_enter_item_info_name {
    background: rgba(0, 0, 0, 0.1);
    background: white;
    width: 200px; min-width: 200px; max-width: 200px; min-height: 33px;
    display: flex; align-items: center;
    margin-left: 35px; margin-right: 15px; padding-left: 15px;
    border-radius: 5px; border-style: solid; border-color:rgba(128, 128, 128, 0.1); border-width: 0.5px;
}
.express_enter_item_info_num {
    width: 75px; min-width: 75px; max-width: 75px;
    margin-left: 30px;
}
