.store_cut_price_page {
    background: #f7f8f8;
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    position: fixed; top: 0vw; left: 0vw;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: scroll;
}
.store_type_spin {
    display: flex; align-items: center; justify-content: center;
}
.store_item_spin {
    background: #f7f8f8;
    width: 100vw; height: calc(100vh - 22vw);
    min-height: calc(100vh - 22vw);
    max-height: calc(100vh - 22vw);
    display: flex;justify-content: center;
    padding-top: 25vw;
    /* align-items: center;  */
}
.store_cut_price_bgp{
    width: 100vw; height: 75vw;
    background-size: cover; background-repeat: no-repeat;
    background-image: url("../../picture/MidAutumnTitle.jpg"); 
}
.store_cut_price_type_list{
    background-color: #f5ecd5;
    width: 100vw; height: 20vw; padding: 2vw; padding-top: 0.5vw;
    border-bottom-style:solid; border-bottom-color: #c9a063; border-bottom-width: 0.1vw;
}
.store_cut_price_intro_area {
    background: #f5ecd5;
    width: 100vw; height: auto;
    color: rgba(0, 0, 0, 0.75); font-size: 3.25vw;
    padding: 5vw; padding-right: 3vw; padding-left: 4vw; 
    border-bottom-style:solid; border-bottom-color: #c9a063; border-bottom-width: 0.1vw;
}
.store_cur_price_type_scoll_area {
    background-color: #f5ecd5;
    width: 96vw; height: 16vw;
    display: flex; align-items: center;
    overflow-x: scroll; overflow-y: hidden;
}
.store_cut_price_type_icon {
    width: 18vw; height: 18vw;
    min-width: 18vw; min-height: 18vw;
    margin-right:2vw; border-radius: 1vw;
}
.store_cut_price_type_icon:active{
    border-style:solid; border-width:0.5vw; border-color: white;
}
.store_cut_price_type_icon_selected {
    width: 16vw; height: 16vw;
    min-width: 16vw; min-height: 16vw;
    margin-right:2vw; border-radius: 1vw;
    border-style:solid; border-width:0.5vw; border-color: #c9a063;
}
.store_cut_price_item_list {
    background: #f7f8f8;
    width: auto; height: calc(100vh - 22vw - 14vw);
    min-height: calc(100vh - 22vw - 14vw);
    max-height: calc(100vh - 22vw - 14vw);
    margin: 0vw;
    overflow-x: hidden; overflow-y: scroll;
}
.store_cut_price_item_row {
    width: 90vw; height: 27.5vw; display: flex;
    margin: 5vw; margin-top:2.5vw; margin-bottom: 5vw;
    border-bottom-style:dotted; border-bottom-color: rgba(128, 128, 128, 0.25);
}
.store_cut_price_item_row_first {
    margin-top: 5vw; 
}
.store_cut_price_item_icon {
    width: 35vw; height: 25vw;
    min-width: 35vw; min-height: 25vw;
    margin: 0; padding: 0;
}
.store_cut_price_item_info {
    width: 45vw; height: 25vw; min-width: 40vw;
}
.store_cut_price_item_active_area {
    width: 15vw; height: auto; z-index: 100;
}
.store_cut_price_dollar_sign{
    display: flex; align-items: center;
    color: darkred; font-size: 4.5vw; font-weight: bold;
    margin: 0.5vw; margin-left: 1vw;
}
.store_cut_price_item_price {
    display: flex; align-items: center;
    color: darkred; font-size: 6vw; font-weight: bold;
    width: 26vw; height: 10vw; 
    position: relative; top: 0vw; left: -10vw;
}
.store_cut_price_item_price_oge {
    display: flex; align-items: center;
    color: grey; font-size: 2.75vw;
    width: 26vw; height: 5vw; 
    position: relative; top: -1.35vw; left: -7vw;
    text-decoration:line-through;
}
.store_cut_price_item_buy_button {
    background: lightcoral;
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 3.75vw; font-weight: bold;
    width: 22vw; height: 9vw; 
    position: relative; top: 0vw; left: -11vw;
    border-width: 0.5vw; border-color: white; border-style: solid; border-radius: 0vw;
}
.store_cut_price_item_buy_button:active {
    background: white;
    border-color: #c9a063;
    color: #c9a063;
}
.store_cut_price_item_buy_button_again{
    background: lightcoral;
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 3.75vw; font-weight: bold;
    width: 22vw; height: 9vw; 
    position: relative; top: 0vw; left: -11vw;
    border-width: 0.5vw; border-color: white; border-style: solid; border-radius: 0vw;
}
.store_cut_price_item_buy_button_again:active {
    opacity: 0.5;
}
.store_cut_price_item_buy_button_over{
    background: lightgray;
    display: flex; align-items: center; justify-content: center;
    color: gray; font-size: 3.75vw; font-weight: bold;
    width: 22vw; height: 9vw; 
    position: relative; top: 0vw; left: -11vw;
    border-width: 0.5vw; border-color: white; border-style: solid; border-radius: 0vw;
}
.store_cut_price_item_name {
    font-size: 4vw; color: black; font-weight: bold;
    width: 40vw; height: auto;
    margin-top: 2vw;
}
.store_cut_price_item_details {
    display: flex; align-items: center;
    font-size: 2.75vw; color: rgba(0, 0, 0, 0.5); 
    width: 40vw; height: auto;
    margin-top: 1vw; margin-left: 0.25vw;
}
.store_cut_price_item_progress {
    font-size: 2.75vw; color: rgba(0, 0, 0, 0.5); 
    width: 40vw; height: auto;
    padding: 1vw; padding-left: 0.25vw; padding-bottom: 0.25vw;
}

.item_details_title {
    background: #f5ecd5;
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 13vw; position: fixed; left: 0; top: 0;
    margin: 0; padding: 0;
}
.item_details_back {
    display: flex; align-items: center; justify-content: center;
    width: 13vw; height: 13vw; position: fixed; left: 0; top: 0;
    color: rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 6vw;
    z-index: 300;
}
.item_details_title_text {
    display: flex; align-items: center; justify-content: center;
    width: 95vw; height: 13vw; position: fixed; left: 5vw; top: 0;
    color: rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 4.5vw;
}
.item_details_bgp {
    width: 100vw; height: 75vw; opacity: 1;
    background: #f7f8f8;
    /* background-size: cover; background-repeat: no-repeat; */
    /* background-image: url("../../picture/MidAutumnTitle.jpg");  */
    position: fixed; left: 0; top: 13vw;
    display: flex; align-items: center; justify-content: center;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.1); border-bottom-width: 1px;
}
.item_details_bgc {
    background: white;
    width: 100vw; height: calc(100vh - 75vw - 13vw - 13vw); opacity: 1;
    position: fixed; left: 0; top: calc(75vw + 13vw);
    overflow-x: hidden; overflow-y: scroll;
}
.item_details_image_area {
    width: 70vw; height: 50vw;
    min-width: 70vw; min-height: 50vw;
    margin: 0; padding: 0;
    margin-left: 10vw;
}
.item_details_image_cover{
    background: rgb(235, 235, 235);
    background: #f7f8f8;
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.25); font-size: 3.5vw;
    width: 26vw; height: 9vw; 
    min-width: 26vw; min-height: 9vw; 
    position: relative; top: 12.5vw; left: -22.5vw;
    /* border-width: 0.5vw; border-color: white; border-style: solid; border-radius: 0vw; */
}
.item_select_image_cover{
    background: #f7f8f8;
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.3); font-size: 3vw;
    width: 20vw; height: 8vw; 
    min-width: 20vw; min-height: 8vw; 
    position: relative; top: 0vw; left: -11vw;
}
.item_details_row {
    display: flex; align-items: baseline;
    width: 100vw; height: 10vw;
}
.item_details_time_left {
    color: grey; font-size: 3.5vw;
    margin-left: 5vw; margin-top: 3vw;
}
.item_details_cut_price{
    display: flex; align-items: center;
    color: darkred; font-weight: bold; font-size: 5vw;
    margin-left: 5vw; margin-top: 4vw;
}
.item_details_org_price {
    color: grey; font-size: 3.5vw;
    margin-left: 2vw;
    text-decoration:line-through;
}
.item_details_num {
    color: grey; font-size: 3.5vw;
    margin-left: auto; margin-right: 5vw;
}
.item_details_name{
    color: black; font-weight: bold; font-size: 4.5vw;
    margin:5vw; margin-top: 0vw; padding-top: 2vw;
}
.item_details_description {
    display: flex; align-items: center;
    width: 90vw; height: 6vw;
    color: grey; font-size: 3.5vw;
    margin: 5vw; margin-top: 0;
    margin-bottom: 0vw;
    padding: 0;
}
.item_details_bottom_space {
    width: 100vw; height: 5vw;
}
.item_details_progress {
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: auto;
    padding: 5.25vw; padding-top:1vw; padding-bottom: 1vw;
    margin-top: 4vw;
}
.cut_price_bottom_bar {
    background: #f5ecd5;
    background: lightblue;
    width: 100vw; height: 13vw;
    position: fixed; top: calc(100vh - 13vw); left: 0; z-index: 1002;
}
.cut_price_bottom_contact {
    background: lightseagreen;
    width: 13vw; height: 13vw;
    position: fixed; bottom: 0vw; left: 0; z-index: 1002;
    margin-left: auto; margin-right: auto;
}
.cut_price_bottom_contact:active {
    background: rgba(32, 178, 171, 0.5); color: black;
}
.cut_price_bottom_text {
    color: white;font-size: 4vw; text-align: center;
    padding-top: 0.5vw;
}
.cut_price_bottom_share {
    background: lightskyblue;
    width: 13vw; height: 13vw;
    position: fixed; bottom: 0vw; right: 0; z-index: 1002;
}
.cut_price_bottom_share:active{
    background: rgba(135, 206, 250, 0.5); color: black;
}
.cut_price_bottom_buy {
    background: lightcoral;
    color: white; font-size: 4vw;text-align: center;
    width: 26vw; height: 13vw;
    position: fixed; bottom: 0vw; left: 13vw; z-index: 1002;
    padding-left: auto; padding-right: auto; padding-top: 0.5vw;
}
.cut_price_bottom_buy:active {
    background: rgba(240, 128, 128, 0.5);
}
.cut_price_bottom_cut_price {
    background: crimson;
    color: white; font-weight: bold; font-size: 4vw; text-align: center;
    width: calc(100vw - 52vw); height: 13vw;
    position: fixed; bottom: 0vw; right: 13vw;  z-index: 1002;
    padding-top: 0.5vw;
}
.cut_price_bottom_cut_price:active {
    background:rgba(220, 20, 60, 0.5);
}
.share_dlg_title{
    text-align: center; font-size: 4vw;
}
.share_dlg_qrcode {
    display: flex; align-items: center; justify-content: center;
    width: auto; height: auto; 
    margin-top: 5vw; margin-bottom: 2.5vw; margin-left: auto; margin-right: auto; 
}
.share_dlg_details{
    display: flex; align-items: center; justify-content: center;
    font-size: 4vw; color: rgba(0, 0, 0, 0.5); margin-bottom: 2vw;
}
.store_cut_price_user_button {
    background: rgba(0, 0, 0, 0.5);
    display: flex; align-items: center; justify-content: center;
    opacity: 0.75; font-size: 6vw; color: white;
    width: 10vw; height: 10vw;
    position: fixed; top: 2vw; right: 2vw; z-index: 300;
    border-radius: 5vw;
}
.store_cut_price_user_button:active {
    opacity: 1; background: black;
}
.item_select_dlg{
    background: #f7f8f8;
    width: auto; height: auto;
    padding-top: 3vw; padding-bottom: 3vw;
    display: flex; border-radius: 1vw;
}
.item_select_num_area {
    width: 30vw; height: 26vw;
    display: flex; align-items: center;justify-content: center;
    margin-top: auto; margin-bottom: auto;
}
.item_select_add_button {
    background: rgba(0, 0, 0, 0.1);
    width: 6.5vw; height: 6.5vw;
    min-width: 6.5vw; min-height: 6.5vw;
    font-size: 5vw; color: rgba(0, 0, 0, 0.6);
    display: flex; align-items: center; justify-content: center;
    border-radius: 1vw; margin: 2vw;
}
.item_select_add_button:active {
    background: rgba(0, 0, 0, 0.5);
}
.item_select_num {
    width: 7.5vw; min-width: 7.5vw; text-align: center;
    font-size: 4vw; color: rgba(0, 0, 0, 0.7);
}
.final_price_calculate_area {
    background: #f7f8f8;
    width: 100%; height: auto;
    margin-top: 1.5vw; border-radius: 1vw;
    padding: 0; padding-left: 3vw; padding-right: 3vw;
}
.final_price_calculate_row {
    width: 100%; height: 8vw;
    display: flex; align-items: center;
}
.final_price_calculate_text {
    width: 100%; height: 8vw;
    color: rgba(0, 0, 0, 0.5);
    font-size: 3.25vw; text-align: right;
    padding-top: 2vw;
}
.final_price_tips {
    display: flex; align-items: center;
    width: 5vw; height: 5vw; z-index: 350;
    margin-left: 1.5vw; margin-right: 1vw;
}
.final_price_claculate_line {
    border-bottom-style: solid; border-bottom-width: 0.25vw; border-bottom-color: rgba(0, 0, 0, 0.2);
}
.final_price_calculate_result {
    color: darkred; font-weight: bold;
    margin-top: 0vw; margin-bottom: 1vw;
}
.confirm_payment_button {
    background: skyblue; opacity: 1;
    display: flex; align-items: center; justify-content: center;
    color: white; font-weight: bold; font-size: 4vw;
    width: 100%; height: 10vw;
    margin-top: 3vw; border-radius: 1vw; 
}
.confirm_payment_button:active {
    opacity: 0.75;
}
.store_cut_price_ship_way {
    width: 10vw; height: 10vw;
    min-width: 10vw; min-height: 10vw;
    position: relative; top: -5vw; left: -5vw;
    background-size: cover; background-repeat: no-repeat;
}
.store_cut_price_item_details_ship_way {
    width: 13vw; height: 13vw;
    min-width: 13vw; min-height: 13vw;
    position: relative; top: 13vw; right: -87vw;
    background-size: cover; background-repeat: no-repeat;
}
.final_price_calculate_pickup_way_area {
    background: #f7f8f8;
    width: 100%; height: auto;
    margin-top: 1.5vw; padding-top: 3vw;
    border-radius: 1vw;
}
.final_price_calculate_pickup_way_select{
    
    padding: 0; padding-left: 5vw;
    font-size: 3.25vw;
}
.final_price_calculate_info_row{
    background: #f7f8f8;
    display: flex; align-items: center;
    width: 100%; height: 10vw;
    margin-top: 1.5vw; border-radius: 1vw;
    padding: 0; padding-left: 3vw; padding-right: 3vw;
}
.final_price_calculate_row_icon {
    font-weight: bold; font-size: 4vw; margin-left: 2vw; margin-right: 2vw;
}
.final_price_calculate_row_text {
    width: 56.5vw;
    font-size: 3.25vw;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.final_price_calculate_row_right_text {
    font-size: 3.25vw; margin-left: auto; margin-right: 0vw;
}
.item_details_dlg_area {
    background: blueviolet;
    width: 100vw; height: 100vh;
    /* height: calc(100vh - 13vw); */
    padding: 0; margin: 0;
    /* position: fixed; top: 0; left: 0; z-index: 1001; */
}
.area_select_area {
    background: rgba(0, 0, 0, 0.5);
    display: flex; align-items: center; justify-content: center;
    width: 25vw; height: 10vw;
    color: white; font-size: 3.5vw; font-weight: bold;
    position: fixed; left: 2vw; top: 2vw; z-index: 300;
    border-radius: 1vw;
}
.area_select_button {
    color: white; font-size: 3.5vw; font-weight: bold;
}


/* 美心 */
.brand_icon_0{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1598130430/10005-%E5%85%B1%E4%BA%AB%E4%BD%B3%E6%9C%88/Type01_eatpq8.jpg"); 
}
/* 富锦 */
.brand_icon_1{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1597776872/10005-%E5%85%B1%E4%BA%AB%E4%BD%B3%E6%9C%88/Type02_rvx4we.jpg"); 
}
/* 圣安娜饼屋 */
.brand_icon_2{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1597776872/10005-%E5%85%B1%E4%BA%AB%E4%BD%B3%E6%9C%88/Type03_bqlcqd.jpg"); 
}
/* 麦轩 */
.brand_icon_3{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1598130430/10005-%E5%85%B1%E4%BA%AB%E4%BD%B3%E6%9C%88/Type04_sbfhgi.jpg"); 
}
/* 星巴克 */
.brand_icon_4{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1598130431/10005-%E5%85%B1%E4%BA%AB%E4%BD%B3%E6%9C%88/Type05_b2u7uw.jpg"); 
}
/* 荣诚食品 */
/* .brand_icon_5{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1597777190/10005-%E5%85%B1%E4%BA%AB%E4%BD%B3%E6%9C%88/Type06_dfaoue.jpg"); 
} */
/* 月美食品 */
/* .brand_icon_6{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/jingtu-ego-test/image/upload/v1597781125/10005-%E5%85%B1%E4%BA%AB%E4%BD%B3%E6%9C%88/Type07_nc79wc.jpg"); 
} */

/* .brand_icon_6{
    background-size: cover; background-repeat: no-repeat;
    background-image: url("../../picture/Type07.jpg"); 
} */