.recommends_area {
    width: 100vw; height: 43vw;
    border-style: solid; border-color: lightgray; border-width: 0px 0px 0.5px 0px;
}
.recommends_title {
    color: black; font-weight: bold; font-size: 4.5vw; text-align: left;
    display: flex; align-items: center; opacity: 0.9;
    width: 100vw; height: 11vw;
    margin: 0; padding: 0; padding-left: 4vw; padding-top: 2.5vw;
}
.recommends_filter {
    color: darkslategray; font-size: 3.5vw; font-weight: lighter; text-align: right;
    display: flex; align-items: center;
    margin: 0; margin-left: auto; margin-right: 2vw; 
}
.recommends_filter_menu {
    background: white; border-radius: 0;
    color: darkslategray; font-size: 3.5vw; font-weight: lighter; text-align: left;
}
.recommends_dropdown_icon {
    margin-left: 1vw;
}
.recommends_scroll_bar_hide_area {
    width: 100vw; height: 32vw;
    margin: 0; padding: 0; overflow: hidden;
}
.recommends_main_contents_scroll {
    width: 100vw; height: 34vw;
    margin: 0; padding: 0;
    overflow-x: scroll; overflow-y: hidden;
}
.ego_recommend_contents_layout {
    display: flex; align-items: center;
    margin: 0; padding: 0; width: 94vw; height: 30vw;
}
.ego_recommend_width_3 {
    width: 132.5vw; 
}
.ego_recommend_width_4 {
    width: 175.625vw; 
}
.ego_recommend_width_5 {
    width: 218.75vw; 
}
.ego_recommend_width_6 {
    width: 261.875vw; 
}
.ego_recommend_width_7 {
    width: 305vw; 
}
.ego_recommend_item {
    background: lightsalmon;
    width: 40vw; height: 23vw;
    margin: 3vw; margin-right: 0; margin-bottom: 0; padding: 0;
    border-radius: 5px; box-shadow: 4px 4px 4px rgb(0, 0, 0, 0.2);
}
.ego_recommend_item_name {
    color: rgba(0, 0, 0, 0.6); font-weight: bold; font-size: 3vw; text-align: center;
    width: 40vw; height: 6vw;
    margin-left: 3vw; margin-bottom: 1vw; padding: 1vw; padding-top: 1.5vw;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.ego_recommend_empty {
    width: 100%; height: 10vw;
}

.recommends_loading {
    background-color: rgba(255, 160, 122, 0.15);
    width: 100vw; height: 43vw;
    padding-left: 40vw; padding-top: 16vw;
}
.recommends_spin {
    width: 20vw; height: 20vw;
    padding-top: auto; padding-bottom: auto;
}