.store_backend_title {
    display: flex; align-items: center; justify-content: center;
    color: lightcyan; font-weight: bold;
    width: 100%; height: 50px;
    margin: auto; padding: auto;
}
.store_manage_side_bar {
    width: 125px; height: 100vh;
    position: fixed; left: 0; top: 50px;
    overflow: hidden;
}
.store_manage_logo {
    background: #000f29;
    /* background: darkcyan; */
    color: lightcyan; font-weight: bold; 
    display: flex; align-items: center; justify-content: center;
    width: 125px; min-width: 125px;height: 50px;
}
.store_contents_title {
    position: fixed; top: 54px; left: 125px; z-index: 700;
    background-color: gray;
    display:flex; align-items: center;
    width:calc(100vw - 125px); height:50px;
    padding: 20px;
    border-bottom-style: solid; border-bottom-color: white; border-bottom-width: 1px;
}
.store_contents_title_sec {
    position: fixed; top: 104px; left: 125px; z-index: 700;
    background-color: gray;
    display:flex; align-items: center;
    width:calc(100vw - 125px); height:50px;
    padding: 20px;
    border-bottom-style: solid; border-bottom-color: white; border-bottom-width: 1px;
}
.store_contents_title_thd {
    position: fixed; top: 154px; left: 125px; z-index: 700;
    background-color: gray;
    display:flex; align-items: center;
    width:calc(100vw - 125px); height:50px;
    padding: 20px;
    border-bottom-style: solid; border-bottom-color: white; border-bottom-width: 1px;
}
.store_contents_title_m {
    background: grey;
    display:flex; align-items: center;
    justify-content: center;
    width:100vw; height:12vw;
    border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-style: solid; border-bottom-width: 1px;
    position: fixed; top: 12vw; left: 0; z-index: 300;
}
.store_contents_title_sec_m {
    background: grey;
    display:flex; align-items: center;
    justify-content: center;
    width:100vw; height:12vw;
    border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-style: solid; border-bottom-width: 1px;
    position: fixed; top: 24vw; left: 0; z-index: 300;
}
.store_contents_title_thd_m {
    background: grey;
    display:flex; align-items: center;
    justify-content: center;
    width:100vw; height:12vw;
    border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-style: solid; border-bottom-width: 1px;
    position: fixed; top: 36vw; left: 0; z-index: 300;
}
.store_manage_contents {
    background:rgba(225, 225, 225);
    width: calc(100vw - 125px); height: calc(100vh - 100px);
    position: fixed; top: 54px; left: 125px;
    margin: 0; padding: 0; padding-top: 20px; padding-bottom: 40px;
    overflow-x: auto; overflow-y: auto;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.store_manage_contents_m {
    background:rgba(225, 225, 225);
    /* background: lightcoral; */
    width: 100vw; height: calc(100vh - 12vw);
    margin: 0; padding: 0;
    overflow: hidden;
}
.store_manage_top_space{
    top: 104px;
}
.store_manage_top_space_double{
    top: 154px;
    padding-bottom: 90px;
}
.store_manage_top_space_trible{
    top: 204px;
    padding-bottom: 140px;
}
.store_manage_top_no_space {
    height: calc(100vh - 50px);
}
.store_time_manage_area {
    background: lightcyan;
    display: flex; align-items: center;
    margin: 0; padding: 10px; padding-top: 0;
}
.store_time_manage_title {
    color: lightcoral; font-weight: bold;
    margin-top: 10px; margin-bottom: auto; margin-right: 20px;
}
.store_time_picker {
    margin: 5px; margin-left: 10px; margin-right: 15px;
}

.manage_top_bar_m {
    background: #000f29;
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 12vw;
}
.store_backend_title_m {
    /* background: lightcoral; */
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 4.5vw;
    font-weight: bold; 
    width: auto; height: 100%;
    margin: auto; padding: auto;
    margin-right: 40vw;
}
.store_manager_menu_button {
    background: rgba(0, 0, 0, 0.5);
    display: flex; align-items: center; justify-content: center;
    width: 10vw; height: 10vw;
    position: fixed; top: 1vw; right: 2vw; z-index: 500;
    color: white; font-size: 4.5vw;
    font-weight: bold; 
    border-radius: 1vw;
}
.store_manager_menu_button:active {
    background: grey; opacity: 0.5;
}
.store_manager_menu_text_m {
    display: flex; align-items: center; justify-content: center;
    font-size: 3.5vw;
    width: 30vw; height: 8vw;
}
.store_manager_menu_icon_m {
    display: flex; align-items: center; justify-content: center;
    font-weight: bold; font-size: 4vw;
    margin-right: 2vw;
    margin-bottom: 0.5vw;
    height: 8vw;
}
.store_manager_central_m {
    width: 100vw; height: auto;
}
.store_manager_control_button {
    display: flex; align-items: center; justify-content: center;
    width: 9vw; height: 9vw;
    font-weight: bold; font-size: 4.5vw;
    margin-left: 1.5vw; margin-right: 1.5vw;
}
.order_search_input_m {
    width: 35vw;  height: 9vw;
    margin-left: 3vw; margin-right: 1vw;
    font-size: 4vw;
}
.store_manage_table_m {
    /* color: lightseagreen; */
    font-size: 3.5vw;
}
.table_row_highlight {
    background: darkcyan;
    background: lightsalmon;
    font-weight: bold;
}
.return_to_home_button {
    /* background: darksalmon; */
    color: white; font-weight: bold; 
    height: 46px; max-height: 46px;
    margin-bottom:4px;
    /* max-width: 50px; */
}
.return_to_home_button:active {
    opacity: 0.8;
}
.return_to_home_button:hover {
    background:darksalmon; color: black;
    cursor: pointer;
}