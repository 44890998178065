.sub_categories_carousel {
    color: black; font-family: Arial, Helvetica, sans-serif;
    width:100vw; height: 20vw; overflow: hidden;
}
.sub_category_area {
    width: 100vw; height: 20vw;
}
.sub_category_item {
    width: 20vw; height: 20vw;
    position: relative; padding-top: 4vw;
}
.sub_category_item_selected {
    background-color: white;
    width: 20vw; height: 20vw;
    position: relative; padding-top: 4vw; 
    box-sizing: border-box; border-bottom-style: solid; border-bottom-color: rgba(90, 190, 255, 1); border-bottom-width: 2px;
}
.sub_category_item:active {
  background-color: white;
  box-sizing: border-box; border-width: 1px; border-color: lightgray; border-style: solid; 
}
.sub_item_0 {
    top:0; left:0;
}
.sub_item_1 {
    top: -20vw; left: 20vw;
}
.sub_item_2 {
    top: -40vw; left: 40vw;
}
.sub_item_3 {
    top: -60vw; left: 60vw;
}
.sub_item_4 {
    top: -80vw; left: 80vw;
}
.sub_category_icon {
    background-size: cover; background-repeat: no-repeat;
    width: 8.5vw; height: 8.5vw;
    padding:0; margin-left: auto; margin-right: auto;
}
.sub_category_text {
    color: black; font-size: 3vw; text-align: center;
    width:20vw; height: 6vw; padding-top: 1vw;
}
