.search_page {
    background-color: white;
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden;
}
/* .search_title {
    display: flex; align-items: center;
    width: 100vw; height: 14vw;
    padding: 0; padding-left: 3vw; padding-right: 8.5vw;
}
.search_back_btn {
    width: 6vw; height: 6vw;
    margin: 0; margin-right: 3vw;
    background-size: cover; background-repeat: no-repeat;
}
.search_back_btn:focus, .search_back_btn:active {
    opacity: 1; width: 7vw; height: 7vw;
} */

.search_top_area {
    margin-top: 14vw;
}
.search_bottom_area {
    margin-bottom: 7vw;
}

/* 包括热门搜索和历史记录两部分 */
.search_list_area {
    width: 100vw; height: auto;
    margin: 0; padding-bottom: 5vw;
    border-top-style: solid; border-top-color: lightgrey; border-top-width: 1px;
}
.search_list_title {
    display: flex; align-items: center;
    font-size: 4.5vw; height: 12vw;
    padding-left: 4vw;
}
.search_history_delete {
    width: 5vw; height: 5vw;
    background-size: cover; background-repeat: no-repeat;
    margin: 0; margin-left: auto; margin-right: 4vw;
}
.search_history_delete:focus, .search_history_delete:active {
    opacity: 1;
}
.search_list_row {
    display: flex; align-items: center;
    width: 100vw; height: 10vw;
    padding:0; padding-left: 1.5vw; padding-right: 2.5vw;
}
.search_list_item {
    background: rgba(0, 191, 255, 0.25);
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
    color: black; font-size: 3.25vw; text-align: center;
    width: auto; min-width: 15vw; max-width: 25vw; height: 7vw;
    margin: 0; margin-left: 2vw; padding: 1vw; padding-left: 1.5vw; padding-right: 1.5vw;
    border-radius: 5px;  border-style: solid; border-width: 1px; border-color: lightgray;
    opacity: 0.9;
}
.search_hot_item {
    background: rgba(240, 128, 128, 0.5);
}
.search_history_item {
    background: rgba(0, 191, 255, 0.25);
}
.search_list_item:focus, .search_list_item:active {
    background: white; color: black; border-color: black; opacity:1;
}
.search_result_area {
    width: 100vw; height: calc(100vh - 14vw);
    margin-top: 14vw; overflow-x: hidden; overflow-y: scroll;
}
.search_keywords {
    display: flex; align-items: center;
    width: 100vw; height: 14vw; padding: 1vw;
    border-bottom-style: solid;border-bottom-width: 1px; border-bottom-color: lightgray;
}
.search_store_list_area {
    width: 100vw; height: auto;
    padding: 0; padding-top: 1vw; padding-bottom: 4.5vw;
    overflow-x: hidden; overflow-y: scroll;
}
.search_page_empty {
    width: 100vw; height: calc(100vh - 13vw - 32vw - 26vw);
    padding-top: 32vw;
}
.search_login_btn {
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.6); font-size: 3vw;
    background: lightsalmon;
    width: 20vw; height: 8vw;
    margin-left: 40vw; margin-top:5vw;
    border-radius: 5px;
}
.search_login_btn:active {
    background: lightsalmon; color: white;
}
