/**** 图标相关 ****/

/* 海运 */
.ship_icon {
    background-image: url("../picture/ShipIcon.png"); 
}
/* 空运 */
.plane_icon {
    background-image: url("../picture/PlaneIcon.png"); 
}

/**** 1. 通用＋主分类 ****/
/* Logo */
.mouthing_logo {
    background-image: url("../picture/MouthingLogo.png"); 
}
.lekon_logo {
    background-image: url("../picture/LekonLogo.png"); 
}
.xiyouji_logo {
    background-image: url("http://www.jwestlog.com/upload/636634658352803065_logo.jpg");
    width: 8vw; height: 3vw; margin-left: 0; margin-right: 1vw;
    background-size: cover; background-repeat: no-repeat;
}
.logo_icon {
    background-image: url("../picture/EgoLogo.png"); 
}
/* 返回 */
.back_icon {
    width: 6vw; height: 6vw;
    background-image: url("../picture/Back.png"); 
    opacity:0.8; border-style: solid; border-color:white; border-width: 2px; border-radius: 20px;
}
/* 删除 */
.delete_icon {
    background-image: url("../picture/Delete.png"); 
    opacity: 0.5;
}
/* 编辑 */
.edit_icon {
    background-image: url("../picture/Edit.png"); 
}
/* 首页定位按钮 */
.location_icon_brown {
    background-image: url("../picture/LocationBrown.png"); 
}
/* 定位0, 商家列表距离显示 */
.location_icon {
    background-image: url("../picture/Location0.png"); 
}
/* 定位1, 首页左上角*/
.location_icon_1 {
    background-image: url("../picture/Location1.png");
}
/* 定位2, 侧边栏*/
.location_icon_2 {
    background-image: url("../picture/Location2.png"); 
}
/* 定位3, 侧边栏*/
.location_icon_3 {
    background-image: url("../picture/Location3.png"); 
}
/* 首页 */
.home_icon {
    background-image: url("../picture/Home.png"); 
}
/* 搜索 */
.search_icon {
    background-image: url("../picture/Search.png"); 
}
/* 用户 */
.user_icon {
    background-image: url("../picture/User.png"); 
}
/* 优惠券 */
.ticket_icon {
    width: 15px; height: 15px;
    background-image: url("../picture/Ticket.png"); 
}
/* 收藏 */
.collects_icon {
    width: 13px; height: 13px;
    background-image: url("../picture/Collects.png"); 
}
/* Star 点击收藏 */
.unstar_icon {
    background-image: url("../picture/Add.png"); 
}
.starred_icon {
    background-image: url("../picture/Added.png"); 
}
/* 喜欢 */
.like_icon {
    background-image: url("../picture/Like.png"); 
}
.liked_icon {
    background-image: url("../picture/Liked.png"); 
}
/* 分享 */
.share_icon {
    background-image: url("../picture/Share.png"); 
}
/* 设置 */
.setting_icon {
    background-image: url("../picture/Setting.png"); 
}
/* 消息 */
.message_icon {
    background-image: url("../picture/Message.png"); 
}
/* 积分 */
.credit_icon {
    background-image: url("../picture/Credit.png"); 
}
/* 客服 */
.phone_icon {
    background-image: url("../picture/Phone.png");
}
.service_icon {
    background-image: url("../picture/Service.png"); 
}
/* 订单 */
.order_icon {
    background-image: url("../picture/Order.png"); 
}
/* Card */
.card_icon {
    background-image: url("../picture/Card.png"); 
}
/* 关于我们 */
.about_us_icon {
    background-image: url("../picture/AboutUs.png"); 
}
/* 购物车 */
.cart_icon {
    background-image: url("../picture/Cart.png"); 
}
/* 商家 */
.store_icon {
    background-image: url("../picture/Store.png"); 
}
/* 侧边栏底图 */
.sidebar_bgp {
    background-image: url("../picture/SideBarBGP.jpg"); 
}
/* Master Card */
.master_icon {
    background-image: url("../picture/MasterIcon.png"); 
}
/* Visa Card */
.visa_icon {
    background-image: url("../picture/VisaIcon.png"); 
}
/* Walk */
.walk_icon {
    background-image: url("../picture/Walk.png"); 
}
/* Distance */
.distance_icon {
    background-image: url("../picture/Distance.png"); 
}
/* Drive */
.drive_icon {
    background-image: url("../picture/Drive.png"); 
}
/* 主分类 */
/* 美食 */
.category_food_icon {
    background-image: url("../picture/Food.png");
}
/* 生活服务 */
.category_life_icon {
    background-image: url("../picture/Life.png"); 
}
/* 休闲娱乐 */
.category_play_icon {
    background-image: url("../picture/Play.png"); 
}
/* 购物 */
.category_shopping_icon {
    background-image: url("../picture/Shopping.png"); 
}
/* 周边游 */
.category_travel_icon {
    background-image: url("../picture/Travel.png"); 
}
/* 商家评星 */
.hot_star_icon {
    opacity: 0.8;
    background-image: url("../picture/Star1.png"); 
}
.hot_star_icon_empty {
    opacity: 0.4;
    background-image: url("../picture/Star0.png"); 
}
/* 生活指南 */
.campass_icon {
    background-image: url("../picture/Campass.png"); 
}
/* 排队 */
.lineup_icon {
    background-image: url("../picture/Lineup.png"); 
}
/* 营业时间 */
.clock_icon {
    background-image: url("../picture/Clock.png"); 
}
/* 生活小广播 */
.broadcast_icon {
    background-image: url("../picture/Broadcast.png"); 
}
/**** 2. 子分类 ****/
/* 奶茶甜品 */
.category_milktea_icon {
    background-image: url("../picture/MilkTea.png"); 
}
/* 汽车服务 */
.category_car_icon {
    background-image: url("../picture/CarService.png"); 
}
/* 美发丽人 */
.category_haircut_icon {
  background-image: url("../picture/HairCut.png"); 
}
/* 卡牌桌游 */
.category_deskgame_icon {
    background-image: url("../picture/DeskGame.png"); 
}
/* 儿童看护 */
.category_children_icon {
    background-image: url("../picture/ChildrenCare.png"); 
}
/* 营养保健 */
.category_medical_icon {
    background-image: url("../picture/Medical.png"); 
}
/* 汽车维修 */
.category_carrepair_icon {
    background-image: url("../picture/CarRepair.png"); 
}
/* 成人教育 */
.category_lecture_icon {
    background-image: url("../picture/Lecture.png"); 
}
/* 酒吧KTV */
.category_ktvbar_icon {
    background-image: url("../picture/KTVBar.png"); 
}
/* 清扫保洁 */
.category_cleaning_icon {
    background-image: url("../picture/Cleaning.png"); 
}
/* 钢琴陪练 */
.category_musical_icon {
    background-image: url("../picture/Musical.png"); 
}
/* 投资理财 */
.category_investement_icon {
    background-image: url("../picture/Investement.png"); 
}
/* 税务咨询 */
.category_tax_icon {
    background-image: url("../picture/Tax.png"); 
}
/* 搬家安置 */
.category_moving_icon {
    background-image: url("../picture/Moving.png"); 
}
/* 约会交友 */
.category_dating_icon {
    background-image: url("../picture/Dating.png"); 
}
/* 母婴产品 */
.category_baby_product_icon {
    background-image: url("../picture/BabyProduct.png"); 
}
/* 服装定制 */
.category_diy_clothes_icon {
    background-image: url("../picture/DIYClothes.png"); 
}
/* 代驾陪练 */
.category_driving_icon {
    background-image: url("../picture/Driving.png"); 
}
/* 护理保养 */
.category_health_care_icon {
    background-image: url("../picture/HealthCare.png"); 
}
/* 人力资源 */
.category_hr_icon {
    background-image: url("../picture/HR.png"); 
}
/* 锻炼健身 */
.category_sport_icon {
    background-image: url("../picture/Sport.png"); 
}
/* 留学咨询 */
.category_study_abroad_icon {
    background-image: url("../picture/StudyAbroad.png"); 
}
/* 拍照摄影 */
.category_photography_icon {
    background-image: url("../picture/Photography.png"); 
}
/* 宠物 */
.category_pets_icon {
    background-image: url("../picture/Pets.png"); 
}
/* 游戏 */
.category_game_icon {
    background-image: url("../picture/Game.png"); 
}
/* 水果 */
.category_fruite_icon {
    background-image: url("../picture/Fruite.png"); 
}
/* 咖啡屋 */
.category_cafe_icon {
    background-image: url("../picture/Cafe.png"); 
}
/* 啤酒屋 */
.category_beer_icon {
    background-image: url("../picture/Beer.png"); 
}
/* 球类运动 */
.category_ballgames_icon {
    background-image: url("../picture/BallGames.png"); 
}
/* 美术艺术 */
.category_art_icon {
    background-image: url("../picture/Art.png"); 
}
/* 水管疏通 */
.category_piping_icon {
    background-image: url("../picture/Piping.png"); 
}
/* 公园美景 */
.category_park_icon {
    background-image: url("../picture/Park.png"); 
}
/* 家电维修 */
.category_room_modify_icon {
    background-image: url("../picture/RoomModify.png"); 
}
/* 工作应聘 */
.category_jobs_icon {
    background-image: url("../picture/Jobs.png"); 
}
/* 房屋租赁 */
.category_house_renting_icon {
    background-image: url("../picture/HouseRenting.png"); 
}
/* 家具交易 */
.category_furniture_icon {
    background-image: url("../picture/Furniture.png"); 
}
/* 牙齿护理 */
.category_dentist_icon {
    background-image: url("../picture/Dentist.png"); 
}
/* 卡牌桌游 */
.category_card_game_icon {
    background-image: url("../picture/CardGame.png"); 
}
/* 丽人 */
.category_beauty_icon {
    background-image: url("../picture/Beauty.png"); 
}
/* 早教 */
.category_baby_education_icon {
    background-image: url("../picture/BabyEducation.png"); 
}
/* 电脑维护 */
.category_computer_icon {
    background-image: url("../picture/Computer.png"); 
}
/* 全球化 */
.category_global_icon {
    background-image: url("../picture/Global.png"); 
}
/* 足球 */
.category_footbal_icon {
    background-image: url("../picture/Football.png"); 
}
/* 礼物 */
.category_gift_icon {
    background-image: url("../picture/Gift.png"); 
}
/* 在售 */
.category_onsale_icon {
    background-image: url("../picture/OnSale.png"); 
}
/* 奶酪 */
.category_cheese_icon {
    background-image: url("../picture/Cheese.png"); 
}
/* 面包 */
.category_bread_icon {
    background-image: url("../picture/Bread.png"); 
}
/* 披萨 */
.category_pizza_icon {
    background-image: url("../picture/Pizza.png"); 
}
/* 蘑菇 */
.category_mushroom_icon {
    background-image: url("../picture/Mushroom.png"); 
}
/* 冰箱 */
.category_frigerator_icon {
    background-image: url("../picture/Frigerator.png"); 
}
/* 鱼 */
.category_fish_icon {
    background-image: url("../picture/Fish.png"); 
}
/* 棒棒糖 */
.category_candy_icon {
    background-image: url("../picture/Candy.png"); 
}
/* 车库 */
.category_carstorage_icon {
    background-image: url("../picture/CarSaving.png"); 
}

/* 传送 */
.category_transform_icon {
    background-image: url("../picture/Tansform.png"); 
}
/* 背包 */
.category_bag_icon {
    background-image: url("../picture/Bag.png"); 
}
/* 吉他 */
.category_guitar_icon {
    background-image: url("../picture/Guitar.png"); 
}
/* 柠檬 */
.category_lemon_icon {
    background-image: url("../picture/Lemon.png"); 
}
/* 雨伞 */
.category_umbrella_icon {
    background-image: url("../picture/Umbrella.png"); 
}
/* 太阳 */
.category_sunny_icon {
    background-image: url("../picture/Sunny.png"); 
}
/* 礼品店 */
.category_giftshopl_icon {
    background-image: url("../picture/GiftShop.png"); 
}
/* 卡车 */
.category_truck_icon {
    background-image: url("../picture/Truck.png"); 
}
/* 轮渡 */
.category_carseatravel_icon {
    background-image: url("../picture/CarSeaTravel.png"); 
}
/* 皮划艇 */
.category_boating_icon {
    background-image: url("../picture/Boating.png"); 
}
/* 营火 */
.category_onfire_icon {
    background-image: url("../picture/OnFire.png"); 
}
/* 货运 */
.transit_icon {
    background-image: url("../picture/Transit.png"); 
}
/* 货车 */
.category_van_icon {
    background-image: url("../picture/Van.png"); 
}
/* 魔法 */
.category_magic_icon {
    background-image: url("../picture/Magic.png"); 
}
/* 键盘 */
.category_keyboards_icon {
    background-image: url("../picture/Keyboards.png"); 
}
/* 飞机 */
.category_plane_icon {
    background-image: url("../picture/Plane.png"); 
}
/* 篮球 */
.category_basketball_icon {
    background-image: url("../picture/Basketball.png"); 
}
/* 证书 */
.category_rewards_icon {
    background-image: url("../picture/Rewards.png"); 
}
/* Nail1 */
.category_nail1_icon {
    background-image: url("../picture/Nail1.png"); 
}
/* Nail2 */
.category_nail2_icon {
    background-image: url("../picture/Nail2.png"); 
}
/* 日本女 */
.category_japanwoman_icon {
    background-image: url("../picture/JapanWomen.png"); 
}
/* 印度茶壶 */
.category_indiacup_icon {
    background-image: url("../picture/IndiaCup.png"); 
}
/* 扇子 */
.category_fan_icon {
    background-image: url("../picture/Fan.png"); 
}
/* 灯笼 */
.category_denglong_icon {
    background-image: url("../picture/DengLong.png"); 
}
/* 面桶 */
.category_bowl_icon {
    background-image: url("../picture/Bowl.png"); 
}
/* 汉堡 */
.category_burger_icon {
    background-image: url("../picture/Burger.png"); 
}
