.transit_price_lookup_area {
    display: flex; align-items: center; justify-content: center;
    width: auto; height: auto;
}
.transit_price_lookup_text {
    color: white; margin-right: 5px; margin-left: 5px;
}
/* .transit_price_lookup_text_m {
    color: white; margin-right: 5px; margin-left: 5px;
    width: 12.5vw; min-width: 12.5vw;
} */
.transit_price_lookup_button {
    background: deepskyblue;
    display: flex; align-items: center; justify-content: center;
    width: 80px; height: 30px;
    margin-left: 10px; margin-right: 10px;
    border-radius: 5px;
    color: black;
}
.transit_price_lookup_result {
    background: white;
    display: flex; align-items: center; justify-content: center;
    width: auto; height: 35px;
    margin-left: 5px; margin-right: 10px;
    padding-left: 15px; padding-right: 15px;
    color: black; border-style: solid; border-width: 1px; border-color: black;
}
.transit_price_lookup_result_m {
    display: flex; align-items: center; justify-content: center;
    width: auto; height: 100%; margin-left: 2vw;
    color: white; font-size: 3.5vw;
}
.transit_price_text_m {
    display: flex; align-items: center;
    width: auto; height: auto; margin-right: 0vw;
    font-size: 3.5vw; min-width: 8vw;
}
.transit_price_lookup_input_m {
    width: 25vw; height: 9vw;
    margin-left: 3.5vw; margin-right: 1vw;
    font-size: 3.5vw;
}
.transit_price_button_m {
    display: flex; align-items: center; justify-content: center;
    width: 24vw; height: 9vw;
    color: white; font-size: 3.5vw; font-weight: bold;
    margin:1.5vw;
}
.transit_price_title_m {
    display: flex; align-items: center;
    width: 15vw; height: 9.2vw;
    font-size: 3.5vw;
}
.transit_type_dropdown_button {
    width: 200px; padding-left: 5px; padding-right: 5px;
    display: flex; align-items: center; justify-content: center;
}
.transit_unit_dropdown_button {
    width: 70px; min-width: 70px; margin: 0; padding: 0; height: auto;
    display: flex; align-items: center; justify-content: center;
}
.transit_expire_date_pickup {
    width: 200px; padding: 0; margin:0; 
}
.transit_price_edit_title {
    font-weight: bold; min-width: 65px; 
}
.transit_vip_price_edit_title {
    font-weight: bold; min-width: 125px; 
}
.transit_type_filter_dropdown_button {
    width: 100px; max-width: 100px;
    display: flex; align-items: center; justify-content: center;
}
.transit_unit_input {
    width: 100px;
}
.transit_price_title {
    width: 65px; min-width: 65px; color: white; font-weight: bold; margin: 10px;
}