.sidebar_container {
    position: fixed; top: 0; right: 0;
    overflow: hidden; z-index: 100;
}
.sidebar_logo_btn {
    /* background-color: white; */
    background-size: cover; background-repeat: no-repeat;
    width: 10vw; height: 10vw; min-width: 10vw; min-height: 10vw;
    margin-top:2vw; margin-right: 2.5vw;
    /* border-radius: 50px; border-style: solid; border-width: 2px; border-color: white; */
    
}
.sidebar_contents {
    background: white;
    /* background: rgba(0, 155, 255, 0.05);
     */
}
.sidebar_user_button {
    display: flex; align-items: center;
    height: 70px; opacity: 1;
}
.sidebar_avatar {
    background-color: white;
    width: 45px; height: 45px;min-width: 45px; min-height: 45px;
    margin: 0; margin-left:20px; margin-top: 15px;
    background-size: cover; background-repeat: no-repeat;
    border-radius: 50px;
}
.sidebar_user_name {
    font-weight: bold; font-size: 18px; text-align: left;
    width: 160px; height: 30px;
    margin: 0; margin-top: 17.5px; margin-left: 15px;
}
.sidebar_goto_icon {
    width: 30px; height: 30px;
    margin: 0; margin-top: 25px; margin-left: 5px;
    opacity: 1;
}
.sidebar_user_collects_area {
    display: flex; align-items: center;
    height: 80px; padding: 0; padding-left: 5px;
}
.sidebar_user_collects_item {
    width: 70px; height: 60px;
    margin: 0; margin-left: 0px; margin-right: 10px;
    opacity: 1;
}
.sidebar_user_collects_num {
    color:black; display: flex; align-items: center;
    width: 70px; height: 30px;
    padding: 0; padding-top: 6px;
}
.sidebar_user_collects_icon {
    width: 16px; height: 16px;
    margin:0; margin-left: 20px; margin-right: 2px;
    background-size: cover; background-repeat: no-repeat;
}
.sidebar_user_collects_text {
    color: black; font-weight: bold; text-align: center;
    width: 70px; height: 30px;
}
.sidebar_item {
    background: none; opacity: 0.9;
    display: flex; align-items: center;
    margin: 10px; padding: 7.5px;
}
.sidebar_item_text {
    color: black; font-size: 15px; margin-top: 5px; margin-left: 5px;
}
.sidebar_item:active, .sidebar_item:focus {
    background: lightsalmon; opacity: 1;
}
.sidebar_item_icon {
    background-size: cover; background-repeat: no-repeat;
    width: 25px; height: 25px; opacity: 1;
    margin: 0; margin-left: 5px; margin-right: 10px;
}
.sidebar_bottom_area {
    background-color: white; height: 600px;
    background-size: cover; background-repeat: no-repeat;
}
.sidebar_divider {
    background-color: darkgray;
    margin: 0; padding: 0;
}
.sidebar_divider_v {
    width: 0.5px; height: 11vw;
    padding : 0.5px; opacity: 0.5;
}
.sidebar_divider_h {
    width: 90%; height: 1px;
    margin: auto; opacity: 0.75;
}