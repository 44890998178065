/* 页面总体设置 */
.sooning_page_web_title_slogan {
    background-image: url("../../picture/SooningLogo.jpg"); 
    background-size: cover; background-repeat: no-repeat;
    width: auto; min-width:175px; height: 70px; min-height: 70px;
    margin-left: 15px;
    display: flex; align-items: center;justify-content: center;
}
.sooning_page_web_title_slogan:hover {
    cursor: pointer;
}
.sooning_page_web_title_slogan:active {
    opacity: 0.75;
}
.sooning_page_web_center_area {
    background: rgba(255, 166, 0, 0.5);
    background-size: 1444.444444px 650px;
    width: 100vw; min-width: 1444.444444px; height:650px;
    display: flex; align-items: center;
}
.sooning_center_home_page {
    width: 100%; height: 100%;
}
.sooning_center_home_title{
    width: 100%; height: auto;
    margin-bottom: 20px; margin-top: 80px;
    color:black ; font-weight: bold; font-size: 40px;
    display: flex; align-items: center; justify-content: center;
}
.sooning_center_home_subtitle{
    width: 100%; height: auto;
    color: rgba(0, 0, 0, 0.5); font-size: 20px;
    margin-bottom: 20px;
    display: flex; align-items: center; justify-content: center;
}
.sooning_website_page {
    background: rgb(255, 255, 255);
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh; overflow: hidden; overflow-y: auto;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
/* 顶部栏排版和按钮 */
.sooning_website_page_title_area {
    background: white;
    width: 100vw; height: 100px;
    display: flex; align-items: center;
}
.sooning_title_menu_row {
    width: auto; height: 100px; margin-left: 25px;
    display: flex; align-items: center; justify-content: center;
}
.sooning_title_menu_button {
    background: orange;
    width: 160px; height: 50px; margin:15px; padding-left: 15px;
    display: flex; align-items: center;
    color: black; font-weight: bold; font-size: 20px;
    border-radius: 5px; opacity: 0.75;
}
.sooning_title_menu_button_select {
    background: orange; opacity: 1;
    color: black; 
}
.sooning_title_menu_button:hover {
    opacity: 0.9; cursor: pointer;
}
.sooning_title_menu_button:active {
    opacity: 0.75;
}
.sooning_title_user_name {
    width: auto; min-width: 120px; height: 60px; margin-right: 50px;
    display: flex; align-items: center;
    color: darkorange; font-weight: bold; font-size: 17.5px;
}
.sooning_title_user_name {
    color: orange; margin-left: auto; margin-right: 50px;
    text-decoration: underline; font-size: 20px;
}
.sooning_title_user_name:hover {
     color: rgba(0, 0, 0, 0.75); cursor: pointer;
}
.sooning_title_user_name:active {
    opacity: 0.5;
}
/* 中心区域 */
.sooning_center_search_button {
    background: orange;
    width: 100px; height: 40px; min-width: 100px; min-height: 35px;
    margin-left:10px;
    color: black; font-weight: bold; font-size: 20px;
    display: flex; align-items: center; justify-content: center;
    border-radius: 5px; border-style: solid; border-color: black; border-width: 2px;
}
.sooning_center_search_button:hover {
    cursor: pointer;
}
.sooning_center_search_button:active {
    opacity: 0.5;
}
/* 底部栏信息 */
.sooning_qrcode {
    background: orange;
    width: 120px; height: 120px;
    min-width: 120px; min-height: 120px;
    background-image: url("../../picture/XiyoujiQRCode.png"); 
    background-size: cover; background-repeat: no-repeat;
    margin-left: 180px;  margin-top: 5px;
}
.sooning_qrcode_tip {
    color: orange; font-weight: bold;
    margin-left: 125px; margin-top: 10px;
}

/******** 登录页面 ********/
.login_page_web_login_form_sooning {
    background: white;
    width: 500px; min-width:500px; height: auto;
    margin-left: auto; margin-right: auto; padding-bottom: 15px;
    border-radius: 5px;
}
.login_page_web_center_area_sooning {
    background:orange;
    background-size: 1444.444444px 650px;
    width: 100vw; min-width: 1444.444444px; height:650px;
    display: flex; align-items: center;
}

/******** 用户后台 ********/
.sooning_manage_top_bar {
    background: #000f29;
    display: flex; align-items: center;
    position: fixed; top: 0; left: 0; z-index: 100;
    width: 100vw; height: 54px;
}
.sooning_return_to_home_button {
    color: white; font-weight: bold; 
    height: 46px; max-height: 46px;
    margin-bottom:4px;
}
.sooning_return_to_home_button:active {
    opacity: 0.8;
}
.sooning_return_to_home_button:hover {
    background:orange; color: white;
    cursor: pointer;
}

/******** 用户后台：用户信息 ********/
.user_info_sooning_title {
    width: 900px; height: auto;
    color: darkorange; font-weight: bold; font-size: 17.5px;
    display: flex; align-items: center; 
    padding-bottom: 5px; margin-left: 25px; margin-bottom: 5px;
    border-bottom-style: solid; border-bottom-color: orange; border-bottom-width: 1px;
}
.user_info_sooning_area {
    width: 900px; height: auto; min-height: 100px;
    margin-left: 25px; margin-bottom: 50px;
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer */
    -khtml-user-select: text; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: text; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: text; /* Disable Android and iOS callouts*/
}
.user_info_sooning_row {
    display: flex; align-items: center; 
    color: rgba(0, 0, 0, 0.75); font-size: 15px; font-weight: bold;
    margin-bottom: 15px;
}
.user_info_sooning_column {
    display: flex; align-items: center; 
    width: 250px; margin-right: 100px; margin-left: 20px; margin-top: 20px; 
}
.user_info_sooning_address_area {
    background: rgba(255, 166, 0, 0.25);
    border-radius: 5px; margin-left: 0px;
    padding: 0px; padding-left: 20px; padding-top: 15px; padding-bottom: 10px;
    width: 100%; height: auto; min-height: 100px;
}
.user_info_sooning_copy_button {
    display: flex; align-items: center; justify-content: center;
    background:orange; color: white; font-weight: bold;
    width: 90px; height: 35px; border-radius: 5px;
    margin-left: 20px;
}
.user_info_sooning_copy_button:hover {
    cursor: pointer;
}
.user_info_sooning_copy_button:active {
    opacity: 0.75;
}
.sooning_logout_btn {
    width: 100px; height: 35px;
    border-radius: 5px; font-size: 15px;
    display: flex; align-items: center; justify-content: center;
    background: darkorange; font-weight: bold;
    margin-left: 15px; margin-right: 15px;
}
.sooning_new_button_general_style {
    background: darkorange;
    color: white; font-weight: bold;
}
.sooning_express_item_info_block {
    background:rgba(255, 140, 0, 0.15);
    width: 96%; height: auto;
    margin: auto; margin-bottom: 7.5px; margin-top: 7.5px; padding-top: 10px;
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 1px;
}
.sooning_express_item_info_block_light {
    background:rgba(255, 140, 0, 0.075);
    width: 96%; height: auto;
    margin: auto; margin-bottom: 7.5px; margin-top: 7.5px; padding-top: 10px;
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 1px;
}
.sooning_export_button_style {
    background: darkorange;
    color: white; font-weight: bold;
}
.sooning_export_button_style:active{
    background: orange;
}
.sooning_express_enter_info_list_title_tracking_no {
    background: rgba(255, 140, 0, 0.1);
    width: 275px; height: 50px;
    display: flex; align-items: center; justify-content: center;
    border-right-style: solid; border-right-color: rgba(0, 0, 0, 0.1); border-right-width: 0.5px;
}
.sooning_auto_order_upload_file_area {
    background: rgba(255, 140, 0, 0.1);
    width: 100%; height: auto;
    margin-bottom: 15px; padding: 10px; padding-left: 20px; padding-right: 0px;
}
.sooning_package_combine_info_block {
    background: rgba(255, 140, 0, 0.1);
    padding-top: 15px; padding-left: 0px; padding-right: 0px; padding-bottom: 1px; margin-bottom: 15px;
    border-bottom-style:solid; border-bottom-color:rgba(0, 0, 0, 0.05); border-bottom-width: 0.5px;
}

/* 菜单选中按钮样式 */
/* .ant-menu-item-selected {
    font-weight: bold !important;
    background-color: darkorange !important;
} */