.webpage_title_menu_row {
    width: auto; height: 100px; margin-left: 25px;
    display: flex; align-items: center; justify-content: center;
}
.webpage_title_menu_button {
    background: lightseagreen;
    width: 160px; height: 50px; margin:15px; padding-left: 15px;
    display: flex; align-items: center;
    color: white; font-weight: bold; font-size: 20px;
    border-radius: 5px; border-style: solid; border-color: rgba(255, 255, 255, 0.5); border-width: 2px;
}
.webpage_title_menu_button_select {
    background: darkcyan;
    border-color: rgba(0, 0, 0, 0.25);
}
.webpage_title_menu_button:hover {
    color: rgba(0, 0, 0, 0.75); cursor: pointer;
}
.webpage_title_menu_button:active {
    opacity: 0.5;
}

.webpage_title_menu_button_xiyouji {
    background: darksalmon;
    width: 160px; height: 50px; margin:15px; padding-left: 15px;
    display: flex; align-items: center;
    color: white; font-weight: bold; font-size: 20px;
    border-radius: 5px;
    /* border-style: solid; border-color: rgba(255, 255, 255, 0.5); border-width: 2px; */
}
.webpage_title_menu_button_select_xiyouji {
    background: darksalmon;
    /* border-color:black; */
    color: black; 
}
.webpage_title_menu_button_xiyouji:hover {
    opacity: 0.9; cursor: pointer;
}
.webpage_title_menu_button_xiyouji:active {
    opacity: 0.75;
}

.webpage_title_user_avatar {
    background: lightsalmon;
    background-image: url("../../picture/MouthingLogo.png"); 
    background-size: cover;background-repeat: no-repeat;
    width: 60px; height: 60px; min-width: 60px; min-height: 60px; 
    margin-left: auto; margin-right: 10px; padding:10px;
    display: flex; align-items: center; justify-content: center;
    border-radius: 100px; border-style: solid; border-color: rgba(0, 0, 0, 0.25); border-width: 2px;
}
.webpage_title_user_avatar:active {
    opacity: 0.5;
}
.webpage_title_user_avatar:hover {
    background: lightseagreen;
    background-image: url("../../picture/MouthingLogoGreen.png"); 
    background-size: cover;background-repeat: no-repeat;
    cursor: pointer;
}
.webpage_title_user_name {
    width: auto; min-width: 120px; height: 60px; margin-right: 50px;
    display: flex; align-items: center;
    color: darkcyan; font-weight: bold; font-size: 17.5px;
}
.webpage_title_user_name_xiyouji {
    color: darksalmon; margin-left: auto; margin-right: 50px;
    text-decoration: underline; font-size: 20px;
}
.webpage_title_user_name:hover {
     color: rgba(0, 0, 0, 0.75); cursor: pointer;
}
.webpage_title_user_name:active {
    opacity: 0.5;
}
.web_center_area_bgp {
    background-image: url("../../picture/WebsiteMainBGP.jpg"); 
    background-size: cover; background-repeat: no-repeat;
    width: auto; min-width: 1444.444444px; height: 650px;
}
.web_center_area_bgp_info {
    background-image: url("../../picture/WebsiteMainBGP1.jpg"); 
}
.web_center_area_bgp_xiyouji {
    background-image: url("../../picture/WebsiteMainBGPXiYouJi.jpg"); 
    margin-left: auto; margin-right: auto;
}
.web_center_area_bgp_info_xiyouji {
    background-image: url("../../picture/WebsiteMainBGPXiYouJi.jpg"); 
}
.web_center_area_bgp_left_layout {
    position: relative; left: -500px; top: 0px;
}
.web_center_black_cover {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw; height: 650px;
    display: flex; align-items: center;
    justify-content: center;
    position: fixed; left: 0; top: 100px;
}
.web_center_transit_function_area {
    background: white;
    width: auto; height: 90%; border-radius: 2.5px; 
    display: flex; align-items: center;
}
.web_center_transit_function_vertical_area {
    background: white;
    width: 390px; min-width: 390px; height: 90%; margin-left: 27.5px; margin-right: 27.5px;
    border-radius: 2.5px; border-style: solid; border-width: 2px; border-color: rgba(0, 0, 0, 0.2);
}
.web_center_transit_function_horizontal_area {
    background: white;
    width: 525px; min-width: 525px; height: 90%;
    border-radius: 2.5px; border-style: solid; border-width: 2px; border-color: rgba(0, 0, 0, 0.2);
}
.web_center_transit_fucntion_title {
    /* background: lightblue; */
    width: 100%; height: 60px;
    color: black; font-size: 20px; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
    border-bottom-style: dashed; border-bottom-width: 2px; border-bottom-color: darkcyan;
}
.web_center_transit_fucntion_row {
    display: flex; align-items: center;
    width: 90%; height: auto; min-height: 50px; margin-left: 5%; margin-top: 15px; padding-left: 5px;
    color: black; font-size: 17.5px; font-weight: bold;
    border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: darkcyan;
}

.web_center_pickup_reminds {
    display: flex; align-items: center;
    height: auto; min-height: 25px; margin-left: 5%; margin-top: 5px; margin-bottom: 10px; padding-left: 5px;
    color: lightcoral; font-size: 17.5px; font-weight: bold;
}

.web_center_transit_fucntion_copy_button {
    background: darkcyan;
    color: white; font-size: 17.5px;
    display: flex; align-items: center; justify-content: center;
    width: 70px; min-width:70px; height: 30px; margin-left: auto; margin-right: 5px;
    border-radius: 5px;
}
.web_center_transit_fucntion_copy_button:hover {
    cursor: pointer;
}
.web_center_transit_fucntion_copy_button:active {
    opacity: 0.5;
}
.web_center_transit_function_copy_all {
    background: darkcyan;
    color: white; font-size: 17.5px; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
    width: 125px; min-width:125px; height:40px; margin: 20px; margin-bottom: 15px; margin-left: auto; margin-right: auto;
    border-radius: 5px;
}
.web_center_transit_function_copy_all:hover {
    cursor: pointer;
}
.web_center_transit_function_copy_all:active {
    opacity: 0.5;
}
.web_center_transit_function_tips {
    width: 90%; height:100px; margin-left: 5%;
    color: rgba(0, 0, 0, 0.5); font-size:16px; padding-top:15px; padding-left:5px;
    border-top-style:solid; border-top-width: 1px; border-top-color: rgba(0, 0, 0, 0.25);
}
.web_center_express_order_input_area {
    width: 90%; height: auto; max-height: 47.5%;
    margin-left: 5%; margin-top: 15px; margin-bottom: 15px;
    overflow-x: hidden; overflow-y: auto;
    border: none; outline: none;
    padding-bottom: 10px;
    /* border-bottom-style:solid; border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-width: 1px; */
}
.web_center_express_order_submit_button {
    background: darkcyan;
    color: white; font-size: 17.5px; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
    width: 120px; min-width:120px; height:40px;
    margin-left: auto; margin-right: 5%;
    border-radius: 5px;
}
.web_center_express_order_submit_button:hover{
    cursor: pointer;
}
.web_center_express_order_submit_button:active{
    opacity: 0.5;
}
.web_center_exress_order_submit_tip_area {
    /* background: rgba(32, 178, 171, 0.171); */
    width: 70%; height:120px; margin-left: 5%;
    color: rgba(0, 0, 0, 0.5); font-size:16px; padding-left:5px;
    position: relative; top: -40px;
    /* border-top-style:solid; border-top-width: 1px; border-top-color: rgba(0, 0, 0, 0.25); */
}
.web_center_exress_order_submit_tip_row {
    width: 100%; height:auto;
    color: rgba(0, 0, 0, 0.5); font-size:16px;
    display: flex; align-items: center;
}
.web_center_exress_order_submit_tip_link{
    color: darkcyan; font-size:16px; font-weight: bold; text-decoration: underline;
    margin-left: 5px;
    display: flex; align-items: center;
}
.web_center_exress_order_submit_tip_link:hover {
    cursor: pointer;
}
.web_center_exress_order_submit_tip_link:active {
    opacity: 0.5;
}
.web_center_order_submit_dlg_tip {
    width: 100%; height: auto; margin-bottom: 2.5%;
    color: darkcyan; font-weight: bold; font-size: 17.5px;
}
.web_center_express_order_info_area {
    width: 100%; height: auto;
    margin-bottom: 2%; padding-bottom: 1%;
    border-bottom-style: solid; border-bottom-color: darkcyan; border-bottom-width: 1px;
}
.web_center_express_order_info_row{
    display: flex; align-items: center;
    margin-bottom: 1%;
}
.web_center_express_info_title {
    width: 12.5%; min-width:75px;
    font-size: 17.5px; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
    margin-right: 2%;
}
.web_center_express_info_contents{
    width: 80%; font-size: 17.5px; font-weight: bold;
}
.web_info_change_confirm_button {
    background: darkcyan;
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 17.5px; font-weight: bold;
    width: 100px; height: 40px; margin-right: 0; margin-top:1%;
    border-radius: 5px;
}
.web_info_change_confirm_button:active {
    opacity: 0.5;
}
.web_info_change_cancel_button {
    background: lightgray;
    display: flex; align-items: center; justify-content: center;
    margin-left: auto; margin-right: 10px; margin-top:1%;
    color: white; font-size: 17.5px; font-weight: bold;
    width: 100px; height: 40px;
    border-radius: 5px;
}
.web_info_change_cancel_button:active {
    opacity: 0.5;
}
.web_pickup_location_map {
    color: gray; text-align: center; font-weight: bold; font-size: 15px;
    /* background: lightseagreen; */
    width: 90%; height: 40%; margin: 0; margin-top: 0px; margin-left: 19px;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
    overflow: hidden;
}

.web_center_order_list_area {
    width: 800px; height: 92%; 
    margin-left: 20px; margin-right: 20px; padding: 0px; padding-bottom: 25px; padding-top: 5px;
    border-radius: 2.5px;
    border-style: solid; border-width: 2px; border-color: rgba(0, 0, 0, 0.2);
    overflow: auto;
}
.web_center_order_details_title {
    width: 100%; height: 30px;
    display: flex; align-items: center;
}
.web_center_order_details_title_left {
    width: 80%; font-size: 20px; font-weight: bold;
    margin-left: 0; margin-right: 2%;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.web_center_order_details_title_right {
    /* background: lightcoral; */
    margin-left: auto; margin-right: 0;
    font-size: 20px; text-align: right;
    display: flex; align-items: center; 
    width: auto; min-width:100px; height: 50px;
    padding: 5px;
}
.web_center_order_details_area {
    color: rgba(0, 0, 0, 0.75); font-size: 17.5px;
    width: 100%; height: auto;
    margin:0; padding:0; margin-bottom: 0; margin-left: 5%;
}
.web_center_order_details_text{
    width: 80%;
    display: flex; align-items: center;
    margin-bottom: 2.5%;
}
.web_center_transit_tracking_no{
    width: 100%; height: 45px; height: auto;
    margin:0; margin-top: 5px; margin-bottom: 15px;
    display: flex; align-items: center;
    font-size: 18px; padding-left: 2%;
}
.web_center_view_order_details_button {
    margin-left: auto; margin-right: 2%; color: darkcyan; font-weight: bold;
}
.web_center_view_order_details_button:hover {
    cursor: pointer;
}
.web_center_view_order_details_button:active{
    opacity: 0.5;
}
.web_center_empty {
    width: 97.5%; height: 75%;
    padding-left: auto; padding-right: auto; padding-top: 150px; padding-bottom: auto;
    color: rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 18px;
}

.web_center_home_page {
    background: rgba(0, 0, 0, 0.5);
    width: 100%; height: 100%;
}
.web_center_home_title{
    width: 100%; height: auto;
    margin-bottom: 20px; margin-top: 80px;
    color: white; font-weight: bold; font-size: 40px;
    display: flex; align-items: center; justify-content: center;
}
.web_center_home_subtitle{
    width: 100%; height: auto;
    color: white; font-size: 20px;
    margin-bottom: 20px;
    display: flex; align-items: center; justify-content: center;
}
.web_center_home_title_xiyouji{
    font-size: 60px; width: 550px; margin-left: auto; margin-right: auto; padding: 10px; margin-top: 125px;
    border-top-style: solid; border-top-width: 3px; border-top-color: white;
    border-bottom-style: solid; border-bottom-width: 3px; border-bottom-color: white;
}
.web_center_home_subtitle_xiyouji{
    font-size: 35px; padding-top: 20px;
}
.web_center_home_search_area {
    width: 100%; height: 100px;
    display: flex; align-items: center; justify-content: center;
}
.web_center_search_button {
    background: darkcyan;
    width: 100px; height: 40px; min-width: 100px; min-height: 35px;
    margin-left:10px;
    color: white; font-weight: bold; font-size: 20px;
    display: flex; align-items: center; justify-content: center;
    border-radius: 5px; border-style: solid; border-color: rgba(255, 255, 255, 0.5); border-width: 2px;
}
.web_center_search_button_xiyouji {
    background: darksalmon;
}
.web_center_search_button:hover {
    cursor: pointer;
}
.web_center_search_button:active {
    opacity: 0.5;
}
.web_center_modify_button{
    background: darkcyan;
    width: 60px; height: 30px;
    display: flex; align-items: center; justify-content: center;
    border-radius: 5px; margin-left: 20px;
    color: white; font-weight: bold; font-size: 15px;
}
.web_center_modify_button:hover {
    cursor: pointer;
}
.web_center_modify_button:active {
    opacity: 0.5;
}
.webpage_dropdown_menu_button {
    background: darkcyan;
    width: 125px; height: 110%;
    margin-left: 20px; padding-left: 30px; padding-right: 10px;
    font-weight: bold; font-size: 17.5px; color: white;
    display: flex; align-items: center; justify-content: center;
    border-radius: 5px;
}
.webpage_dropdown_menu_item {
    width: 100%; height: 30px; font-size: 17.5px;
    display: flex; align-items: center;
}
.webpage_title_logout {
    width: auto; min-width: 50px; height: auto; margin: 5px;
    display: flex; align-items: center;
    color: white; font-size: 15px; text-decoration: underline;
}
.webpage_title_logout:hover {
    cursor: pointer;
}
.webpage_title_logout:active {
    opacity: 0.5;
}
.webpage_delivery_dropdown_text {
    background: lightblue;
    width: 80%;
    text-align: left; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.webpage_delivery_filter_dropdown_button {
    width: 80%; display: flex; align-items: center;
}

/* Lekon WebPage */

.webpage_lekon {
    background: white;
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh; max-height:100vh; overflow: hidden; overflow-y: auto;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.webpage_jeton {
    background: white;
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 105vw; height: 100vh; max-height:100vh; overflow: hidden; overflow-y: auto;
    margin: 0; padding: 0; opacity: 1;
    position: fixed; left: 0px; top: 0px;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.webpage_lekon_title_area {
    background: rgba(255, 255, 255, 0.8);
    width: 100%; height: 100px; padding-right: 3.5vw;
    display: flex; align-items: center; justify-content: center;
    position: fixed; top: 0px; left: 0px; z-index: 100;
}
.webpage_jeton_title_area {
    background: white;
    width: 100%; height: 100px; padding-left: 1vw;
    display: flex; align-items: center;
    position: fixed; top: 0px; left: 0px; z-index: 100;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.2);
}
.webpage_title_icon_lekon {
    background-image: url("../../picture/LekonLogo.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: auto; min-width:86px; height: 86px; min-height: 86px;
    margin-right: 0vw;
    display: flex; align-items: center;justify-content: center;
}
.webpage_title_icon_jeton {
    background-image: url("../../picture/JetonMediaTechnologyLogo.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: auto; min-width:464px; height: 86px; min-height: 86px;
    margin-right: 0vw;
    display: flex; align-items: center;justify-content: center;
}
/* .webpage_title_text_jeton {
    color: orange; font-weight: bold; font-size: 50px;
    font-style: italic;
} */
.webpage_title_menu_button_lekon {
    background: skyblue;
    width: 200px; min-width: 200px; height: 65px; margin:15px; margin-left: 2vw; padding-left: 0px;
    display: flex; align-items: center;justify-content: center;
    color: white; font-weight: bold; font-size: 25px;
    border-radius: 5px;
}
.webpage_title_menu_button_select_lekon {
    color: black; 
}
.webpage_title_menu_button_lekon:hover {
    opacity: 0.8; cursor: pointer;
}
.webpage_title_user_name_lekon{
    color: black; margin-left: auto; margin-right: 50px;
    text-decoration: underline; font-size: 20px;
}
.webpage_lekon_center_area {
    background: white;
    width: 100%; height: 100vh; 
}
.webpage_jeton_center_area {
    background: white;
    width: 100vw; height: 100vh; 
    overflow: hidden; overflow-y:auto;
}
.webpage_background_vedio {
   background: lightskyblue;
   width: 100vw; height: auto;
}
.webpage_area_title1_lekon{
    color: white; font-size: 90px; font-weight: bold;
    width: 1000px; height: 100px; margin-left: auto; margin-right: auto; 
    display: flex; align-items: center;justify-content: center;
    margin-top: 20px; position:relative; top: -45vw; left: 0px;
}
.webpage_search_area_lekon {
    width: 100%; height: 200px;
    display: flex; align-items: center; justify-content: center;
    position:relative; top: -45vw; left: 0px;
    margin-top: 2.5vw;
}
.webpage_search_input_lekon {
    width: 450px; height: 60px;
}
.webpage_search_button_lekon {
    background: skyblue;
    width: 60px; height: 60px;
    color: white; font-weight: bold; font-size: 25px;
    display: flex; align-items: center; justify-content: center;
    /* border-radius: 5px; border-style: solid; border-color: rgba(255, 255, 255, 0.5); border-width: 2px; */
}
.webpage_search_button_lekon:hover {
    cursor: pointer;
}
.webpage_search_button_lekon:active {
    opacity: 0.8;
}
.webpage_lekon_main_contents_area {
    /* background: lightpink; */
    width: 70vw; height: calc(100vh - 100px); height: auto;
    margin-left: auto; margin-right: auto; padding-bottom: 25px;
    position:relative; top: -486px; left: 0px;
    display: flex; align-items: center; 
    border-bottom-style: solid; border-bottom-color:rgba(0, 0, 0, 0.25); border-bottom-width: 1px;
}
.webpage_lekon_contact_area{
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: auto; 
    margin-top: 0px; margin-left: auto; margin-right: auto; padding-bottom: 300px;
    position:relative; top: -450px; left: 0px;
    border-bottom-style: solid; border-bottom-color:rgba(0, 0, 0, 0.25); border-bottom-width: 1px;
}
.webpage_lekon_sub_contents_area_1 {
    /* background: lightskyblue; */
    width: 45%; height: 100%;
}
.webpage_lekon_sub_contents_area_2 {
    /* background: lightsalmon; */
    width: 27.5%; height: 100%;
}
.webpage_lekon_sub_contents_area_3 {
    /* background: lightsalmon; */
    width: 45%; height: 100%;
}
.webpage_area_title2_lekon{
    color: black; font-size: 45px; font-weight: bold;
    width: 70%; height: auto;
    padding: 25px;
}
.webpage_area_title3_lekon{
    color: black; font-size: 45px; font-weight: bold;
    width: auto; height: auto;
    padding: 25px;
}
.webpage_area_title4_lekon{
    color: black; font-size: 30px; font-weight: bold;
    width: 100%; height: auto; padding: 25px;
    /* margin-top: 180px; */
}
.webpage_area_text_lekon{
    color: black; font-size: 20px; font-weight: normal;
    width: 100%; height: auto; margin-left: auto; margin-right: auto; 
    padding: 25px;
}
.webpage_contents_block1 {
    background: darkslategrey;
    width: 90%; height: 15vw;color: white; font-weight: bold;
    margin-left: auto; margin-right: auto; margin-top: 50px;
    padding-top: 50px;
}
.webpage_contents_block2 {
    background: darkslateblue;
}
.webpage_contents_block3 {
    background: darkcyan; margin-top: 12.5vw;
}
.webpage_contents_block4 {
    width: 20vw; height: 20vw; margin-left:25px; margin-top: 25px;
    background: lightseagreen;
}
.webpage_contents_text1 {
    font-size: 65px; width: 100%; 
    display: flex; align-items: center; justify-content: center;
}
.webpage_contents_text2 {
    font-size: 25px; width: 100%; 
    display: flex; align-items: center; justify-content: center;
}
.webpage_location_map_lekon {
    color: gray; text-align: center; font-weight: bold; font-size: 15px;
    width: 35vw; height: 25vw; margin: 0; margin-top: 0px; margin-left: 0px;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
    overflow: hidden;
}
.webpage_lekon_info_row {
    width: auto; height: auto;
    color: rgba(0, 0, 0, 0.75); font-weight: normal; font-size: 20px;
    margin-bottom: 25px; margin-left: 25px;
}
.webpage_lekon_info_area {
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
    height: 25vw; background: rgba(135, 207, 235, 0.1);
    padding-top: 25px; margin-left: 25px;
}
.webpage_jump_position {
    background: white;
    width: 100vw; height:100px;
    position:relative; top: -486px; left: 0px;
}

.webpage_jeton_page_1 {
    background: white;
    width: 100vw; height: calc(100vh - 300px);
}
.webpage_jeton_page_2 {
    background: rgba(128, 128, 128, 0.1);
    width: 100vw; height: calc(100vh - 300px);
}
.webpage_jeton_page_3 {
    background: rgba(128, 128, 128, 0.1);
    width: 100vw; height: calc(100vh - 300px);
    padding: 5vw;padding-left: 10vw; 
    display: flex; align-items: center; justify-content: center;
}

.webage_jeton_info_row {
    display: flex; align-items: center; justify-content: center;
    width: 80vw; height: auto; min-height: 20vh;
    margin-left: 8vw;
}
.webage_jeton_text_block_title {
    width: 40vw; padding: 5vw; padding-bottom: 0vw;
    color: orange; font-weight: bold; font-size: 32.5px;
    margin-top: 40px;
}
.webage_jeton_text_block_contents {
    width: 40vw; padding: 5vw;padding-top: 0vw;
    color: darkgrey; font-weight: normal; font-size: 25px;
}
.webpage_jeton_image_block {
    width: 30vw; height: 30vw;margin-top: 40px;
}
.webpage_jeton_info_area {
    background: white;
    width: auto; height: 25vw; padding: 2.5vw;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
}
.webpage_jeton_info_area_text {
    color: rgba(0, 0, 0, 0.5); font-size: 22.5px;
}

.xiyouji_qrcode {
    background: lightsalmon;
    width: 120px; height: 120px;
    min-width: 120px; min-height: 120px;
    background-image: url("../../picture/XiyoujiQRCode.png"); 
    background-size: cover; background-repeat: no-repeat;
    margin-left: 180px;  margin-top: 5px;
}
.xiyouji_qrcode_tip {
    color: darksalmon; font-weight: bold;
    margin-left: 125px; margin-top: 10px;
}

.transitway_dropdown_menu_button {
    background: white;
    width: 155px; height:40px;
    margin-right: 10px;
    /* padding-left: 30px; padding-right: 10px; */
    /* font-weight: bold; */
    font-size: 17.5px; color: black;
    display: flex; align-items: center; justify-content: center;
    border-radius: 5px;
}

.lekon_website_mission_area{
    /* background: orange; */
    width: 100vw; height:auto; min-height:56.25vw; 
    /* margin-bottom: 10vh; */
    /* display: flex; align-items: center;  */
    /* justify-content: center; */
}
.lekon_website_mission_title {
    width: 100vw; height:auto; 
    margin-top:50px;
    font-size: 50px; color: black; font-weight: bold;
    display: flex; align-items: center; justify-content: center;
}
.lekon_website_mission_subtitle {
    width: 60vw; height:auto; 
    margin-top:27.5px; margin-left:20vw;
    font-size: 40px; color: black; font-weight: bold;
    display: flex; align-items: center;
}
.lekon_website_mission_text{
    width: 60vw; height:auto; 
    margin-top:12.5px; margin-left:20vw;
    font-size: 27.5px; color: black;
    display: flex; align-items: center;
}