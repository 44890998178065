.store_item_empty {
    color: grey;
    width: 95vw; height: 100vw;
    padding-top: 4.5vw;
}
.store_item_list {
    width: 100%; height: auto;
    margin-top: 15vw;
    overflow-x: hidden;
}
.store_item_type_title {
    display: flex; align-items: center; justify-content: center; opacity: 0.75;
    color: grey; font-weight: bold; font-size: 3.5vw; text-align: center;
    width: 80vw; height: 10vw; padding: 0; margin: 0;
    /* border-bottom-style: dashed; border-bottom-color: grey; border-bottom-width: 2px; */
}
.store_item {
    display: flex; align-items: center;
    width: 100%; height: 26vw;
    margin: 0; padding: 1.5vw; padding-bottom: 2vw; padding-top:0;
    border-bottom-style: solid; border-bottom-color: rgba(211, 211, 211, 0.25); border-bottom-width: 1px;
}
.store_item_icon {
    min-width: 18vw; min-height: 18vw;
    max-width: 18vw; max-height: 18vw;
    margin: 1vw; margin-top: 2.5vw;
    border-radius: 50px; box-shadow: 3px 3px 6px rgb(0, 0, 0, 0.5);
    background-size: auto, auto; background-repeat: no-repeat;
}
.store_item_info {
    width: 100%; height: 18vw;
    margin: 2vw; margin-right: 1vw; margin-bottom: 0;
}
.store_item_name {
    color: rgba(0, 0, 0, 0.7); font-size: 4vw; text-align: left; opacity: 0.75;
    width: 47vw; height: 6vw; margin: 0; padding:0; padding-bottom: auto;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.store_item_options {
    display: flex; align-items: center;
    color: gray; font-size: 3vw; font-weight: lighter;
    width: 46vw; height: 6vw; margin: 0; padding:0; padding-bottom: auto;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
}
.store_item_info_bottom {
    display: flex; align-items: center;
    height: 6vw; 
}
.store_item_price {
    display: flex; align-items: center;
    color: lightsalmon;  font-size: 4.5vw;
}
.store_item_add_area {
    display: flex; align-items: center; justify-content: right;
    margin: 0; margin-left: auto;
}
.store_item_add_btn {
    display: flex; align-items: center; justify-content: center;
    background: lightsalmon; opacity: 0.8;
    color: black; font-size: 3vw; text-align: center; font-weight: bold;
    width: 6vw; height: 6vw;
    border-radius: 5px;
}
.store_item_add_btn:active {
    background: skyblue; opacity: 1;
    color: black;
}
.store_item_add_num {
    display: flex; align-items: center; justify-content: center;
    color: black; font-size: 4vw; font-weight: bold;
    width: auto; min-width: 8vw; height: 8vw; padding: 1vw; padding-top: 1.5vw;
}
