.transit_tracking_page {
    /* background: lightblue; */
    color: black;
    width: 100vw; height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: auto;
    display: flex; align-items: center; justify-content: center;
}
.no_tracking{
    color: rgba(0, 0, 0, 0.5); font-size: 0.8vw;
}
.tracking_center_area{
    background: rgba(120, 85, 85, 0.05);
    /* width: auto; min-width: 50vw; max-width: 70vw; */
    width: 100vw;
    height: 100vh; max-height: 100vh;
    padding-left: 1vw; padding-right: 1vw; padding-top:0.5vw; padding-bottom: 1vw;
    overflow-x: hidden; overflow-y: auto;
    /* border-color: rgba(120, 85, 85, 1); border-width: 1px; border-style: solid; */
    /* border-radius: 0.5vw; */
}
.xiyouji_center_area {
    background: white;
}
.tracking_title {
    display: flex; align-items: center; 
    width: 18vw; height: 2vw;
    margin-left: auto; margin-right: auto;
    margin-top: 15vh;
    color: rgba(120, 85, 85, 1); font-weight: bold; font-size: 1.25vw;
    border-bottom-style: solid; border-bottom-color: rgba(120, 85, 85, 1); border-bottom-width: 1px;
}
.tracking_title_icon {
    opacity: 0.85;
    width: 1.5vw; height: 1.5vw; margin: 0.5vw; margin-bottom: 0.65vw;
    background-size: cover; background-repeat: no-repeat;
}
.tracking_no_input{
    display: flex; align-items: center;
    width: 18vw; height: 3vw;
    margin-left: auto; margin-right: auto;
    margin-top: 1.25vw; padding-left: 0.25vw;
}
.tracking_no_input_title{
    display: flex; align-items: center;
    margin-right: 0.25vw;
    width: auto; min-width: 5vw; height: 3vw;
    color: black; font-size: 0.8vw;
}
.tracking_result_area{
    display: flex; align-items: center; justify-content: center;
    width: auto; height: auto;
    margin-bottom: 2vw;
    padding: 2vw;
}
.tracking_key_info_area {
    display: flex; align-items: center;  justify-content: center;
    width: auto; margin: auto; margin-top: 1.5vw;
}
.tracking_key_info {
    display: flex; align-items: center; justify-content: center;
    margin: 0; padding: 0; margin-right: 1.5vw; 
    color: black; font-size: 0.75vw;
}
.tracking_language {
    display: flex; align-items: center;
    width:100%; padding: 1.25vw; padding-top: 0vw;
    position: fixed; top: 0.5vw; left: 0vw;
}
.tracking_logo {
    width: 2vw; height: 2vw; margin-left: 0; margin-right: 1vw;
    background-size: cover; background-repeat: no-repeat;
}
.margin_right_auto{
    margin-right: auto;
}
.tracking_vertical_steps_area {
    width: 50%; height: auto;
}
.tracking_step_row {
    display: flex;
    width: 100%; height: auto;
}
.tracking_step_title{
    width: 30%;height: auto;
    min-width: 30%; padding-right: 1vw; margin-bottom: 1vw;
    color: rgba(0, 0, 0, 0.4); text-align: right ;font-weight: bold; font-size: 0.7vw;
    border-right-style: solid; border-right-color: rgba(0, 0, 0, 0.1); border-right-width: 1.5px;
}
.tracking_step_title_null {
    border: none;
}
.tracking_step_description{
    width: 70%; height: auto;
    margin-bottom: 1vw; margin-left: 1vw;
    color: rgba(0, 0, 0, 0.75); font-size: 0.7vw;
    word-wrap:break-word; word-break:break-all; 
}


/* M站布局 */
.m_transit_tracking_page {
    background-color: rgba(120, 85, 85, 0.05);
    color: black;
    width: calc(100vw - 1px); height: calc(100vh - 1px);
    font-family: Arial, Helvetica, sans-serif;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: scroll;
    border-style: solid; border-width: 1px; border-color: black;
}
.m_tracking_top_area {
    width: 100vw; height: 14vw;
    display: flex; align-items: center;
    padding-right: 3vw;
}
.m_tracking_logo {
    width: 8vw; height: 8vw; margin-left: 3vw; 
    background-size: cover; background-repeat: no-repeat;
}
.m_tracking_title {
    width: 70vw; height: 12vw;
    font-size: 6vw; font-weight: bold; color: rgba(120, 85, 85, 1);
    display: flex; align-items: center;
    margin-top:14vw; margin-left: 14vw;
    border-bottom-style: solid; border-bottom-color: rgba(120, 85, 85, 1); border-bottom-width: 2px;
}
.m_tracking_title_icon {
    opacity: 0.85;
    width: 8vw; height: 8vw; margin: 2vw; margin-bottom:2.5vw;
    background-size: cover; background-repeat: no-repeat;
}
.m_tracking_no_input_title{
    width: 70vw; height: 12vw;
    color: black; font-size: 4.5vw;
    margin-left:14vw; margin-top:10vw; margin-bottom: 10vw;
}
.m_tracking_no_search_box {
    margin-top:2vw;
}
.m_tracking_result_area{
    display: flex; align-items: center; justify-content: center;
    margin:10vw; margin-top: 5vw;
    width: 80vw; height: auto;
}
.m_no_tracking{
    color: rgba(0, 0, 0, 0.5); font-size: 4vw; 
}
.m_tracking_steps {
    margin-left: 5vw;
}
.m_tracking_key_info_area {
    display: flex; align-items: center; 
    margin-left: 15vw; margin-top: 2.5vw;
}
.m_tracking_key_info {
    display: flex; align-items: center; justify-content: center;
    margin: 0; padding: 0; margin-right: 2.5vw; 
    color: black; font-size: 3.5vw;
}