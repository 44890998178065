.location_page {
    background: white;
    color: black; font-family: Arial, Helvetica, sans-serif;
    width: 100vw; height: 100vh;
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    overflow-x: hidden; overflow-y: hidden;
}
.location_title {
    display: flex; align-items: center;
    width: 100vw; height: 14vw;
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 0.1px;
}
.location_back_button {
    position: fixed; top: 4vw; left: 3vw; z-index: 300;
    width: 6vw; height: 6vw;
    background-size: cover; background-repeat: no-repeat;
}
.location_page_icon {
    width: 6vw; height: 6vw;
    margin: 0; margin-right: auto; margin-left: 2vw;
    background-size: cover; background-repeat: no-repeat;
}
.location_title_text {
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.75); font-size: 4.5vw; font-weight: bold;
    margin: 0; padding: 0; width: 100vw; height: 14vw;
}
.search_box_area {
    width: 100vw; height: 11vw; padding-top: 5vw;
    padding-left: 2.5vw; padding-right: 2.5vw;
}
.location_search_box {
    width: 90vw; height: 11vw;
}
.relocate_area {
    display: flex; align-items: center;
    width: 100vw; height: 14vw;
    margin: 0; margin-top: 6.5vw;
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 0.1px;
}
.relocate_text {
    color: darkgray; font-size: 4.5vw; text-align: left;
    width: auto; height: 14vw;
    padding: 0; padding-left: 3vw; padding-top: 3vw;
}
.relocate_button {
    color: darkslategrey; font-size: 4.5vw; text-align: right;
    width: auto; height: 14vw;
    margin: 0; margin-left: auto; margin-right: 3vw;
    padding: 0; padding-top: 3vw;
    opacity: 0.8;
}
.relocate_button:active {
    opacity: 1; font-weight: bold;
}
.current_location {
    font-size: 3.75vw; text-align: left;
    width: 90vw; height: 8.5vw;
    margin: 0; padding: 0; margin-left: 6vw; margin-top: 4.5vw; margin-right: 4vw;
    overflow: hidden; white-space: nowrap;  text-overflow: ellipsis;  
}
.location_google_map {
    background-color: lightsalmon;
    position: fixed; top: 60vw; left: 5vw;
    width: 90vw; height: 50vh;
    box-shadow: 5px 5px 8px rgb(0, 0, 0, 0.4);
    overflow-x: hidden; overflow-y: hidden;
}
