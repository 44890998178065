
.ca_cn_order_title {
    width: 97.5%; margin-bottom: 15px; margin-top: 35px; margin-left: 17.5px; padding-bottom: 5px;
    border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(128, 128, 128, 0.5);
    font-size: 15px; font-weight: bold; color: grey;
    display: flex; align-items: center;
}
.ca_cn_order_row_bottom {
    margin-bottom: 25px;
}

.ca_cn_order_button {
    background: lightseagreen; color: white; font-weight: bold;
    width: auto; height: auto; display: flex; align-items: center; justify-content: center;
    border-radius: 5px; padding: 5px; padding-left: 10px; padding-right: 10px;
    margin-right: 10px;
}
.ca_cn_order_button:active {
    opacity: 0.8;
}
.ca_cn_order_button:hover {
    cursor: pointer;
}
.ca_cn_order_button_right_layout {
    margin-left: auto;
}

.shipper_receiver_select_block {
    background: rgba(0, 139, 139, 0.25);
    border-bottom-style: solid; border-bottom-color:grey; border-bottom-width: 1px;
    margin-bottom: 10px; padding-top: 15px;
}
.shipper_receiver_select_block:hover {
    background: rgba(0, 139, 139, 0.5);
    cursor: pointer;
}
.shipper_receiver_select_block:active {
    opacity: 0.5;
}
.ca_cn_order_dropdown_button {
    width: 200px; padding-left: 5px; padding-right: 5px;
     /* margin-right: 10px; */
    display: flex; align-items: center; justify-content: center;
}
.ca_cn_order_dlg_title {
    padding-left: 15px; padding-right: 50px;
    display: flex; align-items: center;
    font-weight: bold; color: rgba(0, 0, 0, 0.75);
    /* justify-content: center; */
}
.ca_cn_order_input_mid {
    width: 495px; min-width: 495px;
}
.ca_cn_order_photo_id_view_button {
    color: darkcyan; font-weight: bold; text-decoration: underline;
}
.ca_cn_order_photo_id_view_button:hover {
    cursor: pointer;
}
.ca_cn_order_photo_id_view_button:active {
    opacity: 0.8;
}
.ca_cn_order_view_photo_dlg {
    width: 100%; height: auto; max-height: 75vh;
    overflow:hidden; overflow-y: auto;
}
.ca_cn_order_edit_row {
     display: flex; align-items: center;
}
.ca_cn_order_print_area {
    background: white;
    /* width: 11cm; height: 16cm;  */
    margin:0cm; padding:0cm;
    margin-top:0.1cm; margin-left: 0.1cm; 
    /* overflow: hidden; overflow-y: auto; */
}
.ca_cn_order_print_block {
    background: white;
    width: 10cm; height: 15cm; min-width: 10cm; min-height: 15cm;
    /* width: 20cm; height: 30cm; min-width: 20cm; min-height: 30cm; */
    border-style: dashed; border-width: 1px; border-color: gray;
    margin:0cm; margin-bottom: 2cm;
}
.ca_cn_order_print_block_no_space {
    margin:0cm;
}
.ca_cn_order_print_row {
    width: 10cm; height: auto; min-width: 10cm;
    padding:0.25cm;
    /* padding-top: 0.25cm; padding-bottom: 0.25cm; */
    display: flex;
    /* align-items: center; */
    border-bottom-style: dashed; border-bottom-width: 1px; border-bottom-color: gray;
}
.ca_cn_order_print_fixed_height1 {
  height: 2.2cm; min-height: 2.2cm;
}
.ca_cn_order_print_fixed_height2 {
  height: 5cm; min-height: 6.65cm;
}
.ca_cn_order_print_layout_center { 
    display: flex; align-items: center; justify-content: center;
    padding: 0.25cm;
}
.ca_cn_order_print_title {
    /* background: lightseagreen; */
    width: 1.25cm; min-width: 1.25cm;
    font-weight: bold;
    margin-top: 0px; margin-bottom: auto;
}
.ca_cn_order_print_contents {
    /* background: lightcoral; */
    width: calc(10cm - 1.8cm); min-width:calc(10cm - 1.8cm);
    max-width:calc(10cm - 1.8cm);
}