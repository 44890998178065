.auto_order_info_block {
    background: rgba(211, 211, 211, 0.1);
    width: 100%; height: auto;
    margin-bottom: 20px; padding: 20px; padding-top: 17.5px; padding-bottom: 5px;
    border-style: solid; border-width: 1px; border-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.auto_order_info_row {
    display: flex; align-items: center;
    margin-bottom: 15px;
}
.auto_order_input_long {
    width: 465px; min-width: 465px; margin-right: 30px;
}
.auto_order_upload_file_area {
    /* background: rgba(0, 139, 139, 0.02); */
    background: rgba(32, 178, 171, 0.05);
    width: 100%; height: auto;
    margin-bottom: 15px; padding: 10px;
    /* border-style: solid; border-width: 1px; border-color: lightgray; */
}
.auto_order_select_file_button {
    display: flex; align-items: center; justify-content: center;
    width: 90%; height: auto;
    color: darkgray; font-weight: bold; font-size: 15px;
    border: none;
}
.auto_order_select_file_button:hover {
    cursor: pointer;
}
.auto_order_select_file_button:active {
    opacity: 0.5;
}
.auto_order_upload_file_button {
    background: darkcyan;
    display: flex; align-items: center; justify-content: center;
    width: 10%; height: 30px;
    color: white; font-weight: bold; font-size: 15px;
    border-radius: 2.5px;
}
.auto_order_upload_file_button:hover {
    cursor: pointer;
}
.auto_order_upload_file_button:active {
    opacity: 0.5;
}
.auto_order_file_wait_list{
    width: 100%; height: auto;
    margin-top: 15px;
    border-top-style: solid; border-top-width: 0.5px; border-top-color: rgba(0, 0, 0, 0.5);
}
.auto_order_file_wait_row {
    width: 100%; height: auto;
    margin-top: 5px ; margin-bottom: 5px; padding-left: 5px;
}
.auto_order_file_operate_button {
    font-size: 15px;
    /* font-weight: bold; font-size: 10px;
    display: flex; align-items: center; justify-content: center; */
}
.auto_order_file_operate_button:hover {
    cursor: pointer;
}
.auto_order_file_operate_button:active {
    opacity: 0.5;
}