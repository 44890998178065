.ticket_empty {
    color: grey;
    width: 95vw; height: 100%;
    padding-top: 4.5vw;
}
.ticket_list {
    width: 100vw; height: auto; overflow: hidden;
    padding: 0; margin: 0; margin-bottom: 2vw;
}
.ticket_bgp {
    background-size: cover; background-repeat: no-repeat;
    width: 90vw; height: 27vw;
    padding: 0; margin: 5vw;
    border-radius: 3px;
    /* margin-bottom: 3vw; margin-top: 3vw; */
}
.ticket_bgp_image {
    background-image: url("../../../picture/TicketBGP.png"); 
}
.ticket_bgp_image0 {
    background-image: url("../../../picture/TicketBGP0.png"); 
}
.ticket_store_info {
    /* background: lightskyblue; */
    width: 90vw; height: 6vw;
    display: flex; align-items: center;
    /* position: absolute; top: 0; left: 0; */
}
.ticket_store_name {
    /* background: lightseagreen; */
    width: 75vw; height: 6vw;
    color: rgba(133, 133, 133, 0.5); font-size: 3vw; font-weight: bold;
    margin-left: 4.5vw; padding-top: 1vw;
    overflow: hidden; text-overflow: ellipsis;  /* 超出控件宽度的文字用省略号代替 */
}
.ticket_type {
    /* background: lightsalmon; */
    /* text-align: right; */
    color: rgba(133, 133, 133, 0.25); font-size: 3vw;
    width: 15vw; height: 6vw; margin: 0; padding-top: 1vw; padding-left: 1vw;
}
.ticket_details_area{
    /* background: lightseagreen; */
    display: flex; align-items: center;
    width: 90vw; height: 21vw; padding-top: 0vw;
}
.ticket_item_icon {
    /* background-color: white; opacity: 1; */
    background-size: cover; background-repeat: no-repeat;
    min-width: 16vw; min-height: 16vw; max-width: 16vw; max-height: 16vw;
    margin: 0; margin-left: 4vw;
    border-radius: 3px;
    /* box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5); */
}
.ticket_title_area {
    background: rgba(190, 190, 190, 0.1);
    width: 47vw;  height: 16vw;
    margin: 0; padding:0; margin-left: 1vw;
}
.ticket_title {
    color: black; font-weight: bold; font-size: 3.75vw; text-align: left;
    width: 47vw; height: 6.5vw; margin: 0; padding: 0;  padding-left: 1.5vw;
}
.ticket_subtitle {
    width: 47vw; height: 4.75vw;
    color: rgba(0, 0, 0, 0.5); font-size: 3vw;
    text-align: left; padding-left: 1.5vw; padding-top: 0.5vw;
    overflow: hidden; text-overflow: ellipsis;  /* 超出控件宽度的文字用省略号代替 */
}
.ticket_expire_date {
    /* background: lightseagreen; */
    display: flex; align-items: center;
    width: 47vw; height: 4.75vw;
    color: lightsalmon; font-size: 3vw;
    padding-left: 1.5vw; padding-top: 1.5vw;
}
.ticket_type_get_area {
    /* background: lightseagreen; */
    width: 22vw; height: 21vw; padding: 2vw;
}
.ticket_get_btn {
    background: lightcoral;
    display: flex; align-items: center; justify-content: center;
    color: white; font-size: 3.5vw; font-weight: bold;
    width: 16vw; height: 7vw; margin-bottom: 1.5vw;
    border-radius: 2.5px;
    box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3);
}
.ticket_get_btn:active {
    background: lightsalmon; color: black;
}
.ticket_get_btn_use {
    background: lightskyblue;
    display: flex; align-items: center; justify-content: center;
    color: grey; font-size: 3.5vw; font-weight: bold;
    width: 16vw; height: 7vw; margin-bottom: 1.5vw;
    border-radius: 2.5px;
    /* box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3); */
}
.ticket_get_btn_used {
    background: lightgrey;
    display: flex; align-items: center; justify-content: center;
    color: grey; font-size: 3.5vw; font-weight: bold;
    width: 16vw; height: 7vw; margin-bottom: 1.5vw;
    border-radius: 2.5px;
}
.ticket_get_btn_use:active {
    background: lightseagreen; color: black;
}
.ticket_get_info {
    /* background: lightseagreen; */
    /* display: flex; align-items: center; justify-content: center; */
    width: 100%; height: 4vw; 
    color: rgba(0, 0, 0, 0.5); font-size: 3vw; 
}
.item_info_condition{
    display: flex; align-items: center;
    width: 200px;
}
.ticket_details_title {
    display: flex; align-items: center; justify-content: center;
    color: black; font: bold; font-size: 4vw;
    margin-bottom: 3vw; 
}
.ticket_details_text {
    display: flex; align-items: center; justify-content: center;
    color: rgba(0, 0, 0, 0.5); font-size: 3.5vw;
    margin-bottom: 3vw; margin-top: 2vw; padding-bottom: 1.5vw;
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 1px;
}
.ticket_use_confirm_button {
    background: lightsalmon;
    display: flex; align-items: center; justify-content: center;
    font-size: 3.5vw; font-weight: bold;
    width: 20vw; height: 8vw;
    margin-left: auto; margin-right: 0; margin-top: 5vw;
    border-radius: 5px;
}
.ticket_use_confirm_button:active {
    background: lightcoral;
}
.ticket_code {
    display: flex; align-items: center; justify-content: center;
    margin: 5vw;
}
.ticket_price_text {
    /* background: lightseagreen; */
    color: grey; font-size: 3.5vw; text-align: right;
    margin-top: 1vw;
}
.ticket_price_bottom_line {
    /* background: lightskyblue; */
    color: lightcoral;
    width: 50%; margin-left: auto; margin-right: 0;
    margin-top: 2vw; padding-top: 1vw;
    border-top-style: solid; border-top-color: lightgray; border-top-width: 1px;
}
.ticket_details_payment_method {
    display: flex; align-items: center;
    color: grey; font-size: 3.5vw; text-align: left;
    border-bottom-style: solid; border-bottom-color: lightgray; border-bottom-width: 1px;
    margin-bottom: 3vw; padding-bottom: 1vw;
}
