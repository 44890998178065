.login_page {
    color: black; font-family: Arial, Helvetica, sans-serif;
    display: flex; align-items: center; justify-content: center;
    width: 100vw; height: 100vh; overflow: hidden; 
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.login_bgp_area {
    position:fixed; top:-5vw; left:0;
    width: 100vw; height: auto;
    overflow: hidden; opacity: 0.6;
}
.login_bgp {
    width: 100vw; height: calc(100vw * 16 / 9); 
    background-size: cover; background-repeat: no-repeat;
    background-image: url("../../picture/LoginBGP.png"); 
}
.login_form_area {
    background: rgba(255, 255, 255, 0.8);
    display: flex; align-items: center; justify-content: center;
    width: 80vw; height: auto; overflow: hidden;
    margin: 0; padding: 0;
    padding-top: 6vw;
    overflow: hidden; z-index: 200;
    border-style: solid; border-radius: 5px; border-color: rgba(0, 0, 0, 0.2); border-width: 1px;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.2);
}
.register_form_area {
    width: 100vw; height: 100vh;
    overflow-y: scroll; z-index:100;
    padding:10vw; padding-top: 15vw;
    display: flex; align-items: center; justify-content: center;
}
.login_back_btn {
    display: flex; align-items: center; justify-content: center;
    position: fixed; top: 3vw; left: 3vw; z-index: 200;
    font-size: 6vw; color: white; opacity: 0.75;
    width: 8vw; height: 8vw;
    background-size: cover; background-repeat: no-repeat;
}
.login_back_btn:active {
    opacity: 1;
}
.login_button_line {
    margin-right: 5vw;
}
.register_button_line {
    margin-right: 5vw;
}
.register_area_horizontal_space {
    /* padding-left: 5vw; padding-right: 5vw; */
}
.login_title {
    display: flex; align-items: center; justify-content: center;
    position:fixed; bottom:25vw; left:0;
    width: 100vw; height: 20vw;
    color: black; font-size: 10vw; font-weight: bold;
}
.click_link {
    color: rgb(25, 145, 255); font-size: 3.5vw;
    width: auto; margin-left: 4vw; margin-right: 0;
}
.agreement_forget_password{
    font-size: 3.25vw;
    display: flex; align-items: center;
    width: 100%; height: auto;
}
.login_button_area {
    display: flex; align-items: center;
    margin-top: 5vw; margin-bottom: 5vw;
}
.invite_code {
    color: rgba(0, 0, 0, 0.85);  font-size: 3.25vw; 
    margin-left: 0.25vw; margin-top: 5.5vw; margin-bottom: 3.5vw;
}

/****** Web Layout ******/
.login_page_web {
    background: white;
    color: black; font-family: Arial, Helvetica, sans-serif;
    /* display: flex; align-items: center;justify-content: center; */
    width: 100vw; height: 100vh; overflow: hidden; overflow-y: auto;
    /* min-width: 60vw; overflow-x: auto; */
    margin: 0; padding: 0; opacity: 1;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.login_page_web_title_area {
    background: white;
    width: 100vw; height: 100px;
    display: flex; align-items: center;
}
.login_page_web_title_logo {
    background-image: url("../../picture/ZhiHeLogo.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: 75px; height: 75px; min-width: 75px; min-height: 75px;
    margin-left: 50px; margin-right: 5px;
    border-radius: 100px;
}
.login_page_web_title_logo_xiyouji {
    background-image: url("../../picture/XiYouJiLogo.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: 75px; height: 75px; min-width: 75px; min-height: 75px;
    margin-left: 50px; margin-right: 5px;
    border-radius: 100px;
}
.login_page_web_title_logo:hover{
    cursor: pointer;
}
.login_page_web_title_logo:active {
    opacity: 0.75;
}
.login_page_web_title_slogan {
    background-image: url("../../picture/ZhiHeTitle.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: auto; min-width:176.7px; height: 70px; min-height: 70px;
    margin-top: 5px;
    display: flex; align-items: center;justify-content: center;
}
.login_page_web_title_slogan:hover {
    cursor: pointer;
}
.login_page_web_title_slogan:active {
    opacity: 0.75;
}
.login_page_web_title_slogan_xiyouji {
    background-image: url("../../picture/XiYouJiLogo.png"); 
    background-size: cover; background-repeat: no-repeat;
    width: auto; min-width:200px; height: 100px; min-height: 100px;
    margin-top: 0px; margin-left: 25px;
    display: flex; align-items: center;justify-content: center;
}
.login_page_web_title_slogan_xiyouji:hover {
    cursor: pointer;
}
.login_page_web_title_slogan_xiyouji:active {
    opacity: 0.75;
}
.login_page_web_center_area {
    background-image: url("../../picture/WebsiteMainBGColor.jpg"); 
    background-size: 1444.444444px 650px;
    width: 100vw; min-width: 1444.444444px; height:650px;
    display: flex; align-items: center;
    border-bottom-color: darkcyan; border-bottom-width: 1px; border-bottom-style: solid;
}
.login_page_web_center_area_xiyouji {
    background: lightblue;
    background-size: 1444.444444px 650px;
    width: 100vw; min-width: 1444.444444px; height:650px;
    display: flex; align-items: center;
    /* border-bottom-color: darkcyan; border-bottom-width: 1px; border-bottom-style: solid; */
}
.login_page_web_login_form {
    background: white;
    width: 400px; min-width:400px; height: auto;
    margin-left: auto; margin-right: 17.5vw; padding-bottom: 15px;
    border-radius: 5px;
    position: relative; left: 10vw; top: 0px; z-index: 300;
}
.login_page_web_login_form_xiyouji {
    background: white;
    width: 500px; min-width:500px; height: auto;
    margin-left: auto; margin-right: auto; padding-bottom: 15px;
    border-radius: 5px;
    /* position: relative; left: 10vw; top: 0px; z-index: 300; */
}
.login_page_web_login_title {
    width: 100%; height: 50px;
    margin-top: 5%; margin-bottom: 7.5%;
    display: flex; align-items: center;justify-content: center;
    color: rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 25px;
    border-bottom-style: solid; border-bottom-color: rgba(0, 0, 0, 0.25); border-bottom-width: 1px;
}
.login_page_web_login_input {
    width: 80%; height: 20px;
    border-radius: 0px;
    display: flex; align-items: center;
}
.login_page_web_bottom_area {
    width: 100vw; height: 150px; min-height: 150px;
    padding-top: 10px; 
    display: flex; align-items: center;justify-content: center;
}
.login_page_web_bottom_copyright {
    background: white;
    width: auto; height: auto; color: rgba(0, 0, 0, 0.5);
    padding-top: 10px; 
    display: flex; align-items: center;
    /* justify-content: center; */
}
.login_page_web_login_button{
    background-color: darkcyan; 
    width: 80%; height: 40px;
    margin-left: 10%; color: white; font-weight: bold; font-size: 20px;
    display: flex; align-items: center;justify-content: center;
}
.login_page_web_regist_now {
    width: 80%; height: 50px;
    margin-left: 10%; margin-top: 2%; 
    color: darkcyan; font-size: 15px;
    display: flex; align-items: center;
}
.login_page_web_regist_now:hover {
    cursor: pointer;
}
.login_page_web_regist_now:active {
    opacity: 0.5;
}
.login_page_web_quick_login_title {
    width: 80%; height: 50px;
    margin-left: 10%;
    color: rgba(0, 0, 0, 0.25); font-size: 16px;
    display: flex; align-items: center;justify-content: center;
}
.login_page_web_quick_login_method {
    width: 100%; height: 50px; margin-top: 10px; margin-bottom: 20px;
    display: flex; align-items: center;justify-content: center;
    font-size: 35px; font-weight: bold; color: darkcyan;
}
.login_page_web_quick_login_button {
    background: rgba(32, 178, 171, 0.1);
    margin: 10px; padding: 10px;
    border-radius: 50px;
}
.login_page_web_quick_login_button:active {
    opacity: 0.5;
}
.login_page_web_regist_form_row{
    /* background: lightblue; */
    width: 100%; height: auto;
    /* margin-left: 10px; margin-right: 10px; */
    display: flex; align-items: center;justify-content: center;
}
.login_page_web_input_tip {
    /* background: lightblue; */
    width: 80%; height: auto;
    margin: 2.5%; margin-left: 8.5%; margin-bottom: 0%;
}