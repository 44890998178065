/* 顶部栏 */
.navbar {
    background: rgba(160, 125, 125, 1);
    font-family: Arial, Helvetica, sans-serif;
    display: flex; align-items: center;
    position: fixed; top: 0vw; width: 100vw; height: 14vw;
    -moz-user-select: none; /* Firefox */ -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
    z-index: 600; overflow: hidden; opacity: 1;
}
.navbar_search_box {
    background: lightgray; font-size: 5vw;  /*控制不了文字，可以控制放大镜*/
    width: 75vw; min-width: 30vw; height: 10vw;
    margin: 0; padding: 0;
    border-radius: 5px;
}
.navbar_search_box1 {
    max-width: 75vw;
    position: fixed; left: 10vw;
}
.navbar_location_btn {
    background-color: white;
    position: fixed; top: 3.25vw; left: 2.25vw; z-index: 100;
    min-width: 6.5vw; min-height: 6.5vw; border-radius: 50px;
    /* border-style: solid; border-width: 2px; border-color: deepskyblue; */
}
.navbar_back_btn_container {
    display: flex; align-items: center; justify-content: center;
    width: 10vw; height: 13.5vw;
    position: fixed; top: 0; left: 0; z-index: 100;
}
.navbar_back_btn {
    width: 6vw; height: 6vw;
    background-size: cover; background-repeat: no-repeat;
}
/* .navbar_back_btn:active {
    width: 7vw; height: 7vw;
} */
.navbar_location_icon {
    width: 7vw; height: 7vw;
    margin: auto;
    background-size: cover; background-repeat: no-repeat;
}
.top_space {
    width: 100%; height: 14vw;
}
.indie_store_navbar {
    display: flex; align-items: center; z-index: 301;
    position: fixed; top: 2vw; right: 2vw; width: 10vw; height: 10vw;
    border-radius: 50px;
    /* border-style: solid; border-color: white; border-width: 1px; */
    background-size: cover; background-repeat: no-repeat;
}
.mouthing_title{
    /* background: rgba(120, 85, 85, 1); */
    background: rgba(160, 125, 125, 1);
    display: flex; align-items: center; justify-content: center; z-index: 300;
    position: fixed; top: 0vw; left: 0vw; width: 100vw; height: 14vw;
    font-size: 4vw; color: rgba(255, 255, 255, 1);
    border-bottom-style: solid; border-bottom-color: white; border-bottom-width: 1px;
    /* opacity: 0.75; */
}
.mouthing_title_logo_area {
    position: fixed; top: 2vw; left: 2vw; z-index: 301;
    width: 10vw; height: 10vw;
    background-size: cover; background-repeat: no-repeat;
}